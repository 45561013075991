define("oceasoft-web/controllers/authentication/register", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/objects/utils", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/objects/user", "oceasoft-web/objects/company", "oceasoft-web/objects/voucher"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebObjectsUtils, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebObjectsUser, _oceasoftWebObjectsCompany, _oceasoftWebObjectsVoucher) {

    var TAG = "authentication.register controller";
    var A = _ember["default"].A;
    var service = _ember["default"].inject.service;
    var observer = _ember["default"].observer;
    var computed = _ember["default"].computed;
    var on = _ember["default"].on;
    var isEmpty = _ember["default"].isEmpty;
    var Country = _oceasoftWebObjectsConstants["default"].Country;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;
    var REGEX = _oceasoftWebObjectsConstants["default"].REGEX;
    var VoucherType = _oceasoftWebObjectsConstants["default"].VoucherType;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], {

        queryParams: ['email'],

        logger: service('oc-logger'),
        i18n: service('i18n'),
        api: service('oc-api'),
        session: service('session'),

        // region Data

        email: null,
        user: _oceasoftWebObjectsUser["default"].create(),
        company: _oceasoftWebObjectsCompany["default"].create(),
        voucher: _oceasoftWebObjectsVoucher["default"].create(),

        userPassword: null,
        userConfirmPassword: null,

        handleEmailChange: observer('email', function () {
            var email = this.get('email');

            this.set('user.email', email);
            this.set('company.email', email);
        }),

        // endregion

        isRegistered: false,
        countries: Country,

        // region Voucher Types

        selectedVoucherType: VoucherType.EMERALD_AND_ATALS,

        voucherTypes: computed(function () {
            return new A(Object.keys(VoucherType).map(function (voucherType) {
                return VoucherType[voucherType];
            }));
        }).readOnly(),

        // region Voucher Types Atlas Only

        hasAtlasOnly: computed('selectedVoucherType', function () {
            return parseInt(this.get('selectedVoucherType')) === VoucherType.ATLAS_ONLY;
        }),

        // endregion

        actions: {

            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');

                this.set('user', _oceasoftWebObjectsUser["default"].create());
                this.set('company', _oceasoftWebObjectsCompany["default"].create());
                this.set('voucher', _oceasoftWebObjectsVoucher["default"].create());
                this.set('userPassword', null);
                this.set('userConfirmPassword', null);
                this.set('isRegistered', null);
                this.set('selectedVoucherType', VoucherType.EMERALD_AND_ATALS);
            },

            setLocale: function setLocale(locale) {
                this.get('logger').info(TAG, 'resetController');

                if (!isEmpty(locale)) {
                    this.set('i18n.locale', locale);
                    this.set('user.language', locale);
                }
            },

            register: function register() {
                var _this = this;

                this.get('logger').info(TAG, 'register');

                this.send('hideError');

                var api = this.get('api');

                var _getProperties = this.getProperties('i18n', 'user', 'company', 'voucher', 'userPassword', 'userConfirmPassword', 'selectedVoucherType');

                var i18n = _getProperties.i18n;
                var user = _getProperties.user;
                var company = _getProperties.company;
                var voucher = _getProperties.voucher;
                var userPassword = _getProperties.userPassword;
                var userConfirmPassword = _getProperties.userConfirmPassword;
                var selectedVoucherType = _getProperties.selectedVoucherType;

                // Validate Fields
                var hasError = false;

                // Validate Email
                if (isEmpty(user.get('email'))) {
                    this.send('setFieldError', 'email', 'error.empty_field');
                    hasError = true;
                } else if (!REGEX.EMAIL.test(user.get('email'))) {
                    this.send('setFieldError', 'email', 'login.invalid_email');
                    hasError = true;
                }

                // Validate Passwords
                var hasPasswordError = false;
                if (isEmpty(userPassword)) {
                    this.send('setFieldError', 'password1', 'error.empty_field');
                    hasError = true;
                    hasPasswordError = true;
                } else if (userPassword.length < _oceasoftWebObjectsConstants["default"].PASSWORD_LENGTH) {
                    this.send('setFieldError', 'password1', 'login.password_length');
                    hasError = true;
                    hasPasswordError = true;
                } else if (!REGEX.PASSWORD.test(userPassword)) {
                    this.send('setFieldError', 'password1', 'login.invalid_password');
                    hasError = true;
                    hasPasswordError = true;
                }

                if (isEmpty(userConfirmPassword)) {
                    this.send('setFieldError', 'password2', 'error.empty_field');
                    hasError = true;
                } else if (userConfirmPassword.length < _oceasoftWebObjectsConstants["default"].PASSWORD_LENGTH) {
                    this.send('setFieldError', 'password2', 'login.password_length');
                    hasError = true;
                } else if (!REGEX.PASSWORD.test(userConfirmPassword)) {
                    this.send('setFieldError', 'password2', 'login.invalid_password');
                    hasError = true;
                } else if (!hasPasswordError && userPassword !== userConfirmPassword) {
                    this.send('setFieldError', 'password2', 'error.non_matching_passwords');
                    hasError = true;
                }

                // Validate First Name
                if (isEmpty(user.get('firstName'))) {
                    this.send('setFieldError', 'firstName', 'error.empty_field');
                    hasError = true;
                }

                // Validate Last Name
                if (isEmpty(user.get('lastName'))) {
                    this.send('setFieldError', 'lastName', 'error.empty_field');
                    hasError = true;
                }

                // Validate Company Name
                if (isEmpty(company.get('name'))) {
                    this.send('setFieldError', 'companyName', 'error.empty_field');
                    hasError = true;
                }

                var isVoucherEmerald = !this.get('hasAtlasOnly');

                // Validate Voucher
                if (isVoucherEmerald && isEmpty(voucher.get('serial'))) {
                    this.send('setFieldError', 'voucher', 'error.empty_field');
                    hasError = true;
                }

                if (hasError) {
                    return;
                }

                this.send('showLoading', false);

                // Setup objects before sending to the API
                user.set('password', _oceasoftWebObjectsUtils["default"].User.passwordHash(userPassword));
                voucher.set('type', isVoucherEmerald ? selectedVoucherType : VoucherType.ATLAS_ONLY);

                // Set the default voucher serial for ATLAS if the user has not entered anything.
                if (!isVoucherEmerald) {
                    voucher.set('serial', _oceasoftWebObjectsConstants["default"].ATLAS_VOUCHER_SERIAL);
                }

                // Synchronise the user language with the one from the platform.
                user.set('language', i18n.get('locale'));

                // Call the API
                api.createUser(user, company, voucher).then(function () {
                    _this.send('hideLoading', false);
                    _this.set('isRegistered', true);
                })["catch"](function (reason) {
                    _this.send('hideLoading', false);
                    voucher.set('serial', null);

                    switch (reason) {
                        default:
                        case Response.FAILED:
                            _this.send('showGeneralNetworkError');
                            break;

                        case Response.ALREADY_EXIST:
                            _this.send('setFieldError', 'email', 'register.error_already_exists_field');
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'register.error_already_exists_description');
                            break;

                        case Response.ALREADY_EXIST_CUSTOMER:
                            _this.send('setFieldError', 'companyName', 'register.error_already_exists_company_field');
                            _this.send('showWarningError', 'register.error_already_exists_company', 'register.error_already_exists_company_description');
                            break;

                        case Response.WRONG_COMPANY_ID:
                        case Response.INVALID_VOUCHER:
                            _this.send('setFieldError', 'voucher', 'register.error_voucher_invalid_field');
                            _this.send('showWarningError', 'register.error_voucher', 'register.error_voucher_invalid');
                            break;

                        case Response.VOUCHER_ALREADY_USED:
                            _this.send('setFieldError', 'voucher', 'register.error_voucher_used_field');
                            _this.send('showWarningError', 'register.error_voucher', 'register.error_voucher_used');
                            break;
                    }
                });
            },

            login: function login() {
                var _this2 = this;

                this.get('logger').info(TAG, 'login');

                var _getProperties2 = this.getProperties('user');

                var user = _getProperties2.user;

                this.send('showLoading', false);

                this.get('session').authenticate('authenticator:oc-authenticator', user.email, user.password)["catch"](function () {
                    _this2.send('hideLoading', false);
                    _this2.send('showGeneralNetworkError');
                });
            },

            retry: function retry() {
                this.get('logger').info(TAG, 'retry');

                if (this.get('isRegistered')) {
                    this.send('login');
                } else {
                    this.send('register');
                }
            },

            showLoading: function showLoading() {
                this._super.apply(this, arguments);
                this.send('resetContentScroll');
            },

            hideLoading: function hideLoading() {
                if (this.get('isLoading')) {
                    this.send('resetContentScroll');
                }

                this._super.apply(this, arguments);
            }
        }

    });
});