define("oceasoft-web/components/oc-sidebar", ["exports", "ember", "oceasoft-web/mixins/window"], function (exports, _ember, _oceasoftWebMixinsWindow) {

  var TAG = 'oc-sidebar';
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  var observer = _ember["default"].observer;
  exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsWindow["default"], {
    tagName: 'oc-sidebar',
    classNameBindings: ['list', 'isOpened:opened:closed'],

    logger: service('oc-logger'),

    title: null,
    list: false,

    opened: false,
    isOpened: computed('window.isSmallOnly', 'opened', function () {
      return this.get('window.isSmallOnly') && this.get('opened');
    }),

    handleOpenToggle: observer('opened', function () {
      var el = this.$();

      if (el.hasClass('closed') && this.get('isOpened')) {
        el.removeClass('closed').addClass('opened');
      } else {
        el.removeClass('opened').addClass('closed');
      }
    }),

    header: { isHeader: true },
    menu: { isMenu: true },
    footer: { isFooter: true },

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.get('logger').info(TAG, 'toggleSidebar');

        // Close all other oc-sidebars that are opened except the current one.
        this.$().parent().find('oc-sidebar.opened').not(this.$()).removeClass('opened').addClass('closed');

        // Synchronize the opened variable with the class name.
        // Desynchronization happens when the opene/closed class is changed dinamically via jQuery and not the component.
        if (this.get('opened') && this.$().hasClass('closed')) {
          this.set('opened', false);
        }

        // Toggle the property as normal.
        this.toggleProperty('opened');
      }
    }

  });
});