define('oceasoft-web/mixins/session-manager', ['exports', 'ember'], function (exports, _ember) {

  var TAG = 'session-manager';
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Mixin.create({

    logger: service('logger'),
    session: service('session'),
    isAuthenticated: computed.alias('session.isAuthenticated'),

    user: computed.alias('session.data.internal.session.user'),
    company: computed.alias('session.data.internal.session.company'),
    host: computed.alias('session.data.internal.session.host'),

    actions: {
      notifySessionDataChanged: function notifySessionDataChanged() {
        this.get('logger').info(TAG, 'notifySessionDataChanged');
        this.get('session').notifyInternalSessionDataChanged();
      }
    }

  });
});