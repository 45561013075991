define("oceasoft-web/mixins/admin-route", ["exports", "ember", "oceasoft-web/mixins/session-manager", "oceasoft-web/objects/constants", "oceasoft-web/objects/error"], function (exports, _ember, _oceasoftWebMixinsSessionManager, _oceasoftWebObjectsConstants, _oceasoftWebObjectsError) {
  var reject = _ember["default"].RSVP.reject;
  var Level = _oceasoftWebObjectsConstants["default"].User.Level;
  exports["default"] = _ember["default"].Mixin.create(_oceasoftWebMixinsSessionManager["default"], {

    beforeModel: function beforeModel(transition) {
      if (this.get('user.level') !== Level.ADMINISTRATOR) {
        return reject(_oceasoftWebObjectsError["default"].create({
          type: _oceasoftWebObjectsConstants["default"].Error.Type.FATAL,
          image: 'unauthorized_error_img',
          title: 'error.unauthorized',
          description: 'error.unauthorized_description',
          attemptedTransition: transition
        }));
      } else {
        this._super.apply(this, arguments);
      }
    }

  });
});