define('oceasoft-web/initializers/i18n', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    ['route', 'model', 'controller'].forEach(function (component) {
      app.inject(component, 'i18n', 'service:i18n');
    });
  }

  exports['default'] = {
    name: 'i18n',
    initialize: initialize
  };
});