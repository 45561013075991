define("oceasoft-web/authenticators/oc-authenticator", ["exports", "ember", "ember-simple-auth/authenticators/base", "oceasoft-web/objects/session", "oceasoft-web/objects/user", "oceasoft-web/objects/company"], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _oceasoftWebObjectsSession, _oceasoftWebObjectsUser, _oceasoftWebObjectsCompany) {

    var TAG = 'oc-authenticator';

    var Promise = _ember["default"].RSVP.Promise;
    var service = _ember["default"].inject.service;
    exports["default"] = _emberSimpleAuthAuthenticatorsBase["default"].extend({

        logger: service('oc-logger'),
        api: service('oc-api'),

        authenticate: function authenticate(email, pwd) {
            var logger = this.get('logger');
            var api = this.get('api');

            logger.info(TAG, 'authenticate');
            document.cookie = "validSession=true";
            return api.authenticate(email, pwd);
        },

        invalidate: function invalidate(data) {
            this.get('logger').info(TAG, 'invalidate');
            return Promise.resolve(data);
        },

        restore: function restore(data) {
            this.get('logger').info(TAG, 'restore');

            function getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) === 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return false;
            }

            if (!getCookie('validSession')) {
                this.invalidate({ authenticator: "authenticator:oc-authenticator" });
            }

            return Promise.resolve({
                session: _oceasoftWebObjectsSession["default"].create({
                    user: _oceasoftWebObjectsUser["default"].create(data.session.user),
                    company: _oceasoftWebObjectsCompany["default"].create(data.session.company)
                })
            });
        }

    });
});