define("oceasoft-web/objects/mission", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/utils", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsUtils, _oceasoftWebObjectsConstants) {
    var isEmpty = _ember["default"].isEmpty;
    var computed = _ember["default"].computed;
    var A = _ember["default"].A;
    var shortMD5 = _oceasoftWebObjectsUtils["default"].String.shortMD5;
    var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;
    var State = _oceasoftWebObjectsConstants["default"].Mission.State;

    var Mission = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

        init: function init() {
            setDefaultProperties(this, {
                isMission: true,
                mid: null,
                name: null,
                progDate: null,
                startDate: null,
                endDate: null,
                highLimit: null,
                lowLimit: null,
                highLimitEnabled: false,
                lowLimitEnabled: false,
                highDelay: 0,
                lowDelay: 0,
                interval: 0,
                readings: 0,
                alarms: 0,
                technicalAlarms: 0,
                state: State.ANY
            });

            this._super.apply(this, arguments);

            var apiResponse = this.get('apiResponse');

            if (!isEmpty(apiResponse)) {
                this.set('mid', apiResponse.MissionId);
                this.set('name', apiResponse.MissionName);
                this.set('progDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.ProgDate));
                this.set('startDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.StartDate));
                this.set('endDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.EndDate));
                this.set('highLimitEnabled', Boolean(Number(apiResponse.HighLimitEnabled)));
                this.set('lowLimitEnabled', Boolean(Number(apiResponse.LowLimitEnabled)));
                this.set('highLimit', Number(apiResponse.HighLimit.replace(',', '.')));
                this.set('lowLimit', Number(apiResponse.LowLimit.replace(',', '.')));
                this.set('highDelay', Number(apiResponse.HighDelay.replace(',', '.')));
                this.set('lowDelay', Number(apiResponse.LowDelay.replace(',', '.')));
                this.set('interval', Number(apiResponse.Interval.replace(',', '.')));
                this.set('readings', parseInt(apiResponse.Readings));
                this.set('alarms', parseInt(apiResponse.Alarms));
                this.set('technicalAlarms', parseInt(apiResponse.TechnicalAlarms));

                delete this.apiResponse;
            }
        },

        readingsList: new A([]),
        eventsList: new A([]),

        id: computed('mid', function () {
            // return shortMD5(this.get('mid'));
            return this.get('mid');
        }),

        isStarted: computed('startDate', 'endDate', function () {
            var currentDate = new Date();

            var _getProperties = this.getProperties('startDate');

            var startDate = _getProperties.startDate;

            return !isEmpty(startDate) && startDate.getTime() <= currentDate.getTime();
        }).readOnly(),

        isEnded: computed('startDate', 'endDate', function () {
            if (!this.get('isStarted')) {
                return false;
            }

            var currentDate = new Date();
            var endDate = this.get('endDate');

            return !isEmpty(endDate) && endDate.getTime() <= currentDate.getTime();
        }).readOnly(),

        minTemp: computed('readingsList', function () {
            var readingsList = this.get('readingsList');

            if (isEmpty(readingsList)) {
                return null;
            }

            var measureArray = [];

            readingsList.forEach(function (reading) {
                if (!reading.get('isValid')) {
                    return;
                }
                measureArray.push(reading.get('measure'));
            });

            return Math.min.apply(Math, measureArray);
        }).readOnly(),

        avgTemp: computed('readingsList', function () {
            var readingsList = this.get('readingsList');

            if (isEmpty(readingsList)) {
                return null;
            }

            var measureArray = [];

            readingsList.forEach(function (reading) {
                if (!reading.get('isValid')) {
                    return;
                }
                measureArray.push(reading.get('measure'));
            });

            return measureArray.reduce(function (p, c, i, a) {
                return p + c / a.length;
            }, 0);
        }).readOnly(),

        maxTemp: computed('readingsList', function () {
            var readingsList = this.get('readingsList');

            if (isEmpty(readingsList)) {
                return null;
            }

            var measureArray = [];

            readingsList.forEach(function (reading) {
                if (!reading.get('isValid')) {
                    return;
                }
                measureArray.push(reading.get('measure'));
            });

            return Math.max.apply(Math, measureArray);
        }).readOnly(),

        hasAlarms: computed('alarms', 'technicalAlarms', function () {
            return this.getWithDefault('alarms') + this.getWithDefault('technicalAlarms') > 0;
        }).readOnly(),

        copy: function copy() {
            return Mission.create(this);
        }

    });

    exports["default"] = Mission;
});