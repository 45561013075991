define("oceasoft-web/objects/voucher", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var shortMD5 = _oceasoftWebObjectsUtils["default"].String.shortMD5;
  var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;

  var Voucher = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

    init: function init() {
      setDefaultProperties(this, {
        type: null,
        serial: null,
        dateCreation: null,
        dateExpiration: null,
        sensorCount: 0,
        sensorAvailable: 0
      });

      this._super.apply(this, arguments);

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        this.set('serial', apiResponse.voucherSN);
        this.set('creationDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.DateCreation));
        this.set('creationDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.DateExpiration));

        delete this.apiResponse;
      }
    },

    id: computed('serial', function () {
      return shortMD5(this.get('serial'));
    }),

    copy: function copy() {
      return Voucher.create(this);
    }

  });

  exports["default"] = Voucher;
});