define("oceasoft-web/services/oc-api", ["exports", "ember", "oceasoft-web/config/environment", "oceasoft-web/objects/constants", "oceasoft-web/objects/company", "oceasoft-web/objects/module", "oceasoft-web/objects/event", "oceasoft-web/objects/reading", "oceasoft-web/objects/mission", "oceasoft-web/objects/user", "oceasoft-web/objects/associate-voucher-response", "oceasoft-web/objects/voucher", "oceasoft-web/objects/session", "moment"], function (exports, _ember, _oceasoftWebConfigEnvironment, _oceasoftWebObjectsConstants, _oceasoftWebObjectsCompany, _oceasoftWebObjectsModule, _oceasoftWebObjectsEvent, _oceasoftWebObjectsReading, _oceasoftWebObjectsMission, _oceasoftWebObjectsUser, _oceasoftWebObjectsAssociateVoucherResponse, _oceasoftWebObjectsVoucher, _oceasoftWebObjectsSession, _moment) {

    var TAG = 'oc-api';
    var Promise = _ember["default"].RSVP.Promise;
    var $ = _ember["default"].$;
    var service = _ember["default"].inject.service;
    var isEmpty = _ember["default"].isEmpty;
    var typeOf = _ember["default"].typeOf;
    var A = _ember["default"].A;
    var observer = _ember["default"].observer;
    var on = _ember["default"].on;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;

    var Type = {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        PATCH: 'PATCH',
        DELETE: 'DELETE'
    };

    /**
     * Creates the relative URL path using the arguments array in order to convert
     * them to strings and add them in the same order in the path.
     *
     * This URL needs to have the host URL appended to it.
     *
     * @returns {string}
     */
    function getURL() {
        var paramString = '';

        if (!isEmpty(arguments)) {
            for (var i = 0; i < arguments.length; i++) {
                var value = arguments[i];

                if (typeOf(value) === 'boolean') {
                    value = value ? 1 : 0;
                } else if (typeOf(value) === 'date') {}

                paramString += "/" + encodeURIComponent(value);
            }
        }

        return _oceasoftWebConfigEnvironment["default"].APP.API.PATH + paramString;
    }

    /**
     * Utility function that helps extract the data for the awkward type of [[{}]] responses we get from the API.
     * Conditions may vary from normal single value responses to lists of objects.
     *
     * @param data
     * @param isList
     * @returns {*}
     */
    function extractData(_x2) {
        var _arguments = arguments;
        var _again = true;

        _function: while (_again) {
            var data = _x2;
            _again = false;
            var isList = _arguments.length <= 1 || _arguments[1] === undefined ? false : _arguments[1];

            if (typeOf(data) === 'array') {
                if (isList) {
                    return data[0];
                } else if (data.length === 1) {
                    _arguments = [_x2 = data[0]];
                    _again = true;
                    isList = undefined;
                    continue _function;
                }
            }

            return data;
        }
    }

    /**
     * Main function that is called from all of the API Serivce methods. This takes all the necessary information needed
     * in order to create the AJAX call to the API.
     *
     * It does not actually call the API but instead uses a PROMISE pattern that will also parse the response in order to decide if
     * the data can be parsed as an object/value or rejected as an error with a custom code.
     *
     * TODO: For future improvements in speed we may need to consider creating a caching
     * overlay mechanism that will consist of creating a shortMD5 key out of the relative URL and the data that will be
     * encoded and saved in the localstorage with an time-to-live mechanism.
     * For the updates to come from the server the PROMISE pattern needs to be replace
     * with an RX Observable pattern in order to emit many results not just one.
     * The first result will be the caches one and if the TTL is expired we will also make
     * a secondary call to the API in order to retrieve the latest data and update the UP.
     *
     * @param host
     * @param user
     * @param type
     * @param isList
     * @param methodName
     * @param params
     */
    function getAPICall(user, type, isList, methodName) {
        for (var _len = arguments.length, params = Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
            params[_key - 4] = arguments[_key];
        }

        isList = isList || false;

        return new Promise(function (resolve, reject) {
            var url = null;

            if (!isEmpty(user)) {
                url = getURL.apply(undefined, [methodName, user.get('email'), user.get('password')].concat(params));
            } else {
                url = getURL.apply(undefined, [methodName].concat(params));
            }

            $.ajax({
                url: _oceasoftWebConfigEnvironment["default"].APP.API.URL + url,
                accepts: 'application/json',

                crossDomain: true,
                cache: false,
                type: type,

                beforeSend: function beforeSend(xhr) {
                    // console.log(btoa(Config.APP.API.CREDENTIALS.USERNAME + ":" + Config.APP.API.CREDENTIALS.PASSWORD));
                    xhr.setRequestHeader("Authorization", "Basic " + btoa(_oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.USERNAME + ":" + _oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.PASSWORD));
                },

                success: function success(data) {
                    console.log('--------------------------------------------------------------------------');
                    console.log(_oceasoftWebConfigEnvironment["default"].APP.API.URL + url);
                    console.log(data);
                    console.log('--------------------------------------------------------------------------');

                    // If there is no data we reject it with a FAILED error code.
                    if (isEmpty(data)) {
                        reject(Response.FAILED);
                        return;
                    }

                    var parsedData = null;
                    try {
                        parsedData = JSON.parse(data);
                    } catch (exception) {
                        // If we cannot parse the data it means it's not a json object so we send forward as it is.
                        resolve(data);
                        return;
                    }

                    // If the data contains an error key we reject it directly with the error code.
                    if ('error' in parsedData) {
                        reject(parsedData.error);
                        return;
                    }

                    // If the result doesn't contain an object but a status code for the result, we return the status code directly.
                    if ('result' in parsedData) {
                        resolve(extractData(parsedData.result, isList));
                        return;
                    }

                    // Return the normal parsed data object.
                    resolve(parsedData);
                },
                error: function error() {
                    reject(Response.FAILED);
                }
            });
        });
    }

    /**
     * The entire platform is based on Ember's Object so we need to convert the plain js object retrieved from the API to
     * specific custom models that extend Ember.Object.
     *
     * @param data
     * @param object
     * @returns {*}
     */
    function mapData(data, object) {
        if (isEmpty(object)) {
            return null;
        }

        // Map Array
        if (typeOf(data) === 'array') {
            var _ret = (function () {
                var dataArray = new A([]);

                if (!isEmpty(data)) {
                    data.forEach(function (item) {
                        dataArray.push(object.create({ apiResponse: item }));
                    });
                }
                return {
                    v: dataArray
                };
            })();

            if (typeof _ret === "object") return _ret.v;
        }
        // Map Object
        return object.create({ apiResponse: data });
    }

    /**
     * This is the service that handles all API calls and manages making the correct calls to the API based on the current
     * data saved in the session.
     *
     * It manages the logged in user password for each API call and the HOST in order to sent requests to the host that the platform was logged in.
     */
    exports["default"] = _ember["default"].Service.extend({

        logger: service('oc-logger'),
        session: service('session'),
        i18n: service('i18n'),

        user: null,
        company: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.get('logger').info(TAG, 'init');

            $.ajaxSettings.xhr = function getXHRObject() {
                try {
                    if (window.XMLHttpRequest) {
                        return new XMLHttpRequest();
                    } else {
                        return new ActiveXObject("Microsoft.XMLHTTP");
                    }
                } catch (e) {
                    // console.log(e);
                }
            };
        },

        /**
         * If the session changes we retrieve the host and user from the session data object.
         * Only if the user is authenticated.
         */
        handleSessionChange: on('init', observer('session.isAuthenticated', 'session.data.authenticated.session', function () {
            var logger = this.get('logger');
            var isAuthenticated = this.get('session.isAuthenticated');
            var sessionData = this.get('session.data.authenticated.session');

            logger.info(TAG, "handleSessionChange isAuthenticated: " + isAuthenticated);

            if (isAuthenticated && !isEmpty(sessionData) && typeOf(sessionData) === 'instance') {
                this.set('user', sessionData.get('user'));
                this.set('company', sessionData.get('company'));
            }
        })),

        // region User Management

        authenticate: function authenticate(email, pwd) {
            var _this = this;

            this.get('logger').info(TAG, 'authenticate');

            return new Promise(function (resolve, reject) {
                // Check if the user exists in the API DB. This is the login method.
                _this.existUser(email, pwd).then(function () {
                    var session = _oceasoftWebObjectsSession["default"].create();

                    Promise.all([_this.getUser(email, pwd), _this.getCustomerInfos(email, pwd)]).then(function (values) {
                        var user = values[0];
                        var company = values[1];

                        // Store the user password because the API requires it for each authenticated call.
                        // NOTE: Not the best solution for securing an API call.
                        user.set('password', pwd);

                        // Store the Company ID into the company object. The server does not sent it in the response.
                        company.set('id', user.get('companyID'));

                        session.set('user', user);
                        session.set('company', company);

                        _this.get('logger').info(TAG, 'authenticate: successful');

                        resolve({ session: session });
                    })["catch"](function () {
                        reject(Response.FAILED);
                    });
                })["catch"](function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Checks whether the specified sensor is associated with the customer ID indicated by the Email and password parameters.
         * For Atlas, if the sensor does not exist in DB, return SENSOR_AVAILABLE, if sensor exist but not linked to the user’s
         * customer account, return KO, if linked to the user’s customer account, return OK
         *
         * @param sensorSN
         */
        existSensor: function existSensor(sensorSN) {
            var _this2 = this;

            this.get('logger').info(TAG, "existSensor");

            return new Promise(function (resolve, reject) {
                getAPICall(_this2.get('user'), Type.GET, false, 'ExistSensor', sensorSN).then(function (data) {
                    resolve(data);
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Creates the customer account with the first new user account associated with the specified parameters.
         *
         * @param user Object
         * @param customer Object
         * @param voucher Object
         */
        createUser: function createUser(user, customer, voucher) {
            this.get('logger').info(TAG, "createUser");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'CreateUser', user.get('email'), user.get('password'), user.get('firstName'), user.get('lastName'), customer.get('name'), customer.get('address1'), customer.get('address2'), customer.get('postalCode'), customer.get('city'), customer.get('country'), customer.get('tel'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, user.get('language'), voucher.get('serial'), user.get('unit')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.NEW_USER) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Updates an existing user’s account.
         *
         * @param user Object
         * @param newPwd
         */
        updateUser: function updateUser(user, newPwd) {
            this.get('logger').info(TAG, "updateUser");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'UpdateUser', user.get('email'), user.get('password'), newPwd, user.get('firstName'), user.get('lastName'), user.get('notifyStart'), user.get('notifyRead'), user.get('notifyStop'), user.get('notifyAlarm'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, user.get('language'), user.get('dateTimeFormat'), user.get('unit'), user.get('decimalSeparator')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.UPDATE_USER) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Enables admin user to create or update a user account.
         *
         * @param user Object
         */
        addUser: function addUser(user) {
            var _this3 = this;

            this.get('logger').info(TAG, "addUser");

            return new Promise(function (resolve, reject) {
                getAPICall(_this3.get('user'), Type.GET, false, 'AddUser', user.get('email'), user.get('password'), user.get('firstName'), user.get('lastName'), user.get('level'), user.get('notifyStart'), user.get('notifyRead'), user.get('notifyStop'), user.get('notifyAlarm'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, user.get('language'), user.get('dateTimeFormat'), user.get('unit'), user.get('decimalSeparator')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.NEW_USER) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Enables admin user to update the user account.
         *
         * @param user Object
         */
        changeUser: function changeUser(user) {
            var _this4 = this;

            this.get('logger').info(TAG, "changeUser");

            return new Promise(function (resolve, reject) {
                getAPICall(_this4.get('user'), Type.GET, false, 'ChangeUser', user.get('email'), user.get('password'), user.get('firstName'), user.get('lastName'), user.get('level'), user.get('enabled'), user.get('notifyStart'), user.get('notifyRead'), user.get('notifyStop'), user.get('notifyAlarm'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, user.get('language'), user.get('dateTimeFormat'), user.get('unit'), user.get('decimalSeparator')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.UPDATE_USER) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Server sends an e-mail message containing a new password.
         *
         * @param email
         */
        lostPassword: function lostPassword(email) {
            var _this5 = this;

            this.get('logger').info(TAG, "lostPassword");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'LostPassword', email, _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this5.get('i18n.locale')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.OK) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Checks whether the specified sensor is associated with the customer ID indicated by the Email and password parameters.
         *
         * @param email
         * @param password
         */
        existUser: function existUser(email, password) {
            this.get('logger').info(TAG, "existUser");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'ExistUser', email, password).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.EXIST_USER_OK) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Returns information regarding the user specified by email (UserEmail)
         *
         * @param email
         */
        getUser: function getUser(userEmail, userPwd, email) {
            var _this6 = this;

            this.get('logger').info(TAG, "getUser");

            return new Promise(function (resolve, reject) {
                var args = [];
                if (!isEmpty(userEmail) && !isEmpty(userPwd)) {
                    args.push(userEmail, userPwd);
                }

                if (!isEmpty(email)) {
                    args.push(email);
                }

                getAPICall.apply(undefined, [!isEmpty(userEmail) && !isEmpty(userPwd) ? null : _this6.get('user'), Type.GET, false, 'GetUser'].concat(args)).then(function (data) {
                    resolve(_oceasoftWebObjectsUser["default"].create({ apiResponse: data }));
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * If the user is an administrator, this function returns the list of users for CustomerID.
         */
        listUser: function listUser() {
            var _this7 = this;

            this.get('logger').info(TAG, "listUser");

            var currentUser = this.get('user');

            return new Promise(function (resolve, reject) {
                getAPICall(_this7.get('user'), Type.GET, true, 'ListUser', _this7.get('company.id')).then(function (data) {
                    resolve(new A(mapData(data, _oceasoftWebObjectsUser["default"])));
                }, function (err) {
                    reject(err);
                });
            });
        },

        // endregion

        // region Customer or Company Management

        /**
         * Returns client account information relating to the user.
         */
        getCustomerInfos: function getCustomerInfos(userEmail, userPwd) {
            var _this8 = this;

            this.get('logger').info(TAG, "getCustomerInfos");

            return new Promise(function (resolve, reject) {
                var args = [];
                if (!isEmpty(userEmail) && !isEmpty(userPwd)) {
                    args.push(userEmail, userPwd);
                }

                getAPICall.apply(undefined, [!isEmpty(userEmail) && !isEmpty(userPwd) ? null : _this8.get('user'), Type.GET, false, 'GetCustomerInfos'].concat(args)).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsCompany["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        updateCustomer: function updateCustomer(company) {
            var _this9 = this;

            this.get('logger').info(TAG, "updateCustomer");

            return new Promise(function (resolve, reject) {
                getAPICall(_this9.get('user'), Type.GET, false, 'updateCustomer', company.get('name'), company.get('address1'), company.get('address2'), company.get('postalCode'), company.get('city'), company.get('country'), company.get('tel')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.OK) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        // endregion

        // region Module Voucher Management

        /**
         * Link an Module module to a particular customer account.
         *
         * @param emerald Object
         */
        associateEmerald: function associateEmerald(emerald) {
            var _this10 = this;

            this.get('logger').info(TAG, "associateEmerald");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'AssociateEmerald', emerald.get('serial').toUpperCase(), _this10.get('user.email'), _this10.get('user.password'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this10.get('i18n.locale')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.OK) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Removes an Module module from the list of associated modules.
         *
         * @param emerald object
         */
        removeEmerald: function removeEmerald(emerald) {
            var _this11 = this;

            this.get('logger').info(TAG, "removeEmerald");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'RemoveEmerald', emerald.get('serial').toUpperCase(), _this11.get('user.email'), _this11.get('user.password'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this11.get('i18n.locale')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.OK) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Returns list of sensor serial numbers associated with customer (for admin only).
         */
        listEmerald: function listEmerald() {
            var _this12 = this;

            this.get('logger').info(TAG, "listEmerald");

            // TODO: check, customerID is in front of email and pwd...
            return new Promise(function (resolve, reject) {

                getAPICall(_this12.get('user'), Type.GET, true, 'ListEmeraldByUser').then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsModule["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        getEmerald: function getEmerald(serial) {
            var _this13 = this;

            this.get('logger').info(TAG, "getEmerald");

            return new Promise(function (resolve, reject) {
                _this13.listEmerald().then(function (data) {
                    Rx.Observable.fromArray(data).filter(function (emerald) {
                        return emerald.get('serial') === serial;
                    }).toArray().subscribe(function (data) {
                        if (isEmpty(data)) {
                            reject(Response.NOT_IN_DB_SENSOR);
                        } else {
                            resolve(data[0]);
                        }
                    });
                })["catch"](function (reason) {
                    reject(reason);
                });
            });
        },

        /**
         * Link a CustomerID to a voucher
         *
         * @param voucher Object
         */
        associateVoucher: function associateVoucher(voucher) {
            var _this14 = this;

            this.get('logger').info(TAG, "associateVoucherEx");

            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, false, 'AssociateVoucherEx', voucher.get('serial').toUpperCase(), _this14.get('user.email'), _this14.get('user.password'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this14.get('i18n.locale')).then(function (response) {
                    if (response === _oceasoftWebObjectsConstants["default"].API.Response.OK) {
                        resolve();
                    } else {
                        var data = mapData(response, _oceasoftWebObjectsAssociateVoucherResponse["default"]);

                        if (data.get('success')) {
                            resolve();
                        } else {
                            reject(data);
                        }
                    }
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Returns list of vouchers for a company ID, if the user is admin.
         *
         * @param customerID
         */
        listVoucher: function listVoucher(customerID) {
            var _this15 = this;

            this.get('logger').info(TAG, "listVoucher");

            customerID = customerID || this.get('company.id');

            // TODO: check, customerID is in front of email and pwd...
            return new Promise(function (resolve, reject) {
                getAPICall(null, Type.GET, true, 'ListVoucher', customerID, _this15.get('user.email'), _this15.get('user.password')).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsVoucher["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        // endregion

        // region Module mission/reading Management

        /**
         * Save or update mission.
         *
         * @param customerID
         * @param email
         * @param pwd
         * @param missionID
         * @param missionName
         * @param progDate
         * @param startDate
         * @param endDate
         * @param highLimit
         * @param lowLimit
         * @param highLimitEnabled
         * @param lowLimitEnabled
         * @param highDelay
         * @param lowDelay
         * @param sensorSN
         * @param sensorName
         * @param evtType
         * @param accessRight
         * @param provider
         * @param language
         */
        // setMission: function (customerID, email, pwd, missionID, missionName, progDate, startDate, endDate, highLimit, lowLimit, highLimitEnabled, lowLimitEnabled, highDelay, lowDelay, sensorSN, sensorName, evtType, accessRight, provider, language) {
        //   // TODO: figure this out
        // },

        /**
         * Sets mission reading parameters.
         *
         * @param missionID
         * @param sensorSN
         * @param lastReading
         * @param intrval
         * @param readings
         * @param count
         * @param provider
         * @param language
         */
        // setReadings: function (missionID, emeraldSerial, lastReading, intrval, readings, count, provider, language) {
        //   // TODO: figure this out
        // },

        /**
         * Add reading to the current mission (Warning : readings are not horodated by sensor, but by mobile or gateway)
         *
         * @param email
         * @param pwd
         * @param sensorSN
         * @param value
         * @param valueIndex
         * @param posX
         * @param posY
         * @param locationDate
         * @param alarm
         * @param provider
         * @param language
         */
        // setReadingsWatchMode: function (email, pwd, sensorSN, value, valueIndex, posX, posY, locationDate, alarm, provider, language) {
        //   // TODO: figure this out
        // },

        /**
         * Record or update an event.
         *
         * @param event Object
         * @param missionID
         * @param emeraldSerial
         */
        setEvent: function setEvent(event, missionID, emeraldSerial) {
            var _this16 = this;

            this.get('logger').info(TAG, "setEvent");

            return new Promise(function (resolve, reject) {
                getAPICall(_this16.get('user'), Type.GET, false, 'SetEvent', missionID, emeraldSerial, event.get('type'), event.get('date'), event.get('data1'), event.get('data2'), _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this16.get('i18n.locale')).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsMission["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Checks whether the user is in the database and if the user has access rights to this mission. Returns mission details.
         *
         * @param missionID
         */
        getMission: function getMission(missionID) {
            var _this17 = this;

            this.get('logger').info(TAG, "getMission");

            return new Promise(function (resolve, reject) {
                getAPICall(_this17.get('user'), Type.GET, false, 'GetMission', missionID).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsMission["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Returns mission readings.
         *
         * @param missionID
         */
        listReadings: function listReadings(missionID) {
            var _this18 = this;

            this.get('logger').info(TAG, "listReadings");

            return new Promise(function (resolve, reject) {
                getAPICall(_this18.get('user'), Type.GET, true, 'GetReadings', missionID).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsReading["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * Returns mission events.
         *
         * @param missionID
         */
        listEvents: function listEvents(missionID) {
            var _this19 = this;

            this.get('logger').info(TAG, "listEvents");

            return new Promise(function (resolve, reject) {
                getAPICall(_this19.get('user'), Type.GET, true, 'GetEvents', missionID).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsEvent["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        /**
         * If the user is Admin, returns the list of missions for the Module module. If the user is not Admin, returns only the mission to which the user has access.
         *
         * @param emeraldID Module ID
         * @param startDate
         * @param endDate
         */
        listMissionByEmerald: function listMissionByEmerald(emeraldID, startDate, endDate) {
            var _this20 = this;

            this.get('logger').info(TAG, "listMissionByEmerald");

            return new Promise(function (resolve, reject) {
                getAPICall(_this20.get('user'), Type.GET, true, 'ListMissionBySN', emeraldID, isEmpty(startDate) ? "1971-12-30 00:00:00" : (0, _moment["default"])(startDate).format("YYYY-MM-DD HH:mm:ss"), isEmpty(endDate) ? "2100-12-30 00:00:00" : (0, _moment["default"])(endDate).format("YYYY-MM-DD HH:mm:ss")).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsMission["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        listAllMissionByState: function listAllMissionByState(state) {
            var _this21 = this;

            this.get('logger').info(TAG, "listAllMissionByState");

            return new Promise(function (resolve, reject) {
                getAPICall(_this21.get('user'), Type.GET, true, 'ListMission', state).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsMission["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        getLastModuleMissionBySerial: function getLastModuleMissionBySerial(serial) {
            var _this22 = this;

            this.get('logger').info(TAG, "getLastModuleMissionBySerial");

            return new Promise(function (resolve, reject) {
                getAPICall(_this22.get('user'), Type.GET, true, 'LastOrCurrentMissionBySN', serial).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsMission["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        },

        mapDataOnObject: function mapDataOnObject(data, object) {
            if (isEmpty(object)) {
                return null;
            }

            if (data === false) {
                return null;
            }

            // Map Array
            if (typeOf(data) === 'array') {
                var _ret2 = (function () {
                    var dataArray = new A([]);

                    if (!isEmpty(data)) {
                        data.forEach(function (item) {
                            dataArray.push(object.create({ apiResponse: item }));
                        });
                    }
                    return {
                        v: dataArray
                    };
                })();

                if (typeof _ret2 === "object") return _ret2.v;
            }

            // Map Object
            return object.create({ apiResponse: data });
        },

        getAllModulesAndMissionsByDate: function getAllModulesAndMissionsByDate(startDate, endDate) {
            this.get('logger').info(TAG, "getAllModulesAndMissionsByDate");
            return getAPICall(this.get('user'), Type.GET, true, 'ListMissionByDateProg', isEmpty(startDate) ? "0" : (0, _moment["default"])(startDate).format("YYYY-MM-DD HH:mm:ss"), isEmpty(endDate) ? "0" : (0, _moment["default"])(endDate).format("YYYY-MM-DD HH:mm:ss"));
        },

        getAllModulesAndMissionsByDateCount: function getAllModulesAndMissionsByDateCount(startDate, endDate) {
            this.get('logger').info(TAG, "getAllModulesAndMissionsByDateCount");
            return getAPICall(this.get('user'), Type.GET, true, 'ListMissionByDateProgCount', isEmpty(startDate) ? "0" : (0, _moment["default"])(startDate).format("YYYY-MM-DD HH:mm:ss"), isEmpty(endDate) ? "0" : (0, _moment["default"])(endDate).format("YYYY-MM-DD HH:mm:ss"));
        },

        /**
         * Returns mission report in PDF format as stream.
         *
         * @param mission = mission ID
         * @param beginningDate = YYYY-MM-DD hh:mm:ss, null if whole mission
         * @param endingDate = YYYY-MM-DD hh:mm:ss, null if whole mission
         * @param dateFormat = YYYY-MM-DD
         * @param unit = 0:C, 1:F
         * @param fileType = pdf, xls
         *
         */
        getMissionReport: function getMissionReport(mission, beginningDate, endingDate, unit, fileType) {
            var _this23 = this;

            this.get('logger').info(TAG, "getMissionReport");

            return new Promise(function (resolve, reject) {
                var req = new XMLHttpRequest();
                req.open(Type.GET, _oceasoftWebConfigEnvironment["default"].APP.API.URL + getURL('GetMissionReportEx', _this23.get('user.email'), _this23.get('user.password'), mission.get('mid'), beginningDate, endingDate, _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this23.get('i18n.locale'), unit, new Date().getTimezoneOffset() * -1, fileType), true);
                req.setRequestHeader("Authorization", "Basic " + btoa(_oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.USERNAME + ":" + _oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.PASSWORD));
                req.responseType = "blob";

                req.onload = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        resolve(req.response);
                        return;
                    }

                    reject(Response.FAILED);
                };

                req.send();
            });
        },

        getReportMultiGraph: function getReportMultiGraph(missions, beginningDate, endingDate, dateFormat, unit, fileType) {
            var _this24 = this;

            this.get('logger').info(TAG, "getMissionReport");

            return new Promise(function (resolve, reject) {
                var req = new XMLHttpRequest();
                req.open(Type.GET, _oceasoftWebConfigEnvironment["default"].APP.API.URL + getURL('GetReportMultiGraphEx', _this24.get('user.email'), _this24.get('user.password'), beginningDate, endingDate, _oceasoftWebConfigEnvironment["default"].APP.API.PROVIDER, _this24.get('i18n.locale'), dateFormat, unit, new Date().getTimezoneOffset() * -1, missions.join(';'), fileType), true);
                req.setRequestHeader("Authorization", "Basic " + btoa(_oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.USERNAME + ":" + _oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.PASSWORD));
                req.responseType = "blob";

                req.onload = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        resolve(req.response);
                        return;
                    }

                    reject(Response.FAILED);
                };

                req.send();
            });
        },

        searchMissionByUser: function searchMissionByUser() {
            this.get('logger').info(TAG, "searchMissionByUser");
        },

        getReadingsWatchMode: function getReadingsWatchMode(missionID) {
            var _this25 = this;

            this.get('logger').info(TAG, "getReadingsWatchMode");

            return new Promise(function (resolve, reject) {
                getAPICall(_this25.get('user'), Type.GET, true, 'GetReadingsWatchMode', missionID).then(function (data) {
                    resolve(mapData(data, _oceasoftWebObjectsReading["default"]));
                }, function (err) {
                    reject(err);
                });
            });
        }

    });
});
// synchronizeReadings: function () {
//
// }

// endregion