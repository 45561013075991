define('oceasoft-web/mixins/loading-controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    isLoading: false,

    actions: {
      resetController: function resetController() {
        this._super.apply(this, arguments);

        this.send('hideLoading');
      },

      showLoading: function showLoading() {
        var bubble = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.set('isLoading', true);
        return bubble;
      },

      hideLoading: function hideLoading() {
        var bubble = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.set('isLoading', false);
        return bubble;
      }
    }

  });
});