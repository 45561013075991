define('oceasoft-web/mixins/root-route', ['exports', 'ember'], function (exports, _ember) {
  var observer = _ember['default'].observer;
  exports['default'] = _ember['default'].Mixin.create({

    activate: function activate() {
      this._super.apply(this, arguments);
      this.changeComponents.apply(this);
    },

    handleComponentsChange: observer('components', function () {
      this.changeComponents.apply(this);
    }),

    changeComponents: function changeComponents() {
      var applicationController = this.controllerFor('application');
      var innerComponents = this.get('components') || [];

      Rx.Observable.fromArray(Object.keys(applicationController.get('components')))['do'](function (component) {
        applicationController.set("components." + component, innerComponents.indexOf(component) !== -1);
      }).subscribe();
    }

  });
});