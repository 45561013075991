define("oceasoft-web/components/oc-box", ["exports", "ember", "oceasoft-web/mixins/remove-empty-yields"], function (exports, _ember, _oceasoftWebMixinsRemoveEmptyYields) {
  exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsRemoveEmptyYields["default"], {
    tagName: 'oc-box',
    classNameBindings: ['isLight:light:dark', 'padding::no-padding'],
    attributeBindings: ['shadow'],

    isLight: true,
    padding: true,
    shadow: null,

    checkEmptyElements: ['> .header', '> .body', '> .footer'],

    header: { isHeader: true },
    body: { isBody: true },
    footer: { isFooter: true }

  });
});