define("oceasoft-web/components/oc-list-item", ["exports", "ember", "oceasoft-web/mixins/remove-empty-yields"], function (exports, _ember, _oceasoftWebMixinsRemoveEmptyYields) {
    var on = _ember["default"].on;
    var computed = _ember["default"].computed;
    var isEmpty = _ember["default"].isEmpty;
    exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsRemoveEmptyYields["default"], {
        tagName: 'oc-list-item',
        classNameBindings: ['active:active', 'isExpandable:expandable', 'isCheckable:checkable', 'expanded:expanded', 'hasImageBlock:image'],

        attributeBindings: ['id'],

        id: null,

        title: null,
        description: null,
        imageClass: null,
        hasImageBlock: true,
        isExpandable: false,

        expanded: false,
        initExpanded: false,

        isCheckable: false,

        checkEmptyElements: ['> .left', '> .right .content'],

        image: { isImage: true },
        content: { isContent: true },
        expandedContent: { isExpandedContent: true },

        color: null,
        colorStyle: computed('color', function () {
            var color = this.get('color');
            return _ember["default"].String.htmlSafe(isEmpty(color) ? "" : "background-color: " + color);
        }),

        active: false,

        handleInit: on('init', function () {
            this.set('expanded', this.get('initExpanded'));
        }),

        click: function click() {
            this.sendAction();
        },

        actions: {
            toggleExpanded: function toggleExpanded() {
                if (this.get('isExpandable')) {

                    if (this.$().hasClass('expanded')) {
                        this.$().removeClass('expanded');
                        this.set('expanded', false);
                    } else {
                        this.$().addClass('expanded');
                        this.set('expanded', true);
                    }
                }
            }
        }
    });
});