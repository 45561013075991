define('oceasoft-web/routes/profiles/users', ['exports', 'ember', 'ember-i18n', 'oceasoft-web/mixins/admin-route'], function (exports, _ember, _emberI18n, _oceasoftWebMixinsAdminRoute) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_oceasoftWebMixinsAdminRoute['default'], {

    queryParams: {
      q: {
        replace: true
      }
    },

    api: service('oc-api'),

    titleToken: (0, _emberI18n.translationMacro)('profiles.page.user_list'),

    model: function model() {
      return this.get('api').listUser();
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.send('resetController');
    }

  });
});