define("oceasoft-web/objects/user", ["exports", "ember", "oceasoft-web/objects/utils", "oceasoft-web/objects/api-object", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsUtils, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsConstants) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

    var computed = _ember["default"].computed;
    var _Utils$String = _oceasoftWebObjectsUtils["default"].String;
    var isEmpty = _Utils$String.isEmpty;
    var shortMD5 = _Utils$String.shortMD5;
    var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;
    var _Constants$User = _oceasoftWebObjectsConstants["default"].User;
    var TimeFormat = _Constants$User.TimeFormat;
    var DateFormat = _Constants$User.DateFormat;
    var TemperatureUnit = _oceasoftWebObjectsConstants["default"].TemperatureUnit;
    var DecimalSeparator = _oceasoftWebObjectsConstants["default"].DecimalSeparator;

    var User = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

        init: function init() {
            setDefaultProperties(this, {
                userId: null,
                firstName: null,
                lastName: null,
                email: null,
                level: _oceasoftWebObjectsConstants["default"].User.Level.USER,
                enabled: true,
                companyID: null,
                notifyStart: false,
                notifyStop: false,
                notifyRead: false,
                notifyAlarm: false,
                password: null,

                language: null,
                unit: TemperatureUnit.CELSIUS,
                decimalSeparator: DecimalSeparator.POINT,
                dateFormat: DateFormat.DMY,
                timeFormat: TimeFormat.MILITARY,

                plainPassword: '',
                plainPasswordConfirm: ''
            });

            this._super.apply(this, arguments);

            var apiResponse = this.get('apiResponse');

            if (!isEmpty(apiResponse)) {
                this.set('userId', parseInt(apiResponse.userId));
                this.set('firstName', apiResponse.Firstname);
                this.set('lastName', apiResponse.Lastname);
                this.set('level', parseInt(apiResponse.Level));
                this.set('notifyStart', Boolean(parseInt(apiResponse.NotifyStart)));
                this.set('notifyStop', Boolean(parseInt(apiResponse.NotifyStop)));
                this.set('notifyRead', Boolean(parseInt(apiResponse.NotifyRead)));
                this.set('notifyAlarm', Boolean(parseInt(apiResponse.NotifyAlarm)));

                this.set('language', apiResponse.Language);
                this.set('unit', parseInt(apiResponse.unit));

                try {
                    if (!isEmpty(apiResponse.DateFormat)) {
                        var formats = apiResponse.DateFormat.match(/^(\S+)\s(.*)/).slice(1);
                        this.set('dateFormat', formats[0]);
                        this.set('timeFormat', formats[1]);
                    }
                } catch (e) {}

                this.set('decimalSeparator', apiResponse.DecimalSeparator);

                delete this.apiResponse;
            }
        },

        id: computed('email', function () {
            return shortMD5(this.get('email'));
        }),

        fullName: computed('firstName', 'lastName', {
            get: function get() {
                var _getProperties = this.getProperties('firstName', 'lastName');

                var firstName = _getProperties.firstName;
                var lastName = _getProperties.lastName;

                var fullName = '';

                if (!isEmpty(firstName)) {
                    fullName += firstName;
                }

                if (!isEmpty(lastName)) {
                    if (!isEmpty(isEmpty)) {
                        fullName += ' ';
                    }

                    fullName += lastName;
                }

                return fullName;
            },

            set: function set(key, value) {
                var _value$split = value.split(/\s+/);

                var _value$split2 = _slicedToArray(_value$split, 2);

                var firstName = _value$split2[0];
                var lastName = _value$split2[1];

                this.set('firstName', firstName);
                this.set('lastName', lastName);
                return value;
            }
        }),

        isAdministrator: computed.equal('level', _oceasoftWebObjectsConstants["default"].User.Level.ADMINISTRATOR),

        isNormalUser: computed.equal('level', _oceasoftWebObjectsConstants["default"].User.Level.USER),

        dateTimeFormat: computed('dateFormat', 'timeFormat', function () {
            return [this.get('dateFormat'), this.get('timeFormat')].join(' ');
        }).readOnly(),

        copy: function copy() {
            return User.create(this);
        }

    });

    exports["default"] = User;
});