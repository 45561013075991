define('oceasoft-web/helpers/contains-any', ['exports', 'ember'], function (exports, _ember) {
    exports.constant = constant;
    var isEmpty = _ember['default'].isEmpty;

    function constant(params) {
        var a = params[0];
        var b = params[1];

        if (!isEmpty(a) && !isEmpty(b)) {
            return a.filter(function (el) {
                return b.indexOf(el) > -1;
            }).length > 0;
        }

        return false;
    }

    exports['default'] = _ember['default'].Helper.helper(constant);
});