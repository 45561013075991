define("oceasoft-web/routes/profiles/me", ["exports", "ember", "ember-i18n", "oceasoft-web/objects/constants", "oceasoft-web/mixins/session-manager"], function (exports, _ember, _emberI18n, _oceasoftWebObjectsConstants, _oceasoftWebMixinsSessionManager) {
  var _slice = Array.prototype.slice;
  var UserControllerScope = _oceasoftWebObjectsConstants["default"].UserControllerScope;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsSessionManager["default"], {

    session: service('session'),

    titleToken: (0, _emberI18n.translationMacro)('profiles.page.my_profile'),

    controllerName: 'profiles.users.user',

    model: function model() {
      return this.get('user');
    },

    resetController: function resetController(controller) {
      var _super;

      (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));
      controller.send('resetController');
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('scope', UserControllerScope.ME);
    },

    renderTemplate: function renderTemplate(controller, model) {
      this.render('profiles.users.user', {
        controller: controller,
        model: model
      });
    }

  });
});