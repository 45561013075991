define("oceasoft-web/controllers/voucher/new", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/objects/voucher", "oceasoft-web/objects/utils", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/mixins/editable-controller", "oceasoft-web/mixins/session-manager"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebObjectsVoucher, _oceasoftWebObjectsUtils, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebMixinsEditableController, _oceasoftWebMixinsSessionManager) {

    var TAG = 'voucher.new controller';
    var service = _ember["default"].inject.service;
    var typeOf = _ember["default"].typeOf;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;
    var isEmpty = _oceasoftWebObjectsUtils["default"].String.isEmpty;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], _oceasoftWebMixinsEditableController["default"], _oceasoftWebMixinsSessionManager["default"], {

        logger: service('oc-logger'),
        api: service('oc-api'),
        i18n: service('i18n'),
        notify: service('notify'),

        actions: {

            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');
            },

            create: function create() {
                var _this = this;

                this.get('logger').info(TAG, 'create');

                // Reset
                this.send('hideError');

                var api = this.get('api');
                var voucher = this.get('model');
                var i18n = this.get('i18n');

                // Validate The Serial
                var hasError = false;

                if (isEmpty(voucher.get('serial'))) {
                    this.send('setFieldError', 'serial', 'error.empty_field');
                    hasError = true;
                } else if (voucher.get('serial').replace(' ', '-').length < 35) {
                    this.send('showWarningError', 'error.unable_to_complete_operation', 'voucher.page.incorrect_voucher_key');
                    this.send('setFieldError', 'serial', 'register.error_voucher_invalid_field');
                    hasError = true;
                }

                if (hasError) {
                    return;
                }

                this.send('showLoading');

                api.associateVoucher(voucher).then(function () {
                    _this.send('hideLoading');
                    _this.send('notifySessionDataChanged');

                    _this.get('notify').success(i18n.t('voucher.page.new_save_msg').toString());

                    _this.transitionToRoute('voucher.index');
                })["catch"](function (response) {
                    _this.send('hideLoading');

                    var reason = typeOf(response) === 'instance' ? response.get('error') : response;

                    switch (reason) {
                        default:
                        case Response.NOT_IN_DB_VOUCHER:
                        case Response.WRONG_COMPANY_ID:
                        case Response.INVALID_VOUCHER:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'voucher.page.incorrect_voucher_key');
                            _this.send('setFieldError', 'serial', 'register.error_voucher_invalid_field');
                            break;

                        case Response.VOUCHER_ALREADY_USED:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'voucher.page.key_already_used');
                            _this.send('setFieldError', 'serial', 'register.error_voucher_used_field');
                            break;

                        case Response.FAILED:
                        case Response.DB_CONNECTION_FAILED:
                            _this.send('showGeneralNetworkError');
                            break;

                        case Response.NOT_IN_DB_USER:
                        case Response.USER_IS_NOT_ADMIN:
                            break;

                        case Response.INVALID_VOUCHER_DATE:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'voucher.page.voucher_expired');
                            break;

                        case Response.INVALID_VOUCHER_SPACE:
                            _this.send('showWarningError', i18n.t('error.unable_to_complete_operation'), i18n.t('voucher.page.voucher_remove_modules', { x: Math.abs(response.get('details')) }), false);
                            break;
                    }

                    _this.send('handleUserAPIErrors', reason);
                });
            },

            reset: function reset() {
                this.get('logger').info(TAG, 'reset');

                this.set('model', _oceasoftWebObjectsVoucher["default"].create({}));
                this.send('clearFieldErrors');
            }
        }
    });
});