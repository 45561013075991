define("oceasoft-web/routes/application", ["exports", "ember", "oceasoft-web/mixins/loading-indicator", "ember-simple-auth/mixins/application-route-mixin", "oceasoft-web/mixins/application-content-scroll-route", "oceasoft-web/objects/constants", "oceasoft-web/objects/error", "oceasoft-web/mixins/metrics-route", "moment"], function (exports, _ember, _oceasoftWebMixinsLoadingIndicator, _emberSimpleAuthMixinsApplicationRouteMixin, _oceasoftWebMixinsApplicationContentScrollRoute, _oceasoftWebObjectsConstants, _oceasoftWebObjectsError, _oceasoftWebMixinsMetricsRoute, _moment) {

    var TAG = "application route";
    var service = _ember["default"].inject.service;
    var typeOf = _ember["default"].typeOf;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], _oceasoftWebMixinsLoadingIndicator["default"], _oceasoftWebMixinsApplicationContentScrollRoute["default"], _oceasoftWebMixinsMetricsRoute["default"], {

        notify: service('notify'),
        logger: service('oc-logger'),
        session: service('session'),
        moment: service(),
        i18n: service(),

        title: function title(tokens) {
            var title = 'EDGEVue';

            if (tokens.length > 0) {
                title += ' - ' + tokens.join(' - ');
            }

            return title;
        },

        beforeModel: function beforeModel() {
            var locale = this.get('i18n.locale').toLowerCase();
            var emberMoment = this.get('moment');

            _moment["default"].locale(locale);
            _moment["default"].defaultFormat = _oceasoftWebObjectsConstants["default"].API.DATETIME_FORMAT;
            emberMoment.changeLocale(this.get('i18n.locale'));
            emberMoment.changeTimeZone(_moment["default"].tz.guess());
            emberMoment.set('defaultFormat', _oceasoftWebObjectsConstants["default"].API.DATETIME_FORMAT);
        },

        actions: {

            handleUserAPIErrors: function handleUserAPIErrors(apiError) {
                this.get('logger').info(TAG, 'handleUserAPIErrors');
                this.controllerFor('application').send('handleUserAPIErrors', apiError);
            },

            handleUserNotAdmin: function handleUserNotAdmin() {
                this.get('logger').info(TAG, 'handleUserNotAdmin');
                window.location.reload(true);
            },

            handleUserNotEnabled: function handleUserNotEnabled() {
                this.get('logger').info(TAG, 'handleUserNotEnabled');
                this.get('session').invalidate();
            },

            logout: function logout() {
                this.get('logger').info(TAG, 'logout');
                this.controllerFor('application').send('logout');
            },

            error: function error(_error, transition) {
                var session = this.get('session');

                if (typeOf(_error) === 'instance') {
                    this.transitionTo('error', 'error').then(function (errorRoute) {
                        errorRoute.controller.set('model', _error);
                    });

                    return;
                } else if (typeOf(_error) === 'number') {
                    switch (_error) {
                        case _oceasoftWebObjectsConstants["default"].API.Response.NOT_IN_DB_USER:
                            // There could be a major case where the User is erased from the DB and is still logged in the platform.
                            // We check if the user is logged and invalidate the session.
                            if (session.get('isAuthenticated')) {
                                session.invalidate();
                            }
                            return;
                    }
                }

                this.transitionTo('error', 'error').then(function (errorRoute) {
                    errorRoute.controller.set('model', _oceasoftWebObjectsError["default"].create({
                        code: _error,
                        type: _oceasoftWebObjectsConstants["default"].Error.Type.FATAL,
                        image: 'general_error_img',
                        icon: null,
                        title: 'error.general',
                        description: 'error.general_description',
                        attemptedTransition: transition
                    }));
                });
            }

        }

    });
});