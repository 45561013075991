define('oceasoft-web/components/oc-logo', ['exports', 'ember'], function (exports, _ember) {
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-logo',
    classNameBindings: ['isLight:light:dark'],

    isLight: true,

    handleClick: on('click', function () {
      this.sendAction('action');
    })

  });
});