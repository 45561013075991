define('oceasoft-web/controllers/about/accessories', ['exports', 'ember', 'oceasoft-web/objects/constants'], function (exports, _ember, _oceasoftWebObjectsConstants) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {

      moreInfo: function moreInfo() {
        window.open(_oceasoftWebObjectsConstants['default'].URL_ACCESSORIES_GUIDE, '_blank').focus();
      }

    }

  });
});