define("oceasoft-web/objects/module", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/utils", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsUtils, _oceasoftWebObjectsConstants) {
    var computed = _ember["default"].computed;
    var isEmpty = _ember["default"].isEmpty;
    var A = _ember["default"].A;
    var _Constants$Module = _oceasoftWebObjectsConstants["default"].Module;
    var Type = _Constants$Module.Type;
    var State = _Constants$Module.State;
    var shortMD5 = _oceasoftWebObjectsUtils["default"].String.shortMD5;
    var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;

    var Module = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

        init: function init() {
            setDefaultProperties(this, {
                serial: null,
                name: null,
                dateExpiration: null
            });

            this._super.apply(this, arguments);

            var apiResponse = this.get('apiResponse');

            if (!isEmpty(apiResponse) && typeof apiResponse.SN !== 'undefined') {
                this.set('serial', apiResponse.SN.toUpperCase());
                this.set('dateExpiration', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.ExpDate));

                delete this.apiResponse;
            }
        },

        id: computed('serial', function () {
            return shortMD5(this.get('serial'));
        }),

        missions: new A([]),

        lastMission: new A([]),
        CurrentMissionState: null,
        CurrentMissionStateAlarm: null,

        state: computed('CurrentMissionStateAlarm', function () {
            switch (parseInt(this.get('CurrentMissionStateAlarm'))) {
                case 0:
                    return State.NO_ALARMS;
                case 2:
                    return State.TECHNICAL_ALARMS;
                case 1:
                case 3:
                    return State.ALARMS;
                default:
                    return State.NO_MISSIONS;
            }
        }),

        type: computed('serial', function () {
            var serial = this.get('serial');

            if (!isEmpty(serial)) {
                switch (serial.substring(2, 4)) {
                    case '00':
                    case '01':
                    case '02':
                        return Type.EMERALD;

                    case '04':
                        return Type.ATLAS;

                    case '0A':
                    case '0E':
                        return Type.EMERALD2;

                    case '08':
                        return Type.ATLAS2;
                    case '09':
                        return Type.ATLAS2plus;
                    case '0C':
                        return Type.X2;
                }
            }

            return Type.UNKNOWN;
        }),

        hasCloudAccess: computed('serial', function () {
            var serial = this.get('serial');

            if (!isEmpty(serial)) {
                return ['2', '3'].indexOf(serial.substring(5, 6)) > -1;
            }

            return false;
        }),

        copy: function copy() {
            return Module.create(this);
        }

    });

    exports["default"] = Module;
});