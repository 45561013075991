define('oceasoft-web/instance-initializers/i18n', ['exports', 'oceasoft-web/config/environment'], function (exports, _oceasoftWebConfigEnvironment) {
  exports['default'] = {
    name: 'i18n',
    initialize: function initialize(appInstance) {
      var i18n = appInstance.lookup('service:i18n');
      var locales = i18n.get('locales');
      var userLocale = navigator.language || navigator.userLanguage || _oceasoftWebConfigEnvironment['default'].i18n.defaultLocale;

      if (locales.indexOf(userLocale) > -1) {
        i18n.set('locale', userLocale);
      } else if (userLocale.length > 2 && locales.indexOf(userLocale.substring(0, 2)) > -1) {
        i18n.set('locale', userLocale.substring(0, 2));
      } else {
        i18n.set('locale', _oceasoftWebConfigEnvironment['default'].i18n.defaultLocale);
      }
    }
  };
});