define("oceasoft-web/components/oc-menu", ["exports", "ember", "oceasoft-web/mixins/session-manager"], function (exports, _ember, _oceasoftWebMixinsSessionManager) {
  exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsSessionManager["default"], {
    tagName: 'oc-menu',

    actions: {
      logout: function logout() {
        this.sendAction('onLogout');
      }
    }
  });
});