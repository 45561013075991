define('oceasoft-web/components/oc-application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-application',
    classNameBindings: ['topbar', 'navigation'],

    topbar: false,
    navigation: false,

    actions: {
      logout: function logout() {
        this.sendAction('onLogout');
      }
    }
  });
});