define("oceasoft-web/mixins/metrics-route", ["exports", "ember", "oceasoft-web/config/environment"], function (exports, _ember, _oceasoftWebConfigEnvironment) {

    var TAG = "metrics";
    var scheduleOnce = _ember["default"].run.scheduleOnce;
    var service = _ember["default"].inject.service;
    var isEmpty = _ember["default"].isEmpty;
    var typeOf = _ember["default"].typeOf;
    exports["default"] = _ember["default"].Mixin.create({

        metrics: service('metrics'),
        session: service('session'),
        logger: service('oc-logger'),

        actions: {
            didTransition: function didTransition() {
                var _this = this;

                this._super.apply(this, arguments);

                this.get('logger').info(TAG, 'didTransition');

                if (!_oceasoftWebConfigEnvironment["default"].APP.METRICS) {
                    this.get('logger').warn(TAG, 'didTransition - metrics disabled');
                    return;
                }

                scheduleOnce('afterRender', this, function () {
                    var trackingOptions = {
                        title: document.title,
                        page: document.location.pathname
                    };

                    var session = _this.get('session');
                    if (session.get('isAuthenticated')) {
                        var user = session.get('data.internal.session.user');

                        if (!isEmpty(user) && typeOf(user) === 'instance') {
                            trackingOptions.userName = user.get('fullName');
                        }
                    }

                    _this.get('logger').info(TAG, 'tracking - options: ' + JSON.stringify(trackingOptions));
                    _this.get('metrics').trackPage(trackingOptions);
                });
            }
        }

    });
});