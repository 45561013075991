define("oceasoft-web/templates/pairings/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 7,
                      "column": 8
                    },
                    "end": {
                      "line": 9,
                      "column": 8
                    }
                  },
                  "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "oc-button", [], ["label", ["subexpr", "t", ["try_again"], [], ["loc", [null, [8, 28], [8, 43]]]], "primary", false, "action", "create"], ["loc", [null, [8, 10], [8, 75]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 6
                  },
                  "end": {
                    "line": 12,
                    "column": 6
                  }
                },
                "moduleName": "oceasoft-web/templates/pairings/new.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "error.type", ["loc", [null, [7, 18], [7, 28]]]], ["subexpr", "constant", ["Error.Type.NETWORK"], [], ["loc", [null, [7, 29], [7, 60]]]]], [], ["loc", [null, [7, 14], [7, 61]]]]], [], 0, null, ["loc", [null, [7, 8], [9, 15]]]], ["inline", "oc-button", [], ["label", ["subexpr", "t", ["ok"], [], ["loc", [null, [11, 26], [11, 34]]]], "primary", true, "action", ["subexpr", "action", [["subexpr", "line-if", [["subexpr", "eq", [["get", "error.type", ["loc", [null, [11, 76], [11, 86]]]], ["subexpr", "constant", ["Error.Type.ALERT"], [], ["loc", [null, [11, 87], [11, 116]]]]], [], ["loc", [null, [11, 72], [11, 117]]]], "goToList", "hideError"], [], ["loc", [null, [11, 63], [11, 141]]]]], [], ["loc", [null, [11, 55], [11, 142]]]]], ["loc", [null, [11, 8], [11, 144]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "oceasoft-web/templates/pairings/new.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "oc-message", [], ["title", ["subexpr", "@mut", [["get", "error.title", ["loc", [null, [6, 26], [6, 37]]]]], [], []], "description", ["subexpr", "@mut", [["get", "error.description", ["loc", [null, [6, 50], [6, 67]]]]], [], []], "icon", ["subexpr", "@mut", [["get", "error.icon", ["loc", [null, [6, 73], [6, 83]]]]], [], []], "important", true], 0, null, ["loc", [null, [6, 6], [12, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 8
                    },
                    "end": {
                      "line": 23,
                      "column": 8
                    }
                  },
                  "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "icon single-icon");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "title");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["pairings.page.new_module"], [], ["loc", [null, [21, 31], [21, 63]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 23,
                        "column": 8
                      },
                      "end": {
                        "line": 45,
                        "column": 8
                      }
                    },
                    "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\n            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "row align-middle");
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "columns");
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "font-large font-normal font-title");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("br");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "font-large");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "shrink columns");
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "read-sn-image");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "row-input");
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "small-12 medium-3 column-label word-wrap");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1, 1]);
                    var element8 = dom.childAt(fragment, [7]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element7, [5]), 0, 0);
                    morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
                    morphs[3] = dom.createMorphAt(element8, 3, 3);
                    return morphs;
                  },
                  statements: [["inline", "t", ["pairings.page.how_to_find_serial"], [], ["loc", [null, [27, 67], [27, 107]]]], ["inline", "t", ["pairings.page.find_serial_description"], [], ["loc", [null, [29, 44], [29, 89]]]], ["inline", "t", ["pairings.page.serial_nr"], [], ["loc", [null, [41, 70], [41, 101]]]], ["inline", "oc-input", [], ["class", "small-12 medium-9 columns", "type", "text", "mask", "************", "value", ["subexpr", "@mut", [["get", "model.serial", ["loc", [null, [42, 97], [42, 109]]]]], [], []], "error", ["subexpr", "@mut", [["get", "error.field.serial", ["loc", [null, [42, 116], [42, 134]]]]], [], []]], ["loc", [null, [42, 14], [42, 136]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 45,
                          "column": 8
                        },
                        "end": {
                          "line": 50,
                          "column": 8
                        }
                      },
                      "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "actions");
                      var el2 = dom.createTextNode("\n              ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n              ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n        ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element6 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(element6, 1, 1);
                      morphs[1] = dom.createMorphAt(element6, 3, 3);
                      return morphs;
                    },
                    statements: [["inline", "oc-button", [], ["icon", "check", "label", ["subexpr", "t", ["pairings.page.add_one_module"], [], ["loc", [null, [47, 45], [47, 79]]]], "action", "create", "disabled", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [47, 105], [47, 114]]]]], [], []], "primary", true], ["loc", [null, [47, 14], [47, 129]]]], ["inline", "oc-button", [], ["label", ["subexpr", "t", ["reset_fields"], ["count", 1], ["loc", [null, [48, 32], [48, 58]]]], "action", "resetModel", "disabled", ["subexpr", "or", [["get", "isLoading", ["loc", [null, [48, 92], [48, 101]]]], ["subexpr", "not", [["get", "isModelChanged", ["loc", [null, [48, 107], [48, 121]]]]], [], ["loc", [null, [48, 102], [48, 122]]]]], [], ["loc", [null, [48, 88], [48, 123]]]]], ["loc", [null, [48, 14], [48, 125]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 45,
                        "column": 8
                      },
                      "end": {
                        "line": 50,
                        "column": 8
                      }
                    },
                    "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "section.isFooter", ["loc", [null, [45, 18], [45, 34]]]]], [], 0, null, ["loc", [null, [45, 8], [50, 8]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 8
                    },
                    "end": {
                      "line": 50,
                      "column": 8
                    }
                  },
                  "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "section.isBody", ["loc", [null, [23, 18], [23, 32]]]]], [], 0, 1, ["loc", [null, [23, 8], [50, 8]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 16,
                    "column": 6
                  },
                  "end": {
                    "line": 52,
                    "column": 6
                  }
                },
                "moduleName": "oceasoft-web/templates/pairings/new.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "if", [["get", "section.isHeader", ["loc", [null, [18, 14], [18, 30]]]]], [], 0, 1, ["loc", [null, [18, 8], [50, 15]]]]],
              locals: ["section"],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 8
                    },
                    "end": {
                      "line": 64,
                      "column": 8
                    }
                  },
                  "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "icon multiple-icon");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "title");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "font-large font-normal font-title");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "font-large");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["pairings.page.batch_add"], [], ["loc", [null, [60, 63], [60, 94]]]], ["inline", "t", ["pairings.page.batch_add_details_1"], [], ["loc", [null, [61, 40], [61, 81]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 69,
                            "column": 14
                          },
                          "end": {
                            "line": 72,
                            "column": 14
                          }
                        },
                        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        dom.setAttribute(el1, "class", "msg");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "t", ["pairings.page.upload_file"], [], ["loc", [null, [70, 35], [70, 68]]]], ["inline", "oc-message", [], ["class", "dropzone", "title", ["subexpr", "t", ["pairings.page.dropzone_title"], [], ["loc", [null, [71, 54], [71, 88]]]], "description", ["subexpr", "t", ["pairings.page.dropzone_message"], [], ["loc", [null, [71, 101], [71, 137]]]], "imageClass", "file-image", "center", false], ["loc", [null, [71, 18], [71, 176]]]]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 65,
                          "column": 10
                        },
                        "end": {
                          "line": 73,
                          "column": 10
                        }
                      },
                      "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                      morphs[1] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "t", ["pairings.page.batch_add_details_2"], [], ["loc", [null, [66, 19], [66, 60]]]], ["block", "file-picker", [], ["class", "picker", "fileLoaded", "parseFile", "accept", "*", "multiple", false, "preview", false, "readAs", "readAsText", "dropzone", true], 0, null, ["loc", [null, [69, 14], [72, 30]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "fragmentReason": false,
                          "revision": "Ember@2.5.1",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 104,
                              "column": 26
                            },
                            "end": {
                              "line": 106,
                              "column": 26
                            }
                          },
                          "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("span");
                          dom.setAttribute(el1, "class", "red");
                          var el2 = dom.createTextNode(" - ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createComment("");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                          return morphs;
                        },
                        statements: [["inline", "t", [["get", "module.pairingErrorKey", ["loc", [null, [105, 55], [105, 77]]]]], [], ["loc", [null, [105, 51], [105, 79]]]]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 98,
                            "column": 16
                          },
                          "end": {
                            "line": 113,
                            "column": 16
                          }
                        },
                        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                      },
                      isEmpty: false,
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        dom.setAttribute(el1, "class", "serial-list-item");
                        var el2 = dom.createTextNode("\n                        ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("span");
                        var el3 = dom.createTextNode("\n                             ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                          ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n\n");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                            ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("div");
                        dom.setAttribute(el3, "class", "close-btn");
                        var el4 = dom.createTextNode("\n                              ");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createTextNode("\n                            ");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element1 = dom.childAt(fragment, [1, 1]);
                        var element2 = dom.childAt(element1, [7]);
                        var morphs = new Array(5);
                        morphs[0] = dom.createMorphAt(element1, 1, 1);
                        morphs[1] = dom.createMorphAt(element1, 3, 3);
                        morphs[2] = dom.createMorphAt(element1, 5, 5);
                        morphs[3] = dom.createElementMorph(element2);
                        morphs[4] = dom.createMorphAt(element2, 1, 1);
                        return morphs;
                      },
                      statements: [["inline", "fa-icon", ["check-circle-o"], ["class", "icon"], ["loc", [null, [101, 29], [101, 70]]]], ["content", "module.serial", ["loc", [null, [102, 26], [102, 43]]]], ["block", "if", [["get", "module.pairingErrorKey", ["loc", [null, [104, 32], [104, 54]]]]], [], 0, null, ["loc", [null, [104, 26], [106, 33]]]], ["element", "action", ["removeModule", ["get", "module", ["loc", [null, [108, 75], [108, 81]]]]], [], ["loc", [null, [108, 51], [108, 83]]]], ["inline", "fa-icon", ["close"], [], ["loc", [null, [109, 30], [109, 50]]]]],
                      locals: ["module"],
                      templates: [child0]
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 73,
                          "column": 10
                        },
                        "end": {
                          "line": 116,
                          "column": 10
                        }
                      },
                      "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n\n              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "uploaded-file");
                      var el2 = dom.createTextNode("\n                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("ul");
                      dom.setAttribute(el1, "class", "serial-list inline");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("li");
                      dom.setAttribute(el2, "class", "serial-list-item");
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      var el4 = dom.createTextNode("\n                          ");
                      dom.appendChild(el3, el4);
                      var el4 = dom.createComment("");
                      dom.appendChild(el3, el4);
                      var el4 = dom.createTextNode("\n                            ");
                      dom.appendChild(el3, el4);
                      var el4 = dom.createElement("div");
                      dom.setAttribute(el4, "class", "close-btn");
                      var el5 = dom.createTextNode("\n                              ");
                      dom.appendChild(el4, el5);
                      var el5 = dom.createComment("");
                      dom.appendChild(el4, el5);
                      var el5 = dom.createTextNode("\n                            ");
                      dom.appendChild(el4, el5);
                      dom.appendChild(el3, el4);
                      var el4 = dom.createTextNode("\n                        ");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n\n\n              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("ul");
                      dom.setAttribute(el1, "class", "serial-list");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element3 = dom.childAt(fragment, [3, 1, 1]);
                      var element4 = dom.childAt(element3, [3]);
                      var morphs = new Array(6);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      morphs[1] = dom.createMorphAt(element3, 1, 1);
                      morphs[2] = dom.createElementMorph(element4);
                      morphs[3] = dom.createMorphAt(element4, 1, 1);
                      morphs[4] = dom.createMorphAt(dom.childAt(fragment, [8]), 0, 0);
                      morphs[5] = dom.createMorphAt(dom.childAt(fragment, [10]), 1, 1);
                      return morphs;
                    },
                    statements: [["inline", "t", ["pairings.page.file_added"], [], ["loc", [null, [77, 16], [77, 48]]]], ["content", "selectedFileName", ["loc", [null, [83, 26], [83, 46]]]], ["element", "action", ["clearFile"], [], ["loc", [null, [84, 51], [84, 73]]]], ["inline", "fa-icon", ["close"], [], ["loc", [null, [85, 30], [85, 50]]]], ["inline", "t", ["pairings.page.file_serials"], [], ["loc", [null, [95, 20], [95, 54]]]], ["block", "each", [["get", "modules", ["loc", [null, [98, 24], [98, 31]]]]], [], 0, null, ["loc", [null, [98, 16], [113, 25]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 64,
                        "column": 8
                      },
                      "end": {
                        "line": 118,
                        "column": 8
                      }
                    },
                    "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "not", [["get", "hasModules", ["loc", [null, [65, 21], [65, 31]]]]], [], ["loc", [null, [65, 16], [65, 32]]]]], [], 0, 1, ["loc", [null, [65, 10], [116, 17]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 120,
                            "column": 14
                          },
                          "end": {
                            "line": 122,
                            "column": 14
                          }
                        },
                        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "oc-button", [], ["icon", "check", "label", ["subexpr", "t", ["pairings.page.add_multiple_modules"], [], ["loc", [null, [121, 47], [121, 87]]]], "action", "createMultiple", "disabled", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [121, 121], [121, 130]]]]], [], []], "primary", true], ["loc", [null, [121, 16], [121, 145]]]]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 124,
                            "column": 14
                          },
                          "end": {
                            "line": 126,
                            "column": 14
                          }
                        },
                        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "oc-button", [], ["icon", "upload", "label", ["subexpr", "t", ["pairings.page.select_another_file"], [], ["loc", [null, [125, 48], [125, 87]]]], "action", "reselectFile", "disabled", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [125, 119], [125, 128]]]]], [], []]], ["loc", [null, [125, 16], [125, 130]]]]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child2 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 126,
                            "column": 14
                          },
                          "end": {
                            "line": 128,
                            "column": 14
                          }
                        },
                        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "oc-button", [], ["icon", "upload", "label", ["subexpr", "t", ["pairings.page.select_file"], [], ["loc", [null, [127, 48], [127, 79]]]], "action", "selectFile", "disabled", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [127, 109], [127, 118]]]]], [], []]], ["loc", [null, [127, 16], [127, 120]]]]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 118,
                          "column": 8
                        },
                        "end": {
                          "line": 130,
                          "column": 8
                        }
                      },
                      "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "actions");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("            ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n        ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(element0, 1, 1);
                      morphs[1] = dom.createMorphAt(element0, 3, 3);
                      return morphs;
                    },
                    statements: [["block", "if", [["get", "hasModules", ["loc", [null, [120, 20], [120, 30]]]]], [], 0, null, ["loc", [null, [120, 14], [122, 21]]]], ["block", "if", [["get", "hasModules", ["loc", [null, [124, 20], [124, 30]]]]], [], 1, 2, ["loc", [null, [124, 14], [128, 21]]]]],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 118,
                        "column": 8
                      },
                      "end": {
                        "line": 130,
                        "column": 8
                      }
                    },
                    "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "section.isFooter", ["loc", [null, [118, 18], [118, 34]]]]], [], 0, null, ["loc", [null, [118, 8], [130, 8]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 8
                    },
                    "end": {
                      "line": 130,
                      "column": 8
                    }
                  },
                  "moduleName": "oceasoft-web/templates/pairings/new.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "section.isBody", ["loc", [null, [64, 18], [64, 32]]]]], [], 0, 1, ["loc", [null, [64, 8], [130, 8]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 6
                  },
                  "end": {
                    "line": 132,
                    "column": 6
                  }
                },
                "moduleName": "oceasoft-web/templates/pairings/new.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "if", [["get", "section.isHeader", ["loc", [null, [56, 14], [56, 30]]]]], [], 0, 1, ["loc", [null, [56, 8], [130, 15]]]]],
              locals: ["section"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 134,
                  "column": 4
                }
              },
              "moduleName": "oceasoft-web/templates/pairings/new.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["block", "oc-box", [], [], 0, null, ["loc", [null, [16, 6], [52, 17]]]], ["block", "oc-box", [], [], 1, null, ["loc", [null, [54, 6], [132, 17]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 136,
                "column": 2
              }
            },
            "moduleName": "oceasoft-web/templates/pairings/new.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "isError", ["loc", [null, [4, 10], [4, 17]]]]], [], 0, 1, ["loc", [null, [4, 4], [134, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 137,
              "column": 0
            }
          },
          "moduleName": "oceasoft-web/templates/pairings/new.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "oc-content", [], ["class", "pairings-modules-new"], 0, null, ["loc", [null, [2, 2], [136, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 138,
                "column": 2
              },
              "end": {
                "line": 140,
                "column": 2
              }
            },
            "moduleName": "oceasoft-web/templates/pairings/new.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "oc-message", [], ["imageClass", "empty-img", "title", ["subexpr", "t", ["pairings.page.error_voucher_limit"], [], ["loc", [null, [139, 46], [139, 85]]]], "description", ["subexpr", "t", [["subexpr", "line-if", [["get", "company.hasVoucher", ["loc", [null, [139, 110], [139, 128]]]], "pairings.page.error_voucher_limit_details", "pairings.page.error_no_voucher_details"], [], ["loc", [null, [139, 101], [139, 214]]]]], [], ["loc", [null, [139, 98], [139, 215]]]]], ["loc", [null, [139, 4], [139, 217]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 0
            },
            "end": {
              "line": 141,
              "column": 0
            }
          },
          "moduleName": "oceasoft-web/templates/pairings/new.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "oc-content", [], ["class", "pairings-modules-new-no-voucher"], 0, null, ["loc", [null, [138, 2], [140, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 141,
            "column": 7
          }
        },
        "moduleName": "oceasoft-web/templates/pairings/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "gt", [["get", "company.sensorAvailable", ["loc", [null, [1, 10], [1, 33]]]], 0], [], ["loc", [null, [1, 6], [1, 36]]]]], [], 0, 1, ["loc", [null, [1, 0], [141, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});