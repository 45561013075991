define("oceasoft-web/objects/error", ["exports", "ember"], function (exports, _ember) {

  var Error = _ember["default"].Object.extend(_ember["default"].Copyable, {

    code: null,
    type: null,
    image: null,
    icon: null,
    title: null,
    description: null,
    attemptedTransition: null,

    copy: function copy() {
      return Error.create(this);
    }

  });

  exports["default"] = Error;
});