define("oceasoft-web/objects/company", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;
  var isNull = _oceasoftWebObjectsUtils["default"].Property.isNull;

  var Company = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

    init: function init() {
      setDefaultProperties(this, {
        id: null,
        code: null,
        name: null,
        email: null,
        creationDate: null,
        expirationDate: null,
        address1: null,
        address2: null,
        postalCode: null,
        city: null,
        stateProvince: null,
        country: null,
        tel: null,
        sensorCount: 0,
        sensorAvailable: 0,
        latestVoucherSerial: null
      });

      this._super.apply(this, arguments);

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        this.set('postalCode', apiResponse.post_code);
        this.set('sensorCount', apiResponse.count);
        this.set('sensorAvailable', apiResponse.available);

        this.set('latestVoucherSerial', !isNull(apiResponse.Voucher) ? apiResponse.Voucher : null);

        this.set('creationDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.creation_Date));
        this.set('expirationDate', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.expiration_Date));

        delete this.apiResponse;
      }
    },

    hasVoucher: computed('latestVoucherSerial', function () {
      return !isNull(this.get('latestVoucherSerial'));
    }),

    copy: function copy() {
      return Company.create(this);
    }

  });

  exports["default"] = Company;
});