define("oceasoft-web/controllers/about/product-info", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/config/environment"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebConfigEnvironment) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend({

    version: computed(function () {

      var version = _oceasoftWebConfigEnvironment["default"].APP.version;

      if (version.indexOf("+") > -1) {
        return version.split("+")[0];
      } else {
        return version;
      }
    }),

    actions: {

      moreInfo: function moreInfo() {
        window.open(_oceasoftWebObjectsConstants["default"].URL_ACCESSORIES_GUIDE, '_blank').focus();
      }
    }
  });
});