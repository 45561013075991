define("oceasoft-web/objects/reading", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;

  var Reading = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

    init: function init() {
      setDefaultProperties(this, {
        isReading: true,
        measure: null,
        date: null,
        lat: null,
        long: null
      });

      this._super.apply(this, arguments);

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        this.set('measure', Number(apiResponse.V.replace(',', '.')));

        if (!isEmpty(apiResponse.D)) {
          this.set('date', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.D));
        }

        if (!isEmpty(apiResponse.LD)) {
          this.set('date', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.LD));
        }

        if (!isEmpty(apiResponse.PX)) {
          this.set('lat', apiResponse.PX.replace(',', '.'));
        }

        if (!isEmpty(apiResponse.PY)) {
          this.set('long', apiResponse.PY.replace(',', '.'));
        }

        delete this.apiResponse;
      }
    },

    isValid: computed('measure', function () {
      return Math.abs(Math.round(this.get('measure'))) !== 999;
    }),

    hasGeocoordinates: computed('lat', 'long', function () {
      var _getProperties = this.getProperties('lat', 'long');

      var lat = _getProperties.lat;
      var long = _getProperties.long;

      if (lat && long) {
        return true;
      }

      return false;
    }),

    copy: function copy() {
      return Reading.create(this);
    }

  });

  exports["default"] = Reading;
});