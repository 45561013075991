define("oceasoft-web/controllers/application", ["exports", "ember", "oceasoft-web/mixins/session-manager", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebMixinsSessionManager, _oceasoftWebObjectsConstants) {

  var TAG = 'application controller';
  var service = _ember["default"].inject.service;
  var Response = _oceasoftWebObjectsConstants["default"].API.Response;
  exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsSessionManager["default"], {

    logger: service('oc-logger'),
    session: service('session'),

    components: {
      topbar: false,
      navigation: false
    },

    showLogoutDialog: false,
    isLogoutConfirmed: false,

    showUserNotAdminDialog: false,
    showUserNotEnabledDialog: false,

    actions: {

      handleUserAPIErrors: function handleUserAPIErrors(apiError) {
        this.get('logger').info(TAG, 'handleUserAPIErrors');

        switch (apiError) {
          case Response.NOT_IN_DB_USER:
            this.set('showUserNotEnabledDialog', true);
            break;

          case Response.USER_IS_NOT_ADMIN:
            this.set('showUserNotAdminDialog', true);
            break;
        }
      },

      logout: function logout() {
        this.get('logger').info(TAG, 'logout');

        if (!this.get('isLogoutConfirmed')) {
          this.set('showLogoutDialog', true);
          return;
        }

        this.get('session').invalidate();
      },

      confirmLogout: function confirmLogout() {
        this.get('logger').info(TAG, 'confirmLogout');

        this.set('isLogoutConfirmed', true);
        this.send('logout');
      },

      rejectLogout: function rejectLogout() {
        this.get('logger').info(TAG, 'rejectLogout');

        this.set('showLogoutDialog', false);
        this.set('isLogoutConfirmed', false);
      }

    }

  });
});