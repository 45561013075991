define('oceasoft-web/locales/en/translations', ['exports'], function (exports) {
    exports['default'] = {
        'appname': 'EDGEVue',
        'navigation': {
            'menu': {
                'my_profile': 'My Profile',
                'view': 'View',
                'profiles': 'Profiles',
                'pairing': 'Pairing',
                'voucher': 'Voucher',
                'about': 'About',
                'logout': 'Logout'
            }
        },
        'select_language_placeholder': 'Select language...',
        'try_again': 'Try Again',
        'got_it': 'Got It',
        'ok': 'OK',
        'add_here': 'Add Here',
        'more_info': 'More Info',
        'download': 'Download',
        'save_changes': 'Save Changes',
        'discard_changes': 'Discard Changes',
        'search_placeholder': 'Search',
        'yes': 'Yes',
        'no': 'No',
        'cancel': 'Cancel',
        'confirmation': 'Confirmation',
        'none': 'None',
        'celsius': '°C',
        'fahrenheit': '°F',
        'point': '1.0 <br/> Period',
        'comma': '1,0 <br/> Comma',
        'loading': 'Loading',
        'loading_description': 'Please wait while we are loading the data.',
        'not_available': 'Not Yet Available',
        'not_available_description': 'We are sorry but the current section is not yet available for this release.',
        'error_dialog_title': 'Error',
        'dashboard': 'Dashboard',
        'select_country_placeholder': '-Select country-',

        'calendar_title': 'Calendar',
        'calendar_description': 'Select dates and hours in order to filter the modules and missions.',

        'timeZone': 'Time Zone',
        'gmt': 'GMT',

        'timePeriod': {
            '0': 'All',
            '1': 'Last 24 hours',
            '2': 'Last week',
            '3': 'Last month',
            '4': 'Calendar'
        },

        'timeFormat': {
            'title': "Time format",
            'type': {
                '1': '12-hour clock (a.m. and p.m.)',
                '0': '24-hour clock'
            }
        },

        'dateFormat': {
            'title': "Date format",
            'type': {
                'MM/DD/YYYY': 'MM/DD/YYYY',
                'DD/MM/YYYY': 'DD/MM/YYYY'
            }
        },

        'timeUnit': {
            'seconds': '{{val}} seconds',
            'minutes': '{{val}} min(s)'
        },

        'temperatureUnit': {
            'title': "Temperature unit",
            'type': {
                '0': '°C Celsius',
                '1': '°F Fahrenheit'
            }
        },

        'decimalSeparator': {
            'title': "Decimal separator",
            'type': {
                '0': '1.0 Point',
                '1': '1,0 Comma'
            }
        },
        'countryInput': {
            'title': "Display language",
            'language': {
                'EN': "English",
                'FR': "French",
                'ES': "Spanish"
            }
        },
        'logout_confirm': 'Are you sure you want to logout?',
        'notifications': {
            'title': 'Notifications',
            'alarms': 'Alarms',
            'mission_read': 'Mission Read',
            'mission_started': 'Mission Started',
            'mission_stopped': 'Mission Stopped'
        },
        'cvs_template': {
            'type': 'Type',
            'date': 'Date',
            'time': 'Time',
            'datetime': 'Date-Time',
            'value': 'Value',
            'info1': 'Info 1',
            'info2': 'Info 2'
        },
        'user': {
            'level': {
                '1': 'Administrator',
                '0': 'User'
            }
        },

        'mission': {
            'event_type': {
                '-1': 'Unknown Event',
                '0': 'Mission Programmed',
                '1': 'Datalogging Started',
                '2': 'Module Connected',
                '3': 'Readings Downloaded',
                '4': 'Mission Stopped',
                '5': 'Alarm Reset',

                '-10': 'Unknown Alarm',
                '10': 'High Limit',
                '11': 'Low Limit',
                '12': 'Sensor Failed',
                '13': 'Low Battery',
                '14': 'Out of range',

                '20': 'Programmed on bench',
                '21': 'Geolocated mission'
            }
        },

        'module': {
            'type': {
                '0': 'Unknown',
                '1': 'Module',
                '2': 'EDGE S400'
            },

            'mission_search_filter_placeholder': '-Mission state-',

            'mission_search_filter': {
                '0': 'No mission',
                '1': 'Missions with Alarm(s)',
                '2': 'Mission without Alarm(s)',
                '3': 'Stopped/Ended Missions',
                '4': 'All missions'
            },

            'mission_alarm_search_filter': {
                '0': 'Alarm State',
                '1': 'On Alarm',
                '2': 'No Alarms In Progress'
            },

            'mission_filter': {
                '0': 'All Missions',
                '1': 'No Alarms',
                '2': 'One or more alarms'
            }
        },

        'search': {
            'no_matches_title': 'No Matches',
            'no_matches_description': 'Your search query has returned no results. <br/><br/>Please try with another query.'
        },

        'reset_fields': {
            'one': 'Reset Field',
            'other': 'Reset Fields'
        },

        'host': {
            'label': 'Host',

            '0': 'Americas',
            '1': 'EMEA (Europe, Middle East, Africa)',
            '2': 'APAC (Asia-Pacific)'
        },

        'voucher': {
            'page': {
                'title': 'Voucher',
                'add_message_description': 'Have a voucher and want to use it?',
                'my_voucher': 'My Voucher',
                'new_voucher': 'New Voucher',
                'new_voucher_key': 'New Voucher Key',
                'latest_voucher_key': 'Latest Voucher Key',
                'available_modules': 'Available Modules',
                'total_nr_modules': 'Total Number of Modules',
                'exp_date': 'Expiration Date',
                'new_save_msg': 'Voucher has been successfully added.',
                'add_voucher': 'Add Voucher',
                'no_voucher_msg': 'No Vouchers',
                'no_voucher_msg_details': 'It seems you have not added any vouchers.',

                'incorrect_voucher_key': 'This voucher key is not correct',
                'key_already_used': 'Voucher key has already been used',
                'voucher_expired': 'The expiration date has already passed.',
                'voucher_remove_modules': 'Unable to add this voucher. Please remove  {{x}} module(s).'
            },

            'label': 'Voucher',
            'type': {
                '0': 'EDGE M300 & EDGE S400',
                '1': 'EDGE S400 Only'
            }
        },

        'about': {
            'page': {
                'title': 'About',
                'product_info': 'Product Information',
                'accessories': 'Accessories',
                'user_guide': 'User Guide',
                'software_version': 'EDGEVue {{version}}',

                'product_info_page': 'Use EDGEVue to manage Cloud features for your TEMPTIME dataloggers.<br/> Set up your users and company information, add devices, and view data.',

                'accessories_page': 'Personalize your EDGE M300 modules with different colored rings for easy identification. Check the TEMPTIME web site for more information.',

                'guide_page': 'Read the latest user guide online to discover and learn how to use all the features of the EDGEVue solution.'

            }
        },

        'pairings': {
            'page': {
                'title': 'Pairing',
                'module_list': 'Module List',
                'new_module': 'New Module',
                'module_sn': 'Module {{serial}}',
                'add_module_msg': 'Want to add a new module?',
                'no_modules_msg_title': 'No Modules',
                'no_modules_msg_description': 'For the moment there are no modules assigned to your company.',
                'what_to_add_new_module': 'Want to add a new module?',
                'no_modules_selected_msg_title': 'No Module Selected',
                'no_modules_selected_msg_description': 'To edit or modify a module you first need to select one from the list.',

                'serial_nr_mandatory': 'Serial Number <span class="red">*</span>',
                'serial_nr': 'Serial Number',
                'name': 'Name',
                'remove_module': 'Remove Module',

                'remove_confirmation': 'You are about to remove this module.<br/>This action is permanent and cannot be undone.<br/><br/>Are you sure you want to continue?',

                'add_one_module': 'Add One Module',
                'add_multiple_modules': 'Add Multiple Modules',
                'select_file': 'Select File',
                'select_another_file': 'Select Another File',
                'how_to_find_serial': 'How to find the serial?',
                'find_serial_description': 'EDGE M300 modules: Enter the serial number provided on your module’s sticker, then click on ADD ONE MODULE. <br> <br> EDGE S400 modules: You do not need to enter a serial number here. EDGE S400 modules are automatically associated with your account when you program their first Cloud-enabled mission with EDGEVue.',
                'batch_add': 'Batch Add',
                'batch_add_details_1': 'You can upload a text file with the serial numbers of all your modules.',
                'batch_add_details_2': 'The text must be formatted with a single serial number on each line.',

                'error_unkown_serial': 'Serial number is not correct.',

                'module_added_msg': {
                    'one': 'Module successfully added.',
                    'other': '{{count}} modules successfully added.'
                },

                'error_module_atlas': 'EDGE S400 must be added with the EDGEVue mobile application',
                'error_invalid_module': 'Unable to add this module',

                'error_voucher_limit_reached': 'Voucher limit has been reached.',

                'error_invalid_hash_code': 'Invalid serial number',
                'error_voucher_invalid_serial': 'Incorrect serial number.',

                'error_atlas_not_cloud_enabled': 'This EDGE S400 device is not Cloud-enabled.',
                'error_atlas_cloud_enabled': 'This EDGE S400 device is Cloud-enabled, but you must use EDGEVue mobile application for pairing.',

                'error_already_used': 'Serial number already used',
                'error_module_already_used': 'Serial number already exists.<br/>The serial number you are trying to add is already present on our platform.<br/>Please try to enter another serial number.',

                'error_file_invalid': 'Invalid File Format',
                'error_file_invalid_description': 'The provided file has invalid content. In order to add multiple modules at once please provide a text file with one serial number on each line.',

                'error_no_serials_extracted': 'No Serials Found',
                'error_no_serials_extracted_description': 'No valid serial numbers have been found in the uploaded file.',

                'file_serials': 'Valid serials to add:',
                'file_added': 'Uploaded file:',

                'dropzone_title': 'DROP FILES HERE',
                'dropzone_message': 'The file will automatically be uploaded once you drop it here',

                'upload_file': 'Start by selecting a file in order to add multiple modules.',

                'error_uploading_success_all': 'Multiple Module Created',
                'error_uploading_success_all_description': 'All of the modules have been successfully added.',

                'error_uploading_success_some': {
                    'one': 'Module Added',
                    'other': 'Modules Added'
                },
                'error_uploading_success_some_description': '{{nrAdded}} out of {{nrTotal}} module(s) was/were added successfully.<br/><br/>Please verify the module list to see those that failed',

                'error_uploading_multiple': 'Multiple Module Creation Failed',
                'error_uploading_multiple_description': 'All of the modules failed when trying to add them to the server.',

                'loading_remove_module': 'Removing the module.',

                'error_remove': 'Removal Failed',
                'error_remove_description': 'This module was not found on the server in order to be removed.',
                'module_removed_msg': 'Module Removed',

                'error_voucher_limit': 'Cannot Pair New Modules',
                'error_voucher_limit_details': 'You cannot pair new modules because your voucher has expired or the limit has been reached.<br/>Please consult "My Voucher" page.',
                'error_no_voucher_details': 'You cannot pair new modules because you do not have any vouchers.<br/>Please add a voucher in order to use this feature.'
            }
        },

        'profiles': {
            'page': {
                'title': 'Profiles',
                'my_profile': 'My Profile',
                'user_list': 'User List',
                'company_info': 'Company Information',
                'add_user_message': 'Want to add a new user account?',
                'new_user': 'New User',

                'no_users': 'No Users',
                'no_users_description': 'For the moment there are no other users assigned to your company.',

                'no_user_selected': 'No User Selected',
                'no_user_selected_description': 'To edit or modify a user you first need to select one from the list.',

                'remove_user': 'Remove User',
                'add_user': 'Add User',

                'first_name_placeholder': 'First <div class="block">Name <span class="red">*</span></div>',
                'last_name_placeholder': 'Last <div class="block">Name <span class="red">*</span></div>',
                'email_placeholder': 'Email <div class="block">Address <span class="red">*</span></div>',
                'level_placeholder': 'Level',
                'password_placeholder': 'Password <span class="red">*</span>',
                'password_confirm_placeholder': 'Confirm <div class="block">Password <span class="red">*</span></div>',

                'add_new_user': 'Add New User',

                'remove_user_dialog_text': 'You are about to remove this user.<br/>This action is permanent and cannot be undone.<br/><br/>Are you sure you want to continue?',

                'user_added_msg': '{{fullName}} has been added.',
                'user_modified_msg': '{{fullName}} has been modified.',
                'user_removed': '{{fullName}} has been removed.',
                'profile_modified': 'Your profile has been modified.',

                'error': {
                    'edit': {
                        'wrong_customer_id': 'Invalid Company',
                        'wrong_customer_id_description': 'The user you are trying to edit does not belong to you company.',

                        'not_in_db_user': 'Invalid User',
                        'not_in_db_user_description': 'The user you are trying to edit was not found on the server.'
                    },

                    'add': {
                        'already_exists': 'User Already Present.',
                        'already_exists_description': 'User already exists. <br/> The user you are trying to add is already present on our platform. <br/> Please try to use another email address or contact your administrator.'
                    }
                },

                'company_info_page': {
                    'address_1': 'Address 1',
                    'address_2': 'Address 2',
                    'city': 'City',
                    'postal_code': 'Postal Code',
                    'country': 'Country',
                    'tel': 'Phone Number',
                    'company_saved_msg': 'Company information successfully saved.'
                }
            }
        },

        'view': {
            'page': {
                'title': 'View',
                'no_modules_msg_title': 'No Modules',
                'no_modules_msg_description': 'For the moment there are no modules assigned to your company and you cannot use the view functionality.',

                'select_start_date': 'Start date',
                'select_end_date': 'End date',

                'no_module_selected_title': 'No Module Selected',
                'no_module_selected_description': 'To view more information about a mission you need to select one from the list. <br> If you select multiple missions you will switch to multi-view mode where you can compare missions in saved data mode.',

                'date_range_title': ' Show mission started between',
                'date_range_separator': 'and',

                'selected_missions': {
                    'one': "One mission selected",
                    'other': "{{count}} missions selected"
                },

                'time_placeholder': '-Mission calendar-',

                'saved_data': 'Saved Data',
                'live_data': 'Live Data',

                'pdf': 'PDF',
                'cvs': 'CSV',

                'generating_ios_error_title': 'Error',
                'generating_ios_error': 'This device does not support this operation',

                'generating_safari_error_title': 'Error',
                'generating_safari_error': 'This browser does not support this operation',

                'max_missions_selected_error': 'Limit Reached',
                'max_missions_selected_error_description': 'You already selected the maximum number of missions.<br/>Please uncheck a mission to select another one.',

                'generating_pdf_loading': 'Generating Mission Report',
                'pdf_generated_title': 'Mission Report Ready',
                'pdf_generated_description': 'The mission report has been successfully generated and downloaded to your device.',

                'pdf_failed_title': 'Mission Report Failed',
                'pdf_failed_description': 'The mission report generation has failed. Please try again later. Please contact us if you encounter the same issue.',

                'no_missions': 'No Missions',
                'no_missions_details': 'There are no missions available for this module.',
                'no_missions_for_this_period': 'There are no missions available for this period.',

                'no_missions_selected': 'No mission selected',
                'no_missions_selected_description': 'To view information about a mission select any point on the graph below.',
                'no_missions_selected_description_touch': 'To view information about a mission double click on any point on the graph below.',
                'no_missions_selected_description_android': 'To view information about a mission double click on any point on the graph below.',
                'no_missions_selected_description_ios': 'To view information about a mission select any point on the graph below.',

                'no_readings_events': 'No Mission Data',
                'no_readings_events_description': 'This mission does not have any readings, events or alarms yet.',

                'no_readings': 'No Readings',
                'no_readings_description': 'This mission does not have any readings yet.<br/><br/>You can try enabling events/alarms to see if there is any data available.',

                'no_events': 'No Alarms or Alarms',
                'no_events_description': 'This mission does not have any events or alarms yet.<br/><br/>You can try enabling readings to see if there is any data available.',

                'no_reading_event_type_selected': 'No Filter Selected',
                'no_reading_event_type_selected_description': 'Please select one or both filters above in order to see readings, events or alarms.',

                'on_alarm': 'On Alarm',
                'last_mission_started': 'Last Mission Started',

                'start_date': 'Start Date',
                'end_date': 'End Date',

                'unselected_date': 'Select Date',

                'readings_check': 'Readings',
                'events_alarms_check': 'Events/Alarms',

                'mission_started_at_description': 'Started at {{date}}',
                'too_many_result': 'Your request returned too many items, please select a shorter period',
                'graph_title': 'Graph',

                'value_or_none': {
                    'zero': 'None',
                    'other': '{{count}}'
                },

                'informations': 'Information',
                'informations_data': {
                    'sn': 'Serial Number',
                    'reading_interval': 'Reading Interval',
                    'reading_count': 'Cloud Reading Count',
                    'maximum_temp': 'Maximum Temperature',
                    'minimum_temp': 'Minimum Temperature',
                    'high_limit': 'High Limit',
                    'low_limit': 'Low Limit',
                    'high_delay': 'High Delay',
                    'low_delay': 'Low Delay',
                    'datalogging_start': 'Datalogging Start',
                    'alarms': 'Alarm(s)',
                    'avg_temp': 'Average Temperature',
                    'reading_err': 'Reading Error'
                },

                'map_not_available': 'Map not yet available',
                'map_not_available_description': 'At least 1 event with GPS position is needed to generate a map.',
                'map_not_available_description_readings': 'At least 1 reading with GPS position is needed to generate a map.',
                'map_not_available_no_mission_description': 'Select a mission in order to generate a map.',

                'graph_not_available': 'Graph not available',
                'graph_not_available_details': {
                    'zero': 'No readings.',
                    'one': 'At least two readings are needed to generate a graph.'
                }
            }
        },

        'required_field': '<span class="red">*</span> Required Field',

        'error': {
            'unable_to_complete_operation': 'Unable to Complete Operation',
            'empty_field': 'Field is empty',
            'max_length': 'Max length exceeded',
            'empty_select': 'Unselected value',
            'general': 'General Error',
            'general_description': 'There seems to be a problem with our platform.<br/>Please try again later.',

            'non_matching_passwords': 'Passwords do not match',
            'full_name': 'Provide first and last name',

            '404_title': 'Page Not Found',
            '404_description': 'It seems that the page you are looking for cannot be found.<br/>Please return to the previews page in order to continue.',

            'unauthorized': 'Unauthorized',
            'unauthorized_description': 'You don\'t have the necessary privileges in order to access that page.<br/>If you consider that this is an error please contact us.',

            'user_not_admin': 'Only administrators are allowed to perform this operation.',
            'user_not_enabled': 'The email and password you provided are invalid.',
            'user_disabled': 'User is not enabled. Please contact your administrator.',

            'user_not_admin_detailed': 'Only administrators are allowed to perform this operation.'
        },

        'login': {
            'title': 'Login',
            'description': 'Please use your account’s credentials to access the application.',
            'send_password': 'Send a new password',
            'connect': 'Connect',
            'register_msg': 'Don\'t have an account?',
            'register_link_btn': 'Create here',
            'email_placeholder': 'Email Address',
            'password_placeholder': 'Password',

            'invalid_email': 'Invalid Email',
            'invalid_password': 'Illegal characters',
            'password_length': 'Minimum 6 characters',

            'loading_description': 'Please wait while we are logging you in.',

            'error': 'Authentication Failed',
            'error_description': 'The email and password you provided are invalid.',

            'reset_password': {
                'title': 'Reset Password',
                'description': 'You are about to regenerate the password for <b>{{email}}</b>.<br/>Are you sure you want to continue?',

                'success': 'Password Successfully Sent',
                'success_description': 'We have successfully generated a new password and it has been sent by mail to you.',

                'invalid_email': 'Invalid Email',
                'invalid_email_description': 'The provided email address is not registered on our platform.'
            }
        },

        'register': {
            'title': 'Create Company Account',
            'description': 'Please enter information about yourself in order to register.',
            'personal_info': 'Personal Information',
            'email_placeholder': 'Email Address',
            'password_placeholder': 'Password',
            'password_confirm_placeholder': 'Confirm Password',
            'first_name_placeholder': 'First Name',
            'last_name_placeholder': 'Last Name',
            'company_name_placeholder': 'Company Name',
            'addr1_placeholder': 'Address 1',
            'addr2_placeholder': 'Address 2',
            'city_placeholder': 'City',
            'postal_code_placeholder': 'Postal Code',
            'phone_number_placeholder': 'Phone Number',
            'country': 'Country',
            'have_voucher': 'I have a Voucher!',
            'voucher': 'Voucher',
            'voucher_key': 'Voucher Key',
            'already_have_account': 'I already have an account',
            'create': 'Create',

            'loading_description': 'Please wait while your account is being created on our platform.<br/>After this we will automatically log you in.',

            'error_already_exists': 'User Already Exists',
            'error_already_exists_description': 'User Already Exists.<br/>The provided information for the new account has already been used.<br/>Please review your information and use another email address in order to create an account.',

            'error_already_exists_field': 'Email Already Used',

            'error_already_exists_company': 'Company Already Exists',
            'error_already_exists_company_description': 'There is already a registered company with the name you provided.<br/>Please review your information and use another company name in order to create an account.',
            'error_already_exists_company_field': 'Company Name Already Used',

            'error_voucher': 'Voucher Error',
            'error_voucher_invalid': 'The voucher you provided is invalid.<br/>Please provide a valid voucher serial in order to continue.',
            'error_voucher_used': 'The voucher you provided has already been used.<br/>Please provide another voucher serial in order to continue.',
            'error_voucher_invalid_field': 'Invalid Voucher',
            'error_voucher_used_field': 'Voucher Already Used',

            'successful': 'You\'re done!',
            'successful_description': 'The new account has been created and we\'ve taken the liberty to log you in.<br/>Just press continue in order to enter the platform.',
            'continue_button': 'Continue',
            'enter_platform_loading': 'Please wait while we get things ready'
        },

        'copyright': 'Copyright © {{year}} TEMPTIME.<br\/> All rights reserved.',

        'country': {
            'AF': 'Afghanistan',
            'AX': 'Åland Islands',
            'AL': 'Albania',
            'DZ': 'Algeria',
            'AS': 'American Samoa',
            'AD': 'Andorra',
            'AO': 'Angola',
            'AI': 'Anguilla',
            'AQ': 'Antarctica',
            'AG': 'Antigua & Barbuda',
            'AR': 'Argentina',
            'AM': 'Armenia',
            'AW': 'Aruba',
            'AC': 'Ascension Island',
            'AU': 'Australia',
            'AT': 'Austria',
            'AZ': 'Azerbaijan',
            'BS': 'Bahamas',
            'BH': 'Bahrain',
            'BD': 'Bangladesh',
            'BB': 'Barbados',
            'BY': 'Belarus',
            'BE': 'Belgium',
            'BZ': 'Belize',
            'BJ': 'Benin',
            'BM': 'Bermuda',
            'BT': 'Bhutan',
            'BO': 'Bolivia',
            'BA': 'Bosnia & Herzegovina',
            'BW': 'Botswana',
            'BR': 'Brazil',
            'IO': 'British Indian Ocean Territory',
            'VG': 'British Virgin Islands',
            'BN': 'Brunei',
            'BG': 'Bulgaria',
            'BF': 'Burkina Faso',
            'BI': 'Burundi',
            'KH': 'Cambodia',
            'CM': 'Cameroon',
            'CA': 'Canada',
            'IC': 'Canary Islands',
            'CV': 'Cape Verde',
            'BQ': 'Caribbean Netherlands',
            'KY': 'Cayman Islands',
            'CF': 'Central African Republic',
            'EA': 'Ceuta & Melilla',
            'TD': 'Chad',
            'CL': 'Chile',
            'CN': 'China',
            'CX': 'Christmas Island',
            'CC': 'Cocos (Keeling) Islands',
            'CO': 'Colombia',
            'KM': 'Comoros',
            'CG': 'Congo - Brazzaville',
            'CD': 'Congo - Kinshasa',
            'CK': 'Cook Islands',
            'CR': 'Costa Rica',
            'CI': 'Côte d’Ivoire',
            'HR': 'Croatia',
            'CU': 'Cuba',
            'CW': 'Curaçao',
            'CY': 'Cyprus',
            'CZ': 'Czech Republic',
            'DK': 'Denmark',
            'DG': 'Diego Garcia',
            'DJ': 'Djibouti',
            'DM': 'Dominica',
            'DO': 'Dominican Republic',
            'EC': 'Ecuador',
            'EG': 'Egypt',
            'SV': 'El Salvador',
            'GQ': 'Equatorial Guinea',
            'ER': 'Eritrea',
            'EE': 'Estonia',
            'ET': 'Ethiopia',
            'FK': 'Falkland Islands',
            'FO': 'Faroe Islands',
            'FJ': 'Fiji',
            'FI': 'Finland',
            'FR': 'France',
            'GF': 'French Guiana',
            'PF': 'French Polynesia',
            'TF': 'French Southern Territories',
            'GA': 'Gabon',
            'GM': 'Gambia',
            'GE': 'Georgia',
            'DE': 'Germany',
            'GH': 'Ghana',
            'GI': 'Gibraltar',
            'GR': 'Greece',
            'GL': 'Greenland',
            'GD': 'Grenada',
            'GP': 'Guadeloupe',
            'GU': 'Guam',
            'GT': 'Guatemala',
            'GG': 'Guernsey',
            'GN': 'Guinea',
            'GW': 'Guinea-Bissau',
            'GY': 'Guyana',
            'HT': 'Haiti',
            'HN': 'Honduras',
            'HK': 'Hong Kong SAR China',
            'HU': 'Hungary',
            'IS': 'Iceland',
            'IN': 'India',
            'ID': 'Indonesia',
            'IR': 'Iran',
            'IQ': 'Iraq',
            'IE': 'Ireland',
            'IM': 'Isle of Man',
            'IL': 'Israel',
            'IT': 'Italy',
            'JM': 'Jamaica',
            'JP': 'Japan',
            'JE': 'Jersey',
            'JO': 'Jordan',
            'KZ': 'Kazakhstan',
            'KE': 'Kenya',
            'KI': 'Kiribati',
            'XK': 'Kosovo',
            'KW': 'Kuwait',
            'KG': 'Kyrgyzstan',
            'LA': 'Laos',
            'LV': 'Latvia',
            'LB': 'Lebanon',
            'LS': 'Lesotho',
            'LR': 'Liberia',
            'LY': 'Libya',
            'LI': 'Liechtenstein',
            'LT': 'Lithuania',
            'LU': 'Luxembourg',
            'MO': 'Macau SAR China',
            'MK': 'Macedonia',
            'MG': 'Madagascar',
            'MW': 'Malawi',
            'MY': 'Malaysia',
            'MV': 'Maldives',
            'ML': 'Mali',
            'MT': 'Malta',
            'MH': 'Marshall Islands',
            'MQ': 'Martinique',
            'MR': 'Mauritania',
            'MU': 'Mauritius',
            'YT': 'Mayotte',
            'MX': 'Mexico',
            'FM': 'Micronesia',
            'MD': 'Moldova',
            'MC': 'Monaco',
            'MN': 'Mongolia',
            'ME': 'Montenegro',
            'MS': 'Montserrat',
            'MA': 'Morocco',
            'MZ': 'Mozambique',
            'MM': 'Myanmar (Burma)',
            'NA': 'Namibia',
            'NR': 'Nauru',
            'NP': 'Nepal',
            'NL': 'Netherlands',
            'NC': 'New Caledonia',
            'NZ': 'New Zealand',
            'NI': 'Nicaragua',
            'NE': 'Niger',
            'NG': 'Nigeria',
            'NU': 'Niue',
            'NF': 'Norfolk Island',
            'KP': 'North Korea',
            'MP': 'Northern Mariana Islands',
            'NO': 'Norway',
            'OM': 'Oman',
            'PK': 'Pakistan',
            'PW': 'Palau',
            'PS': 'Palestinian Territories',
            'PA': 'Panama',
            'PG': 'Papua New Guinea',
            'PY': 'Paraguay',
            'PE': 'Peru',
            'PH': 'Philippines',
            'PN': 'Pitcairn Islands',
            'PL': 'Poland',
            'PT': 'Portugal',
            'PR': 'Puerto Rico',
            'QA': 'Qatar',
            'RE': 'Réunion',
            'RO': 'Romania',
            'RU': 'Russia',
            'RW': 'Rwanda',
            'WS': 'Samoa',
            'SM': 'San Marino',
            'ST': 'São Tomé & Príncipe',
            'SA': 'Saudi Arabia',
            'SN': 'Senegal',
            'RS': 'Serbia',
            'SC': 'Seychelles',
            'SL': 'Sierra Leone',
            'SG': 'Singapore',
            'SX': 'Sint Maarten',
            'SK': 'Slovakia',
            'SI': 'Slovenia',
            'SB': 'Solomon Islands',
            'SO': 'Somalia',
            'ZA': 'South Africa',
            'GS': 'South Georgia & South Sandwich Islands',
            'KR': 'South Korea',
            'SS': 'South Sudan',
            'ES': 'Spain',
            'LK': 'Sri Lanka',
            'BL': 'St. Barthélemy',
            'SH': 'St. Helena',
            'KN': 'St. Kitts & Nevis',
            'LC': 'St. Lucia',
            'MF': 'St. Martin',
            'PM': 'St. Pierre & Miquelon',
            'VC': 'St. Vincent & Grenadines',
            'SD': 'Sudan',
            'SR': 'Suriname',
            'SJ': 'Svalbard & Jan Mayen',
            'SZ': 'Swaziland',
            'SE': 'Sweden',
            'CH': 'Switzerland',
            'SY': 'Syria',
            'TW': 'Taiwan',
            'TJ': 'Tajikistan',
            'TZ': 'Tanzania',
            'TH': 'Thailand',
            'TL': 'Timor-Leste',
            'TG': 'Togo',
            'TK': 'Tokelau',
            'TO': 'Tonga',
            'TT': 'Trinidad & Tobago',
            'TA': 'Tristan da Cunha',
            'TN': 'Tunisia',
            'TR': 'Turkey',
            'TM': 'Turkmenistan',
            'TC': 'Turks & Caicos Islands',
            'TV': 'Tuvalu',
            'UM': 'U.S. Outlying Islands',
            'VI': 'U.S. Virgin Islands',
            'UG': 'Uganda',
            'UA': 'Ukraine',
            'AE': 'United Arab Emirates',
            'GB': 'United Kingdom',
            'US': 'United States',
            'UY': 'Uruguay',
            'UZ': 'Uzbekistan',
            'VU': 'Vanuatu',
            'VA': 'Vatican City',
            'VE': 'Venezuela',
            'VN': 'Vietnam',
            'WF': 'Wallis & Futuna',
            'EH': 'Western Sahara',
            'YE': 'Yemen',
            'ZM': 'Zambia',
            'ZW': 'Zimbabwe'
        }

    };
});