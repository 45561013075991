define("oceasoft-web/routes/pairings/new", ["exports", "ember", "oceasoft-web/objects/module", "ember-i18n", "oceasoft-web/mixins/admin-route"], function (exports, _ember, _oceasoftWebObjectsModule, _emberI18n, _oceasoftWebMixinsAdminRoute) {
  var _slice = Array.prototype.slice;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], {

    titleToken: (0, _emberI18n.translationMacro)('pairings.page.new_module'),

    model: function model() {
      return _oceasoftWebObjectsModule["default"].create({});
    },

    resetController: function resetController(controller) {
      var _super;

      (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));

      controller.send('resetController');
    }

  });
});