define("oceasoft-web/services/session", ["exports", "ember", "ember-simple-auth/services/session", "oceasoft-web/objects/session", "oceasoft-web/objects/user", "oceasoft-web/objects/company", "oceasoft-web/objects/utils", "oceasoft-web/config/environment"], function (exports, _ember, _emberSimpleAuthServicesSession, _oceasoftWebObjectsSession, _oceasoftWebObjectsUser, _oceasoftWebObjectsCompany, _oceasoftWebObjectsUtils, _oceasoftWebConfigEnvironment) {

    var TAG = 'session';
    var $ = _ember["default"].$;
    var on = _ember["default"].on;
    var service = _ember["default"].inject.service;
    var observer = _ember["default"].observer;
    var isEmpty = _ember["default"].isEmpty;
    var typeOf = _ember["default"].typeOf;
    var _Ember$run = _ember["default"].run;
    var once = _Ember$run.once;
    var cancel = _Ember$run.cancel;
    var later = _Ember$run.later;
    var debounce = _Ember$run.debounce;
    var copyProperties = _oceasoftWebObjectsUtils["default"].Object.copyProperties;
    exports["default"] = _emberSimpleAuthServicesSession["default"].extend({

        logger: service('oc-logger'),
        api: service('oc-api'),
        i18n: service('i18n'),
        store: service(),

        handleInit: on('init', 'authenticationSucceeded', 'internalDataChanged', 'sessionDataUpdated', function () {
            once(this, 'refreshSessionData');
        }),

        handleDataChange: observer('isAuthenticated', 'data', function () {
            once(this, 'refreshSessionData');
        }),

        handleInvalidationSucceeded: on('invalidationSucceeded', function () {
            this.get('logger').info(TAG, 'handleInvalidationSucceeded');

            // Clear all the data.
            this.set('data.internal', {});
        }),

        observerUserLocale: observer('data.internal.session.user.language', function () {
            once(this, 'changeUserLocale');
        }),

        changeUserLocale: function changeUserLocale() {
            var availableLocales = this.get('i18n.locales').filter(function (e, i, arr) {
                return arr.indexOf(e) === i;
            });
            var platformLocale = this.get('i18n.locale');
            var userLocale = this.get('data.internal.session.user.language');

            this.get('logger').info(TAG, "changeUserLocale - userLocale: " + userLocale + ", platformLocale: " + platformLocale + ", availableLocales: " + availableLocales.toString());

            if (!isEmpty(userLocale) && userLocale !== platformLocale && availableLocales.indexOf(userLocale > -1)) {
                this.get('logger').info(TAG, "changeUserLocale - changed: " + userLocale);

                this.set('i18n.locale', userLocale);
            }
        },

        refreshSessionData: function refreshSessionData() {
            var _getProperties = this.getProperties('logger');

            var logger = _getProperties.logger;

            logger.info(TAG, 'refreshSessionData');

            var isAuthenticated = this.get('isAuthenticated');
            var readOnlySession = this.get('data.authenticated.session');
            var internalSession = this.get('data.internal.session');

            if (!isAuthenticated) {
                return;
            }

            // If there is data available in the internal session we need to remake the object as it does not store ember objects.
            if (!isEmpty(internalSession) && typeOf(internalSession) !== 'instance') {
                logger.info(TAG, 'refreshSessionData: remaking from internal session');
                this.remakeSession.call(this, internalSession);
            } else if (isEmpty(internalSession) && !isEmpty(readOnlySession)) {
                logger.info(TAG, 'refreshSessionData: remaking from read-only session');
                this.remakeSession.call(this, readOnlySession);

                // We finish here because there is no need to get online info. This case will be when the user just authenticated.
                return;
            }

            // In case the user has changed his password we need to force a change on the readOnlySession data in order to sync both objects.
            var readOnlyUserPassword = typeOf(readOnlySession) !== 'instance' ? readOnlySession.user.password : readOnlySession.get('user.password');
            var userPassword = typeOf(internalSession) !== 'instance' ? internalSession.user.password : internalSession.get('user.password');

            if (readOnlyUserPassword !== userPassword) {
                logger.info(TAG, 'refreshSessionData: user password change - forcing session sync');
                this.forceSyncReadOnlyData.call(this);

                return;
            }

            // Refresh data from the API in order to get the latest information.
            logger.info(TAG, 'refreshSessionData: re-fetching online data');
            once(this, this.refreshOnlineData);
        },

        forceSyncReadOnlyData: function forceSyncReadOnlyData() {
            this.get('logger').info(TAG, 'forceSyncReadOnlyData');

            var store = this.get('session.store._store');
            var data = store.get('_lastData');

            data.authenticated.session.user = data.internal.session.user;
            data.authenticated.session.company = data.internal.session.company;

            store.persist(data);
            store.trigger('sessionDataUpdated', data);
        },

        refreshOnlineData: function refreshOnlineData() {
            var _this = this;

            var logger = this.get('logger');
            logger.info(TAG, 'refreshOnlineData');

            var session = this.get('data.internal.session');

            if (isEmpty(session)) {
                logger.info(TAG, 'refreshOnlineData - session empty, exiting');
                return;
            }

            this.get('api').authenticate(session.get('user.email'), session.get('user.password')).then(function (data) {
                // The online refresh may happen at any point in time while the user is navigating the app.
                // We do not simply replace the old session with the new session as that will change the current referenced objects and the models will never update.
                // Instead we copy the data from the new session to the old one therefor keeping the current instance of objects in the session.
                logger.info(TAG, 'refreshOnlineData - copying properties to old session instance');
                copyProperties(data.session.get('user'), session.get('user'));
                copyProperties(data.session.get('company'), session.get('company'));

                // Because we are just modifying underlying data it does not get persisted to the store so we sync it here.
                _this.get('session.store._store').persist(_this.get('session.store._store._lastData'));
            })["catch"](function () {});
        },

        remakeSession: function remakeSession(session) {
            this.get('logger').info(TAG, 'remakeSession');

            if (isEmpty(session)) {
                this.get('logger').info(TAG, 'remakeSession - empty session - closing');
                return;
            }

            var isEmberObject = typeOf(session) === 'instance';
            var user = isEmberObject ? session.get('user') : session.user;
            var company = isEmberObject ? session.get('company') : session.company;

            var remadeSession = _oceasoftWebObjectsSession["default"].create({
                user: _oceasoftWebObjectsUser["default"].create(user),
                company: _oceasoftWebObjectsCompany["default"].create(company)
            });

            this.set('data.internal', { session: remadeSession });
        },

        notifyInternalSessionDataChanged: function notifyInternalSessionDataChanged() {
            this.get('logger').info(TAG, 'notifyInternalSessionDataChanged');
            this.trigger('internalDataChanged');
        },

        // region Session Timeout

        sessionTimeoutScheduler: null,

        observeAuthenticationChange: observer('isAuthenticated', function () {
            once(this, 'handleAuthenticationChange');
        }),

        handleAuthenticationChange: function handleAuthenticationChange() {
            var _this2 = this;

            this.get('logger').info(TAG, 'handleAuthenticationChange');

            if (this.get('isAuthenticated')) {
                once(this, 'handleSessionTimeoutScheduling');

                $('body').on('click keydown', function () {
                    debounce(_this2, 'handleSessionTimeoutScheduling', 100);
                });
            } else {
                $('body').off('click keydown');
            }
        },

        handleSessionTimeoutScheduling: function handleSessionTimeoutScheduling() {
            this.get('logger').info(TAG, "handleSessionTimeoutScheduling " + _oceasoftWebConfigEnvironment["default"].APP.SESSION_TIMEOUT + "m");

            var sessionTimeoutScheduler = this.get('sessionTimeoutScheduler');

            if (!isEmpty(sessionTimeoutScheduler)) {
                this.get('logger').info(TAG, 'handleSessionTimeoutScheduling: canceling old scheduler');
                cancel(sessionTimeoutScheduler);
            }

            this.set('sessionTimeoutScheduler', later(this, function () {
                this.get('logger').info(TAG, 'handleSessionTimeoutScheduling: expired, invalidating session');
                this.invalidate();
            }, _oceasoftWebConfigEnvironment["default"].APP.SESSION_TIMEOUT * 60 * 1000));
        }

    });
});
// endregion