define("oceasoft-web/objects/host", ["exports", "ember", "oceasoft-web/config/environment", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebConfigEnvironment, _oceasoftWebObjectsConstants) {
  var isEmpty = _ember["default"].isEmpty;

  var Host = _ember["default"].Object.extend(_ember["default"].Copyable, {

    init: function init() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('id', 'url');

      var id = _getProperties.id;
      var url = _getProperties.url;

      if (!isEmpty(id) && isEmpty(url)) {
        switch (id) {
          case _oceasoftWebObjectsConstants["default"].Host.AMERICAS:
            this.set('url', _oceasoftWebConfigEnvironment["default"].APP.API.URL.AMERICAS);
            break;

          case _oceasoftWebObjectsConstants["default"].Host.APAC:
            this.set('url', _oceasoftWebConfigEnvironment["default"].APP.API.URL.APAC);
            break;

          case _oceasoftWebObjectsConstants["default"].Host.EMEA:
            this.set('url', _oceasoftWebConfigEnvironment["default"].APP.API.URL.EMEA);
            break;
        }
      }
    },

    id: null,
    url: null,

    copy: function copy() {
      return Host.create(this);
    }

  });

  exports["default"] = Host;
});