define('oceasoft-web/helpers/is-equal-time', ['exports', 'ember-date-components/helpers/is-equal-time'], function (exports, _emberDateComponentsHelpersIsEqualTime) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualTime['default'];
    }
  });
  Object.defineProperty(exports, 'isEqualTime', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualTime.isEqualTime;
    }
  });
});