define("oceasoft-web/routes/voucher/new", ["exports", "ember", "oceasoft-web/objects/voucher", "ember-i18n", "oceasoft-web/mixins/admin-route"], function (exports, _ember, _oceasoftWebObjectsVoucher, _emberI18n, _oceasoftWebMixinsAdminRoute) {
  var _slice = Array.prototype.slice;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], {

    titleToken: (0, _emberI18n.translationMacro)('voucher.page.new_voucher'),

    model: function model() {
      return _oceasoftWebObjectsVoucher["default"].create({});
    },

    resetController: function resetController(controller) {
      var _super;

      (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));

      controller.send('resetController');
    }

  });
});