define('oceasoft-web/helpers/is-equal-day', ['exports', 'ember-date-components/helpers/is-equal-day'], function (exports, _emberDateComponentsHelpersIsEqualDay) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualDay['default'];
    }
  });
  Object.defineProperty(exports, 'isEqualDay', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualDay.isEqualDay;
    }
  });
});