define("oceasoft-web/mixins/remove-empty-yields", ["exports", "ember", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsUtils) {
  var on = _ember["default"].on;
  var isEmpty = _ember["default"].isEmpty;
  var _Ember$run = _ember["default"].run;
  var once = _Ember$run.once;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var observeMutation = _oceasoftWebObjectsUtils["default"].Component.observeMutation;
  exports["default"] = _ember["default"].Mixin.create({

    checkEmptyElements: [],

    handleMutationChange: observeMutation(function () {
      once(this, 'removeEmptyElements');
    }),

    handleInitUpdate: on('didRender', 'didInsertElement', 'didUpdate', function () {
      scheduleOnce('afterRender', this, 'removeEmptyElements');
    }),

    removeEmptyElements: function removeEmptyElements() {
      var _this = this;

      Rx.Observable.fromArray(this.get('checkEmptyElements')).map(function (elName) {
        return _this.$(elName);
      }).filter(function (el) {
        return !isEmpty(el);
      }).forEach(function (el) {
        var shouldHide = isEmpty(el.html().replace(new RegExp('<!---->', 'g'), '').trim());
        el.css('display', shouldHide ? 'none' : '');
      });
    }
  });
});