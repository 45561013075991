define('oceasoft-web/locales/fr/translations', ['exports'], function (exports) {
    exports['default'] = {
        'appname': 'EDGEVue',
        'navigation': {
            'menu': {
                'my_profile': 'Mon profil',
                'view': 'Vue',
                'profiles': 'Profils',
                'pairing': 'Appairage',
                'voucher': 'Voucher',
                'about': 'A propos',
                'logout': 'Déconnexion'
            }
        },
        'select_language_placeholder': 'Choisir la langue',
        'try_again': 'Essayez à nouveau',
        'got_it': 'J\'ai compris',
        'ok': 'OK',
        'add_here': 'Ajouter ici',
        'more_info': 'Plus d\'informations',
        'download': 'Télécharger',
        'save_changes': 'Sauvegarder les changements',
        'discard_changes': 'Annuler les changements',
        'search_placeholder': 'Rechercher',
        'yes': 'Oui',
        'no': 'Non',
        'cancel': 'Annuler',
        'confirmation': 'Confirmation',
        'none': 'Aucun',
        'celsius': '°C',
        'fahrenheit': '°F',
        'point': '1.0 <br/> Point',
        'comma': '1,0 <br/> Virgule',
        'loading': 'Chargement en cours',
        'loading_description': 'Merci de patienter pendant le chargement des données.',
        'not_available': 'Pas encore disponible',
        'not_available_description': 'Nous sommes désolés, mais cette section n\'est pas encore disponible dans la version actuelle.',
        'error_dialog_title': 'Erreur',
        'dashboard': 'Tableau de bord',
        'select_country_placeholder': '-Choisissez un pays-',

        'calendar_title': 'Calendrier',
        'calendar_description': 'Choisir les dates et heures pour filtrer les modules et missions.',

        'timeZone': 'Fuseau horaire',
        'gmt': 'GMT',

        'timePeriod': {
            '0': 'Tout',
            '1': 'Dernières 24h',
            '2': 'Semaine dernière',
            '3': 'Mois dernier',
            '4': 'Calendrier'
        },

        'timeFormat': {
            'title': 'Format d\'heure',
            'type': {
                '1': '12 heures (a.m/p.m.)',
                '0': '24 heures'
            }
        },

        'dateFormat': {
            'title': "Format de date",
            'type': {
                'MM/DD/YYYY': 'MM/JJ/AAAA',
                'DD/MM/YYYY': 'JJ/MM/AAAA'
            }
        },

        'timeUnit': {
            'seconds': '{{val}} secondes',
            'minutes': '{{val}} min(s)'
        },

        'temperatureUnit': {
            'title': 'Unité',
            'type': {
                '0': '°C Celsius',
                '1': '°F Fahrenheit'
            }
        },

        'decimalSeparator': {
            'title': 'Séparateur décimal',
            'type': {
                '0': '1.0 Point',
                '1': '1,0 Virgule'
            }
        },

        'countryInput': {
            'title': 'Langue d\'affichage',
            'language': {
                'EN': "Anglais",
                'FR': 'Français',
                'ES': "Espagnol"
            }
        },

        'logout_confirm': 'Êtes-vous sûr de vouloir vous déconnecter ?',
        'notifications': {
            'title': 'Notifications',
            'alarms': 'Alarmes',
            'mission_read': 'Mission lue',
            'mission_started': 'Mission démarrée',
            'mission_stopped': 'Mission stoppée'
        },
        'cvs_template': {
            'type': 'Type',
            'date': 'Date',
            'time': 'Heure',
            'datetime': 'Date-Heure',
            'value': 'Valeur',
            'info1': 'Information 1',
            'info2': 'Information 2'
        },
        'user': {
            'level': {
                '1': 'Administrateur',
                '0': 'Utilisateur'
            }
        },
        'mission': {
            'event_type': {
                '-1': 'Evenement inconnu',
                '0': 'Mission programmée',
                '1': 'Enregistrement des données démarré',
                '2': 'Module connecté',
                '3': 'Téléchargement des relevés',
                '4': 'Mission arrêtée',
                '5': 'Reinitialisation d\'alarme',

                '-10': 'Alarme inconnue',
                '10': 'Seuil haut',
                '11': 'Seuil bas',
                '12': 'Echec capteur',
                '13': 'Batterie faible',
                '14': 'Hors de portee',

                '20': 'Programmé sur le banc',
                '21': 'Mission geolocalisée'
            }
        },
        'module': {
            'type': {
                '0': 'Inconnu',
                '1': 'Module',
                '2': 'EDGE S400'
            },
            'mission_search_filter_placeholder': '-Etat de la mission-',
            'mission_search_filter': {
                '0': 'Aucune mission',
                '1': 'Mission(s) avec alarme(s)',
                '2': 'Mission(s) sans alarme',
                '3': 'Missions stoppées/terminées',
                '4': 'Toutes les missions'
            },
            'mission_alarm_search_filter': {
                '0': 'état d\'alarme',
                '1': 'En alarme',
                '2': 'Pas d\'alarme en cours'
            },
            'mission_filter': {
                '0': 'Toutes les missions',
                '1': 'Pas d\'alarme',
                '2': 'Une alarme ou plus'
            }
        },
        'search': {
            'no_matches_title': 'Aucun résultat',
            'no_matches_description': 'Votre recherche ne donne aucun résultat. <br/><br/>Veuillez relancer une autre requête.'
        },
        'reset_fields': {
            'one': 'Réinitialiser le champs',
            'other': 'Réinitialiser les champs'
        },
        'host': {
            'label': 'Hôte',
            '0': 'Amérique',
            '1': 'EMEA (Europe, Moyen-Orient, Afrique)',
            '2': 'APAC (Asie-Pacifique)'
        },
        'voucher': {
            'page': {
                'title': 'Voucher',
                'add_message_description': 'Vous avez un voucher et vous voudriez l\'utiliser ?',
                'my_voucher': 'Mon Voucher',
                'new_voucher': 'Nouveau Voucher',
                'new_voucher_key': 'Nouvelle clé de voucher',
                'latest_voucher_key': 'Dernière clé de voucher',
                'available_modules': 'Modules disponibles',
                'total_nr_modules': 'Nombre total de modules',
                'exp_date': 'Date d\'expiration',
                'new_save_msg': 'Ajout de voucher réussi.',
                'add_voucher': 'Ajouter Voucher',
                'no_voucher_msg': 'Aucun Voucher',
                'no_voucher_msg_details': 'Vous n\'avez pas ajouté de voucher.',
                'incorrect_voucher_key': 'Cette clé de voucher n\'est pas correcte.',
                'key_already_used': 'Cette clé de voucher a déjà été utilisé.',
                'voucher_expired': 'La date d\'expiration est déjà passée.',
                'voucher_remove_modules': 'Impossible d\'ajouter ce voucher. Veuillez enlever {{x}} module(s).'
            },
            'label': 'Voucher',
            'type': {
                '0': 'EDGE M300 & EDGE S400',
                '1': 'EDGE S400 seulement'
            }
        },
        'about': {
            'page': {
                'title': 'A propos',
                'product_info': 'Informations du produit',
                'accessories': 'Accessoires',
                'user_guide': 'Guide utilisateur',
                'software_version': 'EDGEVue {{version}}',
                'product_info_page': 'Utilisez EDGEVue pour gérer les fonctionnalités Cloud de vos modules TEMPTIME.<br>Configurez vos informations utilisateurs et entreprises, ajouter des modules et visualiser vos données.',
                'accessories_page': 'Personnalisez vos modules EDGE M300 avec des anneaux de couleur pour les identifier facilement. Visitez le site web TEMPTIME pour plus d\'informations.',
                'guide_page': 'Consultez en ligne le Guide de l\'Utilisateur pour découvrir et apprendre toute la fonctionnalité de la solution EDGEVue.'
            }
        },
        'pairings': {
            'page': {
                'title': 'Appairage',
                'module_list': 'Liste des modules',
                'new_module': 'Nouveau Module',
                'module_sn': 'Module {{serial}}',
                'add_module_msg': 'Voulez-vous ajouter un module ?',
                'no_modules_msg_title': 'Aucun Module',
                'no_modules_msg_description': 'Aucun module n\'est attribué à votre entreprise actuellement',
                'what_to_add_new_module': 'Ajouter un nouveau module ?',
                'no_modules_selected_msg_title': 'Aucun module sélectionné',
                'no_modules_selected_msg_description': 'Pour modifier un module, vous devez d\'abord le choisir dans la liste.',
                'serial_nr_mandatory': 'Numéro de série <span class="red">*</span>',
                'serial_nr': 'Numéro de série',
                'name': 'Nom',
                'remove_module': 'Retirer un module',
                'remove_confirmation': 'Vous allez supprimer ce module.<br/>Cette action est définitive et irréversible.<br/><br/>Voulez-vous continuer ?',
                'add_one_module': 'Ajouter un module',
                'add_multiple_modules': 'Ajouter plusieurs modules',
                'select_file': 'Choisir un fichier',
                'select_another_file': 'Choisir un autre fichier',
                'how_to_find_serial': 'Où se trouve le numéro de série ?',
                'find_serial_description': 'Modules EDGE M300 : Saisissez le numéro de série imprimé sur l\'étiquette de votre module, puis cliquez sur AJOUTER UN MODULE. <br> <br> Modules EDGE S400 : Vous n\'avez pas besoin de saisir un numéro de série ici. Les modules EDGE S400 sont automatiquement associés à votre compte lorsque vous programmez leur première mission liée au Cloud, avec EDGEVue.',
                'batch_add': 'Ajout d\'un lot',
                'batch_add_details_1': 'Vous pouvez télécharger un fichier texte contenant les numéros de série de tous vos modules.',
                'batch_add_details_2': 'Le texte doit être formaté avec un seul numéro de série par ligne.',
                'error_unkown_serial': 'Numéro de série incorrect.',
                'module_added_msg': {
                    'one': 'Module ajouté avec succès.',
                    'other': '{{count}} modules ajoutés avec succès.'
                },
                'error_module_atlas': 'Vous devez utiliser l\'application mobile EDGEVue pour ajouter un module EDGE S400.',
                'error_invalid_module': 'Impossible d\'ajouter ce module',
                'error_voucher_limit_reached': 'La limite autorisée par votre voucher est atteinte.',
                'error_invalid_hash_code': 'Numéro de série invalide',
                'error_voucher_invalid_serial': 'Le numéro de série est incorrect.',
                'error_atlas_not_cloud_enabled': 'Ce module EDGE S400 n\'est pas compatible avec le Cloud.',
                'error_atlas_cloud_enabled': 'Ce module EDGE S400 est compatible avec le Cloud, mais vous devez utiliser l\'application mobile pour l\'associer.',
                'error_already_used': 'Numéro de série déjà utilisé',
                'error_module_already_used': 'Le numéro de série existe déjà.<br/>Le numéro de série que vous essayez d\'ajouter est déjà configuré sur la plateforme.<br/>Veuillez essayer un autre numéro de série.',
                'error_file_invalid': 'Format de fichier invalide',
                'error_file_invalid_description': 'Le fichier fourni comporte du contenu invalide. Pour ajouter plusieurs modules à la fois, veuillez fournir un fichier texte avec un seul numéro de série par ligne.',
                'error_no_serials_extracted': 'Aucun numéro de série trouvé',
                'error_no_serials_extracted_description': 'Aucun numéro de série n\'a été trouvé dans le fichier téléchargé.',
                'file_serials': 'Numéros de séries valides à ajouter :',
                'file_added': 'Fichier téléchargé :',
                'dropzone_title': 'DEPOSER VOTRE FICHIER ICI',
                'dropzone_message': 'Le fichier sera téléchargé automatiquement dès que vous le posez ici.',
                'upload_file': 'Pour ajouter plusieurs modules, sélectionnez d\'abord un fichier.',
                'error_uploading_success_all': 'Plusieurs modules ajoutés',
                'error_uploading_success_all_description': 'Tous les modules ont été ajoutés avec succès.',
                'error_uploading_success_some': {
                    'one': 'Module ajouté',
                    'other': 'Module ajoutés'
                },
                'error_uploading_success_some_description': '{{nrAdded}} sur {{nrTotal}} modules a/ont été ajouté(s).<br/><br/>Veuillez consulter la liste de modules pour identifier ceux qui ont échoué.',
                'error_uploading_multiple': 'Échec de création de multiples modules',
                'error_uploading_multiple_description': 'Tous les modules ont échoué en essayant de les ajouter au serveur.',
                'loading_remove_module': 'Suppression du module en cours.',
                'error_remove': 'Échec de suppression',
                'error_remove_description': 'Ce module à enlever n\'a pas été trouvé sur le serveur.',
                'module_removed_msg': 'Module supprimé',
                'error_voucher_limit': 'Impossible d\'appairer de nouveaux modules',
                'error_voucher_limit_details': 'Votre voucher a expiré ou vous avez atteint le nombre maximum de modules. Vous ne pouvez pas appairer de nouveaux modules.<br/> Veuillez voir la page "Mon Voucher".',
                'error_no_voucher_details': 'Vous n\'avez pas saisi de voucher. Vous ne pouvez pas appairer de nouveaux modules.<br/>Veuillez ajouter un voucher pour utiliser cette fonctionnalité.'
            }
        },
        'profiles': {
            'page': {
                'title': 'Profils',
                'my_profile': 'Mon profil',
                'user_list': 'Liste des utilisateurs',
                'company_info': 'A propos de l\'entreprise',
                'add_user_message': 'Voulez-vous ajouter un nouveau compte utilisateur ?',
                'new_user': 'Nouvel utilisateur',
                'no_users': 'Aucun utilisateur',
                'no_users_description': 'Il n\'y a pas d\'autre utilisateur configuré pour votre entreprise pour l\'instant.',
                'no_user_selected': 'Aucun utilisateur sélectionné',
                'no_user_selected_description': 'Pour modifier un utilisateur, vous devez d\'abord le choisir dans la liste.',
                'remove_user': 'Supprimer un utilisateur',
                'add_user': 'Ajouter un utilisateur',
                'first_name_placeholder': 'Prénom <span class="red">*</span>',
                'last_name_placeholder': 'Nom <span class="red">*</span>',
                'email_placeholder': 'Adresse e-mail <span class="red">*</span>',
                'level_placeholder': 'Niveau',
                'password_placeholder': 'Mot de Passe <span class="red">*</span>',
                'password_confirm_placeholder': 'Valider mot de passe <span class="red">*</span>',
                'add_new_user': 'Ajouter un nouvel utilisateur',
                'remove_user_dialog_text': 'Vous allez supprimer cet utilisateur.<br/>Cette action est définitive et irréversible.<br/><br/>Voulez-vous continuer ?',
                'user_added_msg': '{{fullName}} a été ajouté(e).',
                'user_modified_msg': '{{fullName}} a été modifié(e).',
                'user_removed': '{{fullName}} a été supprimé(e).',
                'profile_modified': 'Votre profil a été modifié.',
                'error': {
                    'edit': {
                        'wrong_customer_id': 'Entreprise invalide',
                        'wrong_customer_id_description': 'L\'utilisateur que vous essayez d\'éditer n\'appartient pas à votre entreprise.',
                        'not_in_db_user': 'Utilisateur invalide',
                        'not_in_db_user_description': 'L\'utilisateur que vous essayez d\'éditer n\'a pas été trouvé sur le serveur.'
                    },
                    'add': {
                        'already_exists': 'Utilisateur déjà présent',
                        'already_exists_description': 'L\'utilisateur existe déjà. <br/> L\'utilisateur que vous essayez d\'ajouter est déjà présent dans le système. <br/> Veuillez utiliser une autre adresse e-mail ou contacter votre administrateur système.'
                    }
                },
                'company_info_page': {
                    'address_1': 'Adresse 1',
                    'address_2': 'Adresse 2',
                    'city': 'Ville',
                    'postal_code': 'Code postal',
                    'country': 'Pays',
                    'tel': 'Numéro de téléphone',
                    'company_saved_msg': 'Information de l\'entreprise correctement sauvegardée.'
                }
            }
        },
        'view': {
            'page': {
                'title': 'Vue',
                'no_modules_msg_title': 'Aucun module',
                'no_modules_msg_description': 'Pour l\'instant aucun module n\'est attribué à votre entreprise et vous ne pouvez pas utiliser la fonctionnalité Vue.',
                'select_start_date': 'Date de début',
                'select_end_date': 'Date de fin',
                'no_module_selected_title': 'Aucun module sélectionné',
                'no_module_selected_description': 'Sélectionnez une mission dans la liste pour accéder au détail de ses informations.<br/>En cas de sélection multiple, un affichage multi-courbes vous permettra de comparer les missions en mode "Données enregistrées".',
                'date_range_title': ' Montrer la mission démarrée entre',
                'date_range_separator': 'et',
                'selected_missions': {
                    'one': 'Une mission sélectionnée',
                    'other': '{{count}} missions sélectionnées'
                },

                'time_placeholder': '-Calendrier Mission-',

                'saved_data': 'Données enregistrées',
                'live_data': 'Surveillance',
                'pdf': 'PDF',
                'cvs': 'CSV',
                'generating_ios_error': 'Cet appareil ne supporte pas cette opération',
                'generating_ios_error_title': 'Erreur',
                'generating_safari_error_title': 'Erreur',
                'generating_safari_error': 'Cette opération n\'est pas supportée par votre navigateur',
                'max_missions_selected_error': 'Limite atteinte',
                'max_missions_selected_error_description': 'Vous avez déjà sélectionné le nombre maximum de missions.<br/>Veuillez désélectionner une mission afin de pouvoir en choisir une autre.',
                'generating_pdf_loading': 'Rapport de Mission en cours de génération',
                'pdf_generated_title': 'Rapport de mission prêt',
                'pdf_generated_description': 'Le rapport de mission a été généré et téléchargé sur votre appareil.',
                'pdf_failed_title': 'échec du rapport de mission',
                'pdf_failed_description': 'La génération du rapport de mission a échoué. Veuillez réessayer plus tard. Contactez le Support Technique si vous rencontrez ce problème de nouveau.',
                'no_missions': 'Pas de missions',
                'no_missions_details': 'Il n\'y a pas de mission disponible pour la requête actuelle.',
                'no_missions_for_this_period': 'Il n\'y a pas de mission disponible pour cette période.',
                'no_missions_selected': 'Aucune mission sélectionnée',
                'no_missions_selected_description': 'Sélectionnez un point sur la courbe ci-dessous pour accéder aux informations concernant la mission.',
                'no_missions_selected_description_touch': 'Double-cliquez sur un point de la courbe ci-dessous pour accéder aux informations concernant la mission.',
                'no_missions_selected_description_android': 'Double-cliquez sur un point de la courbe ci-dessous pour accéder aux informations concernant la mission.',
                'no_missions_selected_description_ios': 'Sélectionnez un point sur la courbe ci-dessous pour accéder aux informations concernant la mission.',
                'no_readings_events': 'Pas de données sur la mission',
                'no_readings_events_description': 'Cette mission n\'a pas encore de données, d\'évènements ni d\'alarmes.',
                'no_readings': 'Aucune lecture',
                'no_readings_description': 'Cette mission ne contient pas encore de mesures.<br/><br/>Vous pouvez activer les événements/alarmes pour voir s\'il y a des informations disponibles.',
                'no_events': 'Aucune alarme',
                'no_events_description': 'Cette mission ne contient pas encore d\'événements ou d\'alarmes.<br/><br/>Vous pouvez activer les mesures pour voir s\'il y a des informations disponibles.',
                'no_reading_event_type_selected': 'Aucun filtre sélectionné',
                'no_reading_event_type_selected_description': 'Merci de sélectionner un ou plusieurs filtres ci-dessus afin de voir les mesures, événements ou alarmes',
                'on_alarm': 'En alarme',
                'last_mission_started': 'Dernière mission démarrée',
                'start_date': 'Date de démarrage',
                'end_date': 'Date de fin',
                'unselected_date': 'Choisissez une date',
                'readings_check': 'Relevés',
                'events_alarms_check': 'Evénements/Alarmes',
                'mission_started_at_description': 'Démarrée le {{date}}',
                'too_many_result': 'Votre requête a renvoyé trop d\'éléments, veuillez sélectionner une période plus courte',

                'graph_title': 'Graphique',

                'value_or_none': {
                    'zero': 'Aucun',
                    'other': '{{count}}'
                },
                'informations': 'Informations',
                'informations_data': {
                    'sn': 'Numéro de série',
                    'reading_interval': 'Intervalle de mesure',
                    'reading_count': 'Nombre relevés Cloud',
                    'maximum_temp': 'Température maximale',
                    'minimum_temp': 'Température minimale',
                    'high_limit': 'Seuil haut',
                    'low_limit': 'Seuil bas',
                    'high_delay': 'Tempo haute',
                    'low_delay': 'Tempo basse',
                    'datalogging_start': 'Début des mesures',
                    'alarms': 'Alarme(s)',
                    'avg_temp': 'Température moyenne',
                    'reading_err': 'Erreur de lecture'
                },
                'map_not_available': 'Carte non encore disponible',
                'map_not_available_description': 'Au moins 1 évènement avec sa position GPS est nécessaire pour générer une carte.',
                'map_not_available_description_readings': 'Au moins 1 lecture avec sa position GPS est nécessaire pour générer une carte.',
                'map_not_available_no_mission_description': 'Sélectionnez une mission pour générer une carte.',
                'graph_not_available': 'Graphique non disponible',
                'graph_not_available_details': {
                    'zero': 'Aucune lecture.',
                    'one': 'Au moins deux lectures sont nécessaires pour générer un graphique.'
                }
            }
        },
        'required_field': '<span class="red">*</span> Champ Obligatoire',
        'error': {
            'unable_to_complete_operation': 'Impossible de terminer cette opération',
            'empty_field': 'Le champ est vide',
            'max_length': 'Longueur maximale dépassée',
            'empty_select': 'Valeur non sélectionnée',
            'general': 'Erreur générale',
            'general_description': 'Il semble y avoir un problème technique.<br/>Merci de réessayer plus tard.',
            'non_matching_passwords': 'Les mots de passe ne correspondent pas',
            'full_name': 'Fournissez nom et prénom',
            '404_title': 'Page non trouvée',
            '404_description': 'Il semble que la page que vous recherchez ne peut être trouvée.<br/>Merci de retourner sur les pages précédentes pour continuer.',
            'unauthorized': 'Non autorisé',
            'unauthorized_description': 'Vous n\'avez pas les permissions nécessaires pour accéder à cette page.<br/>Veuillez nous contacter si vous considérez qu\'il y a erreur.',
            'user_not_admin': 'Seuls les administrateurs sont autorisés à effectuer cette opération.',
            'user_not_enabled': 'L\'adresse e-mail/mot de passe que vous avez fournis sont invalides.',
            'user_disabled': 'Cet utilisateur n\'est pas activé. Veuillez contacter votre administrateur système.',
            'user_not_admin_detailed': 'Seuls les administrateurs sont autorisés à effectuer cette opération.'
        },
        'login': {
            'title': 'Connexion',
            'description': 'Merci d\'utiliser vos identifiants de compte pour accéder à l\'application.',
            'send_password': 'Envoyer nouveau mot de passe',
            'connect': 'Connecter',
            'register_msg': 'Avez-vous un compte ?',
            'register_link_btn': 'Créer ici',
            'email_placeholder': 'Adresse e-mail',
            'password_placeholder': 'Mot de passe',
            'invalid_email': 'E-mail invalide',
            'invalid_password': 'Caractères non-autorisés',
            'password_length': '6 caractères minimum',
            'loading_description': 'Merci de patienter',
            'error': 'Échec de l\'authentification',
            'error_description': 'L\'adresse e-mail/mot de passe que vous avez fourni sont invalides.',
            'reset_password': {
                'title': 'Réinitialisez mot de passe',
                'description': 'Vous êtes sur le point de régénérer un mot de passe pour <b>{{email}}</b>.<br/>êtes-vous sûr de vouloir poursuivre ?',
                'success': 'Mot de passe envoyé',
                'success_description': 'Votre nouveau mot de passe a été généré et nous vous l\'avons envoyé par e-mail.',
                'invalid_email': 'Email invalide',
                'invalid_email_description': 'L\'adresse e-mail que vous avez fournie n\'est pas inscrite sur notre plateforme.'
            }
        },
        'register': {
            'title': 'Créer un compte entreprise',
            'description': 'Merci de saisir vos informations personnelles afin de vous enregistrer',
            'personal_info': 'Informations personnelles',
            'email_placeholder': 'Adresse E-mail',
            'password_placeholder': 'Mot de Passe',
            'password_confirm_placeholder': 'Confirmez mot de passe',
            'first_name_placeholder': 'Prénom',
            'last_name_placeholder': 'Nom',
            'company_name_placeholder': 'Nom de l\'entreprise',
            'addr1_placeholder': 'Adresse 1',
            'addr2_placeholder': 'Adresse 2',
            'city_placeholder': 'Ville',
            'postal_code_placeholder': 'Code postal',
            'phone_number_placeholder': 'Numéro de téléphone',
            'country': 'Pays',
            'have_voucher': 'J\'ai un voucher !',
            'voucher': 'Voucher',
            'voucher_key': 'Clé d\'activation du voucher',
            'already_have_account': 'J\'ai déjà un compte',
            'create': 'Créer',
            'loading_description': 'Veuillez patientez pendant la création de votre compte sur la plateforme.<br/>Vous serez connecté(e) automatiquement.',
            'error_already_exists': 'Cet utilisateur existe déjà',
            'error_already_exists_description': 'L\'utilisateur existe déjà.<br/>Les informations concernant le nouveau compte ont déjà été utilisées.<br/>Veuillez revoir vos informations et utiliser un compte e-mail différent pour créer un compte.',
            'error_already_exists_field': 'Compte e-mail déjà utilisé',
            'error_already_exists_company': 'Entreprise déjà existante',
            'error_already_exists_company_description': 'Une entreprise avec le même nom que vous avez fourni est déjà inscrite<br/>Veuillez revoir vos informations et utiliser un nom d\'entreprise différent pour créer un compte.',
            'error_already_exists_company_field': 'Nom d\'entreprise déjà utilisé',
            'error_voucher': 'Erreur de voucher',
            'error_voucher_invalid': 'Vous avez fourni un numéro de voucher invalide.<br/>Veuillez saisir un numéro de voucher valide pour continuer.',
            'error_voucher_used': 'Le numéro de voucher que vous avez fourni a déjà été utilisé.<br/>Veuillez saisir un numéro de voucher valide pour continuer.',
            'error_voucher_invalid_field': 'Voucher invalide',
            'error_voucher_used_field': 'Voucher déjà utilisé',
            'successful': 'Vous avez terminé !',
            'successful_description': 'Le nouveau compte a été créé et nous avons pris la liberté de vous connecter.<br/>Cliquer simplement sur Continuer pour démarrer une session.',
            'continue_button': 'Continuer',
            'enter_platform_loading': 'Merci de patienter pendant l\'opération'
        },
        'copyright': 'Copyright © {{year}} TEMPTIME.<br/> Tous droits réservés.',
        'country': {
            'AF': 'Afghanistan',
            'ZA': 'Afrique du Sud',
            'AL': 'Albanie',
            'DZ': 'Algérie',
            'DE': 'Allemagne',
            'AD': 'Andorre',
            'AO': 'Angola',
            'AI': 'Anguilla',
            'AQ': 'Antarctique',
            'AG': 'Antigua-et-Barbuda',
            'BQ': 'Antilles Néerlandaises',
            'SA': 'Arabie Saoudite',
            'AR': 'Argentine',
            'AM': 'Armenie',
            'AW': 'Aruba',
            'AU': 'Australie',
            'AT': 'Autriche',
            'AZ': 'Azerbaïdjan',
            'BS': 'Bahamas',
            'BH': 'Bahreïn',
            'BD': 'Bangladesh',
            'BB': 'Barbade',
            'BY': 'Belarus',
            'BE': 'Belgique',
            'BZ': 'Belize',
            'BJ': 'Bénin',
            'BM': 'Bermudes',
            'BT': 'Bhoutan',
            'MM': 'Birmanie',
            'BO': 'Bolivie',
            'BA': 'Bosnie-Herzégovine',
            'BW': 'Botswana',
            'BR': 'Brésil',
            'BN': 'Brunei',
            'BG': 'Bulgarie',
            'BF': 'Burkina Faso',
            'BI': 'Burundi',
            'KH': 'Cambodge',
            'CM': 'Cameroun',
            'CA': 'Canada',
            'CV': 'Cap Vert',
            'EA': 'Ceuta et Melilla',
            'CL': 'Chili',
            'CN': 'Chine',
            'CY': 'Chypre',
            'CO': 'Colombie',
            'KM': 'Comores',
            'CG': 'Congo - Brazzaville',
            'CD': 'Congo - Kinshasa',
            'KP': 'Corée du Nord',
            'KR': 'Corée du Sud',
            'CR': 'Costa Rica',
            'CI': 'Côte d’Ivoire',
            'HR': 'Croatie',
            'CU': 'Cuba',
            'CW': 'Curaçao',
            'DK': 'Danemark',
            'DG': 'Diego Garcia',
            'DJ': 'Djibouti',
            'DM': 'Dominique',
            'EC': 'équateur',
            'EG': 'égypte',
            'SV': 'El Salvador',
            'AE': 'émirats Arabes Unis',
            'ER': 'érythrée',
            'ES': 'Espagne',
            'EE': 'Estonie',
            'US': 'Etats-Unis',
            'ET': 'éthiopie',
            'FJ': 'Fidji',
            'FI': 'Finlande',
            'FR': 'France',
            'GF': 'Guinée Française',
            'GA': 'Gabon',
            'GM': 'Gambie',
            'GE': 'Georgie',
            'GS': 'Géorgie du Sud-et-Iles Sandwich du Sud',
            'GH': 'Ghana',
            'GI': 'Gibraltar',
            'GR': 'Grèce',
            'GL': 'Groenland',
            'GD': 'Grenade',
            'GP': 'Guadeloupe',
            'GU': 'Guam',
            'GT': 'Guatemala',
            'GG': 'Guernesey',
            'GN': 'Guinée',
            'GW': 'Guinée-Bissau',
            'GQ': 'Guinée équatoriale',
            'GY': 'Guyane',
            'HT': 'Haïti',
            'HN': 'Honduras',
            'HK': 'Hong Kong',
            'HU': 'Hongrie',
            'AX': 'Iles Aland',
            'IC': 'Ile Canaries',
            'KY': 'Iles Cayman',
            'CX': 'Ile Christmas',
            'CC': 'Iles Cocos (Keeling)',
            'CK': 'Iles Cook',
            'AC': 'Ile de l\'Ascension',
            'IM': 'Ile de Man',
            'FO': 'Iles Féroé',
            'FK': 'Iles Malouines',
            'MP': 'Iles Mariannes du Nord',
            'MH': 'Iles Marshall',
            'MU': 'Ile Maurice',
            'UM': 'Iles Mineures éloignées des Etats-Unis',
            'NF': 'Ile Norfolk',
            'PN': 'Iles Pitcairn',
            'SB': 'Iles Salomon',
            'TC': 'Iles Turks-et-Caïques ',
            'VG': 'Iles Vierges Britanniques',
            'VI': 'Iles Vierges des Etats-Unis',
            'IS': 'Islande',
            'IN': 'Inde',
            'ID': 'Indonésie',
            'IR': 'Iran',
            'IQ': 'Irak',
            'IE': 'Irlande',
            'IL': 'Israël',
            'IT': 'Italie',
            'JM': 'Jamaïque',
            'JP': 'Japon',
            'JE': 'Jersey',
            'JO': 'Jordanie',
            'KZ': 'Kazakhstan',
            'KE': 'Kenya',
            'KG': 'Kirghizistan',
            'KI': 'Kiribati',
            'XK': 'Kosovo',
            'KW': 'Koweït',
            'LA': 'Laos',
            'LV': 'Lettonie',
            'LB': 'Liban',
            'LS': 'Lesotho',
            'LR': 'Libéria',
            'LY': 'Libye',
            'LI': 'Liechtenstein',
            'LT': 'Lituanie',
            'LU': 'Luxembourg',
            'MO': 'Macao',
            'MK': 'Macédoine',
            'MG': 'Madagascar',
            'MY': 'Malaisie',
            'MW': 'Malawi',
            'MV': 'Maldives',
            'ML': 'Mali',
            'MT': 'Malte',
            'MA': 'Maroc',
            'MQ': 'Martinique',
            'MR': 'Mauritanie',
            'YT': 'Mayotte',
            'MX': 'Mexique',
            'FM': 'Micronésie',
            'MD': 'Moldavie',
            'MC': 'Monaco',
            'MN': 'Mongolie',
            'ME': 'Monténégro',
            'MS': 'Montserrat',
            'MZ': 'Mozambique',
            'NA': 'Namibie',
            'NR': 'Nauru',
            'NP': 'Népal',
            'NC': 'Nouvelle-Calédonie',
            'NZ': 'Nouvelle Zélande',
            'NI': 'Nicaragua',
            'NE': 'Niger',
            'NG': 'Nigéria',
            'NU': 'Niue',
            'NO': 'Norvège',
            'OM': 'Oman',
            'UG': 'Ouganda',
            'UZ': 'Ouzbékistan',
            'PK': 'Pakistan',
            'PW': 'Palaos',
            'PS': 'Palestine',
            'PA': 'Panama',
            'PG': 'Papouasie Nouvelle Guinée',
            'PY': 'Paraguay',
            'NL': 'Pays-Bas',
            'PE': 'Pérou',
            'PH': 'Philippines',
            'PL': 'Pologne',
            'PF': 'Polynésie Française',
            'PT': 'Portugal',
            'PR': 'Porto Rico',
            'QA': 'Qatar',
            'CF': 'République de Centrafrique',
            'DO': 'République Dominicaine',
            'CZ': 'République Tchèque',
            'RE': 'Réunion',
            'RO': 'Roumanie',
            'GB': 'Royaume-Uni',
            'RU': 'Russie',
            'RW': 'Rwanda',
            'EH': 'Sahara Occidental',
            'BL': 'Saint-Barthélemy',
            'SH': 'Sainte-Hélène',
            'KN': 'Saint-Christophe-et-Niévès',
            'LC': 'Sainte-Lucie',
            'SM': 'Saint-Marin',
            'SX': 'Saint-Martin',
            'MF': 'Saint Martin',
            'PM': 'Saint-Pierre-et-Miquelon',
            'VC': 'Saint-Vincent-Et-Les-Grenadines',
            'WS': 'Samoa',
            'AS': 'Samoa Américaines',
            'ST': 'São Tomé & Príncipe',
            'SN': 'Sénégal',
            'RS': 'Serbie',
            'SC': 'Seychelles',
            'SL': 'Sierra Leone',
            'SG': 'Singapour',
            'SK': 'Slovaquie',
            'SI': 'Slovénie',
            'SO': 'Somalie',
            'SD': 'Soudan',
            'SS': 'Soudan du Sud',
            'LK': 'Sri Lanka',
            'SE': 'Suède',
            'CH': 'Suisse',
            'SR': 'Suriname',
            'SJ': 'Svalbard et Jan Mayen',
            'SZ': 'Swaziland',
            'SY': 'Syrie',
            'TW': 'Taiwan',
            'TJ': 'Tadjikistan',
            'TZ': 'Tanzanie',
            'TD': 'Tchad',
            'TF': 'Terres australes et antarctiques françaises',
            'IO': 'Territoire Britannique de l\'Océan Indien',
            'TH': 'Thaïlande',
            'TL': 'Timor Oriental',
            'TG': 'Togo',
            'TK': 'Tokelau',
            'TO': 'Tonga',
            'TT': 'Trinidad & Tobago',
            'TA': 'Tristan da Cunha',
            'TN': 'Tunisie',
            'TM': 'Turkménistan',
            'TR': 'Turquie',
            'TV': 'Tuvalu',
            'UA': 'Ukraine',
            'UY': 'Uruguay',
            'VU': 'Vanuatu',
            'VA': 'Vatican',
            'VE': 'Venezuela',
            'VN': 'Vietnam',
            'WF': 'Wallis & Futuna',
            'YE': 'Yémen',
            'ZM': 'Zambie',
            'ZW': 'Zimbabwe'
        }
    };
});