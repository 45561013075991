define("oceasoft-web/routes/voucher/index", ["exports", "ember", "ember-i18n", "oceasoft-web/mixins/admin-route", "oceasoft-web/mixins/session-manager"], function (exports, _ember, _emberI18n, _oceasoftWebMixinsAdminRoute, _oceasoftWebMixinsSessionManager) {
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], _oceasoftWebMixinsSessionManager["default"], {

    api: service('oc-api'),

    titleToken: (0, _emberI18n.translationMacro)('voucher.page.my_voucher'),

    model: function model() {
      return this.get('company');
    }

  });
});