define("oceasoft-web/router", ["exports", "ember", "oceasoft-web/config/environment"], function (exports, _ember, _oceasoftWebConfigEnvironment) {

    var Router = _ember["default"].Router.extend({
        location: _oceasoftWebConfigEnvironment["default"].locationType
    });

    Router.map(function () {

        this.route('authentication', { path: '/authentication' }, function () {
            /**
             * Connect
             */
            this.route('login', { path: '/login' });

            /**
             * Register
             */
            this.route('register', { path: '/register' });
        });

        /**
         * Module View
         */
        this.route('view', { path: '/view' });

        /**
         * Profiles, User List & Company Information
         */
        this.route('profiles', { path: '/profiles' }, function () {
            this.route('users', { path: '/users' }, function () {
                this.route('user', { path: '/:id' });
            });

            this.route('me', { path: '/me' });
            this.route('new', { path: '/users/new' });
            this.route('company-information', { path: '/company-information' });
        });

        /**
         * Pairings
         */
        this.route('pairings', { path: '/pairings' }, function () {
            this.route('modules', { path: '/modules' }, function () {
                this.route('module', { path: '/:id' });
            });

            this.route('new', { path: '/new' });
        });

        /**
         * Voucher & My Voucher
         */
        this.route('voucher', { path: '/voucher' }, function () {
            this.route('new', { path: '/new' });
        });

        /**
         * About
         */
        this.route('about', { path: '/about' }, function () {
            this.route('product-info', { path: '/product-info' });
            this.route('accessories', { path: '/accessories' });
            this.route('guide', { path: '/guide' });
        });

        /**
         * Errors
         */
        this.route('error', { path: '/*path' });
    });

    exports["default"] = Router;
});