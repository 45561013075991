define('oceasoft-web/components/oc-datetimepicker', ['exports', 'ember', 'oceasoft-web/mixins/outside-click', 'ember-datetimepicker/components/date-time-picker'], function (exports, _ember, _oceasoftWebMixinsOutsideClick, _emberDatetimepickerComponentsDateTimePicker) {
    var $ = _ember['default'].$;
    var on = _ember['default'].on;
    var computed = _ember['default'].computed;
    var isEmpty = _ember['default'].isEmpty;
    var htmlSafe = _ember['default'].String.htmlSafe;
    var service = _ember['default'].inject.service;

    _emberDatetimepickerComponentsDateTimePicker['default'].reopen({
        i18n: service('i18n'),

        handleInit: on("init", function () {
            $.datetimepicker.setLocale(this.get('i18n.locale'));
            $.datetimepicker.setDateFormatter({
                parseDate: function parseDate(date, format) {
                    var d = moment(date, format);
                    return d.isValid() ? d.toDate() : false;
                },

                formatDate: function formatDate(date, format) {
                    return moment(date).format(format);
                }
            });
        }),

        _changeHandler: function _changeHandler(event) {
            var _this = this;

            var format = this.get('options.format');

            _ember['default'].run(function () {
                var newValue = _ember['default'].$(event.target).val(),
                    oldValue = _this.get('datetime'),
                    newDatetime = undefined,
                    newDatetimeFormat = undefined,
                    oldDatetimeFormat = undefined;
                if (newValue) {
                    newDatetime = moment(newValue, format).toDate();
                    newDatetimeFormat = moment(newDatetime).format(format);
                }
                if (oldValue) {
                    oldDatetimeFormat = moment(oldValue).format(format);
                }

                if (newDatetimeFormat === oldDatetimeFormat) {
                    return;
                }

                _this.sendAction('action', newDatetime);
            });
        },

        _updateValue: function _updateValue(shouldForceUpdatePicker) {
            var value = undefined,
                datetime = this.get('datetime');
            var format = this.get('options.format');

            if (datetime) {
                value = moment(datetime).format(format);
            } else {
                value = '';
            }

            var el = this.$();
            el.val(value);

            // is only needed for inline, changing value above didn't change the picker
            if (shouldForceUpdatePicker) {
                el.datetimepicker({ value: value });
            }
        }

    });

    exports['default'] = _ember['default'].Component.extend(_oceasoftWebMixinsOutsideClick['default'], {
        tagName: 'oc-datetimepicker',
        classNameBindings: ['round:round', 'opened:opened'],

        round: false,

        placeholder: null,
        required: false,

        value: null,

        opened: false,

        options: {
            defaultSelect: true,
            format: 'MM/DD/YYYY h:mm a',
            formatTime: 'h:mm a',
            formatDate: 'MM/DD/YYYY'
        },

        init: function init() {
            this._super();
            _ember['default'].run.schedule("afterRender", this, function () {
                $(".ember-view .date-time-picker").on("click", function () {
                    /*$(".ember-view .date-time-picker").blur();*/
                });
            });
        },

        formattedPlaceholder: computed('placeholder', 'required', function () {
            var placeholder = this.get('placeholder');

            if (this.get('required')) {
                placeholder += ' *';
            }

            return htmlSafe(placeholder);
        }),

        handleInit: on('didInsertElement', function () {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                var that = this;
                this.$().find('input.date-time-picker').on('keyup keydown paste', function () {
                    that.set('placeholder', null);
                });
            });
        }),

        handleDestroy: on('willDestroyElement', function () {
            this.$().find('input.date-time-picker').off('keyup keydown paste');
        }),

        handleClick: on('click', function () {
            // this.toggleProperty('opened');
            if (this.get('opened')) {
                this.set('opened', false);
                $(".ember-view .date-time-picker").blur();
            } else {
                this.set('opened', true);
            }
        }),

        handleOutsideClick: on('outside-click', function () {
            this.set('opened', false);
        }),

        actions: {
            update: function update(date) {
                this.set('value', date);

                $(".ps-container").scrollTop(0);

                var action = this.get('action');
                if (!isEmpty(action)) {
                    action(date);
                }
            },

            clear: function clear() {
                this.set('value', null);

                var action = this.get('action');
                if (!isEmpty(action)) {
                    action(null);
                }
            }
        }
    });
});