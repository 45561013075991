define("oceasoft-web/session-stores/encrypted-local-storage", ["exports", "ember", "ember-simple-auth/session-stores/base", "oceasoft-web/config/environment", "oceasoft-web/objects/utils", "npm:secure-ls"], function (exports, _ember, _emberSimpleAuthSessionStoresBase, _oceasoftWebConfigEnvironment, _oceasoftWebObjectsUtils, _npmSecureLs) {
  var RSVP = _ember["default"].RSVP;
  var $ = _ember["default"].$;
  var isEmpty = _ember["default"].isEmpty;
  var equals = _oceasoftWebObjectsUtils["default"].Object.equals;
  exports["default"] = _emberSimpleAuthSessionStoresBase["default"].extend({

    key: '_oc-session',

    localStorage: null,

    // region Base

    init: function init() {
      this._super.apply(this, arguments);

      this._initLocalStorage.apply(this);
      this._bindToStorageEvents.apply(this);
    },

    persist: function persist(data) {
      var localStorage = this.get('localStorage');

      this._lastData = data;
      data = JSON.stringify(data || {});
      localStorage.set(this.key, data);

      return RSVP.resolve();
    },

    restore: function restore() {
      var localStorage = this.get('localStorage');

      var data = localStorage.get(this.key);
      return RSVP.resolve(!isEmpty(data) ? JSON.parse(data) : {});
    },

    clear: function clear() {
      var localStorage = this.get('localStorage');

      localStorage.removeAll();
      this._lastData = {};

      return RSVP.resolve();
    },

    // endregion

    // region Utility Functions

    _initLocalStorage: function _initLocalStorage() {
      var lsConfig = {
        encodingType: '',
        isCompression: false
      };

      if (!_oceasoftWebConfigEnvironment["default"].APP.DEBUG) {
        // We only use secure encryption in production builds.
        lsConfig = {
          encodingType: 'aes',
          isCompression: true,
          encryptionSecret: _oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.SECRET_HASH_KEY
        };
      }

      this.set('localStorage', new _npmSecureLs["default"](lsConfig));
    },

    _bindToStorageEvents: function _bindToStorageEvents() {
      var _this = this;

      $(window).bind('storage', function (e) {
        if (e.originalEvent.key === _this.key) {
          _this.restore().then(function (data) {
            if (!equals(data, _this._lastData)) {
              _this._lastData = data;
              _this.trigger('sessionDataUpdated', data);
            }
          });
        }
      });
    }

    // endregion

  });
});