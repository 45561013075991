define("oceasoft-web/mixins/loading-indicator", ["exports", "ember", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsConstants) {

    NProgress.configure({ showSpinner: false });

    var on = _ember["default"].on;

    var Loading = {
        timeout: null,

        // isElementLoaded: function () {
        //     const nprogressEl = $('#nprogress');
        //     return (typeof nprogressEl !== 'undefined' && nprogressEl !== null || nprogressEl.length > 0);
        // },

        show: function show() {
            this.timeout = setTimeout(function () {
                NProgress.start();
            }, _oceasoftWebObjectsConstants["default"].LOADING_INDICATOR_TIMEOUT);
        },

        hide: function hide() {
            clearTimeout(this.timeout);
            NProgress.done();
        }
    };

    exports["default"] = _ember["default"].Mixin.create({

        isActivated: false,

        handleActivate: on('activate', function () {
            this.set('isActivated', true);
        }),

        actions: {

            loading: function loading() {
                var _this = this;

                if (this.get('isActivated')) {
                    Loading.show();
                    this.router.one('didTransition', function () {
                        return _this.send('hideLoading');
                    });
                }
                return true;
            },

            error: function error() {
                if (this.get('isActivated')) {
                    Loading.hide();
                }
                return true;
            },

            showLoading: function showLoading() {
                Loading.show();
            },

            hideLoading: function hideLoading() {
                Loading.hide();
            }

        }
    });
});
/* globals NProgress */