define("oceasoft-web/controllers/authentication/login", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/mixins/window", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebMixinsWindow, _oceasoftWebObjectsUtils) {

    var TAG = 'authentication.login controller';
    var service = _ember["default"].inject.service;
    var computed = _ember["default"].computed;
    var isEmpty = _ember["default"].isEmpty;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;
    var REGEX = _oceasoftWebObjectsConstants["default"].REGEX;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], _oceasoftWebMixinsWindow["default"], {

        logger: service('oc-logger'),
        i18n: service('i18n'),
        session: service('session'),

        showSendPassword: false,

        // region Data

        email: null,
        password: null,

        isValidEmail: computed('email', function () {
            var email = this.get('email');
            return !isEmpty(email) && REGEX.EMAIL.test(email);
        }),

        // region Reset Password Dialog

        dialogTitle: computed('i18n.locale', 'isError', 'isLoading', function () {
            var _getProperties = this.getProperties('i18n', 'isError', 'isLoading');

            var i18n = _getProperties.i18n;
            var isError = _getProperties.isError;
            var isLoading = _getProperties.isLoading;

            if (isLoading) {
                return i18n.t('loading');
            } else if (isError) {
                return this.get('error.title');
            } else {
                return i18n.t('login.reset_password.title');
            }
        }),

        showForgotConfirmation: false,
        isForgotConfirmed: false,

        // endregion

        actions: {

            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');

                this.set('showSendPassword', false);
                this.set('email', null);
                this.set('password', null);
                this.set('showForgotConfirmation', false);
                this.set('isForgotConfirmed', false);
            },

            login: function login() {
                var _this = this;

                this.get('logger').info(TAG, 'login');

                this.send('hideError');

                var _getProperties2 = this.getProperties('email', 'password');

                var email = _getProperties2.email;
                var password = _getProperties2.password;

                var hasError = false;

                // Validate
                if (isEmpty(email)) {
                    this.send('setFieldError', 'email', 'error.empty_field');
                    hasError = true;
                } else if (!REGEX.EMAIL.test(email)) {
                    this.send('setFieldError', 'email', 'login.invalid_email');
                    hasError = true;
                }

                if (isEmpty(password)) {
                    this.send('setFieldError', 'password', 'error.empty_field');
                    hasError = true;
                } else if (password.length < _oceasoftWebObjectsConstants["default"].PASSWORD_LENGTH) {
                    this.send('setFieldError', 'password', 'login.password_length');
                    hasError = true;
                } else if (!REGEX.PASSWORD.test(password)) {
                    this.send('setFieldError', 'password', 'login.invalid_password');
                    hasError = true;
                }

                if (hasError) {
                    return;
                }

                // Send API Call
                this.send('showLoading', false);

                this.get('session').authenticate('authenticator:oc-authenticator', email, _oceasoftWebObjectsUtils["default"].User.passwordHash(password))["catch"](function (reason) {
                    _this.send('hideLoading', false);

                    switch (reason) {
                        default:
                        case Response.FAILED:
                            _this.send('showGeneralNetworkError');
                            break;

                        case Response.NOT_IN_DB_USER:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'error.user_disabled');
                            break;

                        case Response.EXIST_USER_NOT_PRESENT:
                            _this.send('showWarningError', 'login.error', 'error.user_not_enabled');
                            break;

                        case Response.EXIST_USER_WRONG_PASSWORD:
                            _this.send('showWarningError', 'login.error', 'login.error_description');
                            break;
                    }
                });
            },

            forgot: function forgot() {
                var _this2 = this;

                this.get('logger').info(TAG, 'forgot');

                if (!this.get('isForgotConfirmed')) {
                    this.set('showForgotConfirmation', true);
                    return;
                }

                var _getProperties3 = this.getProperties('api');

                var api = _getProperties3.api;

                this.send('hideError');
                this.send('showLoading', false);

                api.lostPassword(this.get('email')).then(function () {
                    _this2.send('hideLoading', false);
                    _this2.send('showError', null, 'check', null, 'login.reset_password.success', 'login.reset_password.success_description', true);
                })["catch"](function (reason) {
                    _this2.send('hideLoading', false);
                    switch (reason) {
                        default:
                        case Response.NOT_IN_DB_USER:
                            _this2.send('showWarningError', 'login.reset_password.invalid_email', 'login.reset_password.invalid_email_description');
                            break;

                        case Response.FAILED:
                            _this2.send('showGeneralNetworkError');
                            break;
                    }
                });
            },

            confirmForgot: function confirmForgot() {
                this.get('logger').info(TAG, 'confirmForgot');

                this.set('isForgotConfirmed', true);
                this.set('showForgotConfirmation', true);

                this.send('forgot');
            },

            rejectForgot: function rejectForgot() {
                this.get('logger').info(TAG, 'rejectForgot');

                this.set('isForgotConfirmed', false);
                this.set('showForgotConfirmation', false);
                this.send('hideError');
                this.send('hideLoading');
            },

            showLoading: function showLoading() {
                this._super.apply(this, arguments);
                this.send('resetContentScroll');
            },

            hideLoading: function hideLoading() {
                if (this.get('isLoading')) {
                    this.send('resetContentScroll');
                }

                this._super.apply(this, arguments);
            }
        }

    });
});