define("oceasoft-web/routes/profiles/new", ["exports", "ember", "ember-i18n", "oceasoft-web/objects/user", "oceasoft-web/objects/constants", "oceasoft-web/mixins/admin-route"], function (exports, _ember, _emberI18n, _oceasoftWebObjectsUser, _oceasoftWebObjectsConstants, _oceasoftWebMixinsAdminRoute) {
    var _slice = Array.prototype.slice;
    var service = _ember["default"].inject.service;
    var UserControllerScope = _oceasoftWebObjectsConstants["default"].UserControllerScope;
    exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], {

        controllerName: 'profiles.users.user',
        titleToken: (0, _emberI18n.translationMacro)('profiles.page.new_user'),

        i18n: service('i18n'),

        model: function model() {
            return _oceasoftWebObjectsUser["default"].create({ language: this.get('i18n.locale') });
        },

        resetController: function resetController(controller) {
            var _super;

            (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));

            controller.send('resetController');
        },

        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            controller.set('scope', UserControllerScope.CREATE);
        },

        renderTemplate: function renderTemplate(controller, model) {
            this.render('profiles.users.user', {
                controller: controller,
                model: model
            });
        }

    });
});