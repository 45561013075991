define('oceasoft-web/components/oc-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-message',
    classNameBindings: ['center', 'important'],

    title: null,
    description: null,
    loading: false,
    icon: null,
    imageClass: null,
    center: true,
    important: false

  });
});