define("oceasoft-web/routes/authentication/register", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Route.extend({

    titleToken: (0, _emberI18n.translationMacro)('register.title'),

    queryParams: {
      email: {
        replace: true
      }
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.send('resetController');
    }

  });
});