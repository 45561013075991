define("oceasoft-web/objects/associate-voucher-response", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/constants", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsConstants, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;

  var AssociateVoucherResponse = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

    init: function init() {
      setDefaultProperties(this, {
        error: _oceasoftWebObjectsConstants["default"].API.Response.OK,
        details: null,
        success: false
      });

      this._super.apply(this, arguments);

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        if (apiResponse.ERROR !== _oceasoftWebObjectsConstants["default"].API.Response.NAN) {
          this.set('error', parseInt(apiResponse.ERROR));
        }

        if (apiResponse.DETAILS !== _oceasoftWebObjectsConstants["default"].API.Response.NAN) {
          this.set('details', apiResponse.DETAILS);
        }

        this.set('success', parseInt(apiResponse.SUCCESS) === 1);

        delete this.apiResponse;
      }
    },

    copy: function copy() {
      return AssociateVoucherResponse.create(this);
    }

  });

  exports["default"] = AssociateVoucherResponse;
});