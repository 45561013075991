define("oceasoft-web/components/oc-recycler", ["exports", "ember", "oceasoft-web/mixins/window"], function (exports, _ember, _oceasoftWebMixinsWindow) {
    var isEmpty = _ember["default"].isEmpty;
    var computed = _ember["default"].computed;
    var A = _ember["default"].A;
    var on = _ember["default"].on;
    var observer = _ember["default"].observer;
    exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsWindow["default"], {
        tagName: "oc-recycler",

        data: null,
        cellOffscreen: 2,

        hasNotifiedLoadMore: false,

        handleLoadMoreNotification: observer('hasNotifiedLoadMore', function () {
            if (this.get('hasNotifiedLoadMore')) {
                this.sendAction('onLoadMoreData');
            }
        }),

        handleDataChange: observer('data', 'infinite', function () {
            var infinite = this.get('infinite');

            if (!infinite) {
                this.send('resetScroll');
            }

            _ember["default"].run.scheduleOnce('afterRender', this, function () {
                this.calculateListHeight.apply(this);
                this.calculateInnerListHeight.apply(this);

                if (this.get('hasNotifiedLoadMore')) {
                    this.set('hasNotifiedLoadMore', false);
                }
            });
        }),

        cellCurrentOffset: 0,
        listHeight: 0,
        cellHeight: 100,

        cellVisible: computed('listHeight', 'cellHeight', 'cellOffscreen', function () {
            var _getProperties = this.getProperties('listHeight', 'cellHeight', 'cellOffscreen');

            var listHeight = _getProperties.listHeight;
            var cellHeight = _getProperties.cellHeight;
            var cellOffscreen = _getProperties.cellOffscreen;

            return Math.ceil(listHeight / cellHeight) + cellOffscreen * 2;
        }),

        cellOffset: computed('cellCurrentOffset', 'cellOffscreen', function () {
            var _getProperties2 = this.getProperties('cellCurrentOffset', 'cellOffscreen');

            var cellCurrentOffset = _getProperties2.cellCurrentOffset;
            var cellOffscreen = _getProperties2.cellOffscreen;

            return cellCurrentOffset < cellOffscreen ? 0 : cellCurrentOffset - cellOffscreen;
        }),

        sliceData: computed('data', 'cellVisible', 'cellCurrentOffset', 'cellOffscreen', function () {
            var _getProperties3 = this.getProperties('data', 'cellVisible', 'cellOffset');

            var data = _getProperties3.data;
            var cellVisible = _getProperties3.cellVisible;
            var cellOffset = _getProperties3.cellOffset;

            if (isEmpty(data)) {
                return new A([]);
            }

            return new A(this.get('data').slice(cellOffset, cellOffset + cellVisible));
        }),

        handleUpdate: on('didUpdate', function () {
            this.calculateListOffset.apply(this);
            this.calculateListCellHeight.apply(this);
        }),

        handleInit: on('didInsertElement', 'resize', function () {
            _ember["default"].run.scheduleOnce('afterRender', this, function () {
                var _this = this;

                this.$('> .list > .scroll > .ps-content').on('scroll', function () {
                    _this.send('onScroll');
                });

                this.calculateListHeight.apply(this);
                this.calculateInnerListHeight.apply(this);
            });
        }),

        handleOnScreenItems: observer('cellHeight', 'listHeight', function () {
            this.calculateListCellHeight.apply(this);
        }),

        calculateListCellHeight: function calculateListCellHeight() {
            this.$('.list-item').css('height', this.get('cellHeight'));
        },

        calculateListHeight: function calculateListHeight() {
            this.set('listHeight', this.$('> .list').height());
        },

        calculateInnerListHeight: function calculateInnerListHeight() {
            var _getProperties4 = this.getProperties('data', 'cellHeight', 'infinite');

            var data = _getProperties4.data;
            var cellHeight = _getProperties4.cellHeight;
            var infinite = _getProperties4.infinite;

            var totalItemsNr = !isEmpty(data) ? data.length : 0;
            if (infinite) {
                totalItemsNr += 1;
            }
            var height = cellHeight * totalItemsNr;

            this.$('.outer-list').css('height', height);
        },

        calculateListOffset: function calculateListOffset() {
            var _getProperties5 = this.getProperties('cellHeight', 'cellOffset');

            var cellHeight = _getProperties5.cellHeight;
            var cellOffset = _getProperties5.cellOffset;

            var innerListEl = this.$('.inner-list');
            var paddingTop = cellHeight * cellOffset;

            if (!isEmpty(innerListEl)) {
                innerListEl.css('padding-top', paddingTop + "px");

                // this.$('.inner-list').css({
                //   '-webkit-transform':'translate3d(0,, '+paddingTop+'px, 0)'
                //   ,'-moz-transform':'translate3d(0,, '+paddingTop+'px, 0)'
                //   ,'transform':'translate3d(0,'+paddingTop+'px, 0)'
                // });
            }
        },

        calculateScrollbar: function calculateScrollbar() {
            var scrollbar = this.$('.scroll .ps-content .ps-scrollbar-y-rail .ps-scrollbar-y');

            if (scrollbar.height() <= 20) {
                scrollbar.css('margin-top', "-" + 20 * (this.$('.scroll .ps-content').scrollTop() / (this.$('.outer-list').height() - this.$('> .list').height())) + "px");
            }
        },

        actions: {

            onScroll: function onScroll() {
                var scrollEl = this.$('.scroll .ps-content');
                if (isEmpty(scrollEl) || scrollEl.length === 0) {
                    return;
                }

                this.set('cellCurrentOffset', Math.floor(scrollEl.scrollTop() / this.get('cellHeight')));

                if (this.get('infinite')) {
                    if (this.$('.outer-list').height() - this.$('> .list').height() - scrollEl.scrollTop() - this.get('cellHeight') <= 0) {
                        this.set('hasNotifiedLoadMore', true);
                    }
                }

                this.calculateScrollbar.call(this);
            },

            resetScroll: function resetScroll() {
                var scrollEl = this.$('.scroll .ps-content');
                if (isEmpty(scrollEl) || scrollEl.length === 0) {
                    return;
                }

                scrollEl.scrollTop(0);
            }

        }

    });
});