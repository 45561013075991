define('oceasoft-web/controllers/pairings/modules', ['exports', 'ember'], function (exports, _ember) {

  var TAG = 'pairings.modules controller';
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var A = _ember['default'].A;
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['q'],

    logger: service('oc-logger'),

    // region Search

    q: null,

    isSearching: computed.notEmpty('q'),

    searchedModules: computed('model', 'q', function () {
      var modules = this.get('model');
      var searchValue = (this.get('q') || "").toLowerCase();

      return modules.filter(function (module) {
        return !isEmpty(module.get('serial')) && module.get('serial').toLowerCase().includes(searchValue) || !isEmpty(module.get('name')) && module.get('name').toLowerCase().includes(searchValue);
      });
    }),

    // endregion

    // region Sorting

    sortProperties: ['name:asc', 'serial:asc'],
    modules: computed.sort('searchedModules', 'sortProperties'),

    // endregion

    actions: {

      resetController: function resetController() {

        this.get('logger').info(TAG, 'resetController');
        this.set('q', null);
      },

      addOrUpdateModule: function addOrUpdateModule(module) {
        this.get('logger').info(TAG, 'addOrUpdateModule');

        var modules = this.get('model');
        var moduleIdx = modules.indexOf(modules.findBy('id', module.get('id')));

        if (moduleIdx > -1) {
          modules[moduleIdx] = module;
        } else {
          modules.push(module);
        }

        this.set('model', new A(modules.slice(0)));
      },

      removeModule: function removeModule(module) {
        this.get('logger').info(TAG, 'removeModule');

        var modules = this.get('model');
        var moduleIdx = modules.indexOf(modules.findBy('id', module.get('id')));

        if (moduleIdx > -1) {
          modules.splice(moduleIdx, 1);
          this.set('model', new A(modules.slice(0)));
        }
      }

    }

  });
});