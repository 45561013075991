define('oceasoft-web/components/oc-toggle', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-toggle',
    classNameBindings: ['isOn:on'],

    isOn: false,

    onLabel: null,
    offLabel: null,

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOn');
      }
    }

  });
});