define('oceasoft-web/components/oc-select', ['exports', 'ember'], function (exports, _ember) {
    var isEmpty = _ember['default'].isEmpty;
    var on = _ember['default'].on;
    var computed = _ember['default'].computed;
    var scheduleOnce = _ember['default'].run.scheduleOnce;
    var htmlSafe = _ember['default'].String.htmlSafe;
    var observer = _ember['default'].observer;
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'oc-select',
        classNameBindings: ['round:round', 'error:error', 'isPlaceholderSelected:placeholder'],

        round: false,

        placeholder: null,
        required: false,

        values: null,
        selectedValue: null,

        disabled: false,
        translationKeyPrefix: null,

        error: null,

        formattedPlaceholder: computed('placeholder', 'required', function () {
            var placeholder = this.get('placeholder');

            if (this.get('required')) {
                placeholder += ' *';
            }

            return htmlSafe(placeholder);
        }),

        handleInit: on('didInsertElement', function () {
            scheduleOnce('afterRender', this, function () {
                this.$('> select').trigger('change');
            });
        }),

        changeValue: observer('selectedValue', function () {
            this.$('> select').val(this.get('selectedValue'));
        }),

        actions: {
            selectValue: function selectValue(value) {
                var _this = this;

                if (isEmpty(value)) {
                    return;
                }

                this.send('removeError');

                this.get('values').forEach(function (v) {
                    if (v.toString() === value && (isEmpty(_this.get('selectedValue')) || _this.get('selectedValue').toString() !== v.toString())) {
                        _this.set('selectedValue', v);

                        var changeAction = _this.get('onChange');
                        if (!isEmpty(changeAction)) {
                            changeAction();
                        }
                    }
                });
            },

            isSelectedValue: function isSelectedValue(value) {
                var selectedValue = this.get('selectedValue');
                var g = !isEmpty(selectedValue) && selectedValue.toString() === value.toString();

                console.log('value: ' + value + ' - selectedValue: ' + selectedValue + ': ' + g);

                return g;
            },

            removeError: function removeError() {
                this.set('error', null);
            }
        }
    });
});