define("oceasoft-web/components/oc-list", ["exports", "ember", "oceasoft-web/mixins/remove-empty-yields"], function (exports, _ember, _oceasoftWebMixinsRemoveEmptyYields) {
    var on = _ember["default"].on;
    var isEmpty = _ember["default"].isEmpty;
    var $ = _ember["default"].$;
    var later = _ember["default"].run.later;
    exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsRemoveEmptyYields["default"], {
        tagName: 'oc-list',

        title: null,

        checkEmptyElements: ['> .header', '> .list'],

        header: { isHeader: true },
        list: { isList: true },

        selectedID: null,

        handleSelectedItem: on('didRender', 'didInsertElement', 'didUpdate', function () {
            _ember["default"].run.scheduleOnce('afterRender', this, function () {
                var _this = this;

                this.set('_elementHeight', this.$().height());

                this.$().on('scroll-update', function () {
                    _this.set('_elementHeight', _this.$().height());
                    _this.calculateActiveScrollPosition.call(_this);

                    later(function () {
                        return _this.calculateActiveScrollPosition.call(_this);
                    }, 250);
                });

                $('body').on('click', 'oc-list-item', function () {
                    later(function () {
                        if (isEmpty(_this.$())) {
                            return;
                        }

                        var newHeight = _this.$().height();
                        var oldHeight = _this.get('_elementHeight') || 0;

                        if (newHeight !== oldHeight) {
                            _this.set('_elementHeight', newHeight);

                            _this.calculateActiveScrollPosition.call(_this);
                        }
                    }, 100);
                });

                this.calculateActiveScrollPosition.call(this);
            });
        }),

        handleDestroy: on('willDestroyElement', function () {
            // this.$('oc-list-item').off('click');
            this.$().off('scroll-update');
        }),

        calculateActiveScrollPosition: function calculateActiveScrollPosition() {
            var selectedID = this.get('selectedID');

            var scrollListEl = this.$('.scroll > .ps-content');
            var scrollToEl = isEmpty(selectedID) ? this.$('oc-list-item.active') : this.$("oc-list-item[id=\"" + selectedID + "\"]");

            // only scroll if there is a selected id of a single active element.
            if (!isEmpty(scrollToEl) && scrollToEl.length === 1) {

                if (scrollToEl.parent().parent().is('oc-list-item.expandable') && !scrollToEl.parent().parent().hasClass('expanded')) {
                    scrollToEl.parent().parent().addClass('expanded');
                }

                scrollListEl.scrollTop(Math.round(scrollToEl.offset().top + scrollListEl.scrollTop() - scrollListEl.offset().top));
            }
        }

    });
});