define('oceasoft-web/routes/pairings/modules/module', ['exports', 'ember'], function (exports, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  var typeOf = _ember['default'].typeOf;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({

    api: service('oc-api'),

    titleToken: function titleToken(model) {
      if (isEmpty(model) || typeOf(model) !== 'instance' || isEmpty(model.get('serial'))) {
        return null;
      }

      return this.get('i18n').t('pairings.page.module_sn', {
        serial: model.get('serial')
      });
    },

    model: function model(params) {
      return this.modelFor('pairings.modules').findBy('id', params.id);
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.send('resetController');
    }

  });
});