define("oceasoft-web/controllers/profiles/users/user", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/mixins/editable-controller", "oceasoft-web/mixins/session-manager", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebMixinsEditableController, _oceasoftWebMixinsSessionManager, _oceasoftWebObjectsUtils) {

    var TAG = 'profiles.users.user controller';
    var _Ember$inject = _ember["default"].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var computed = _ember["default"].computed;
    var A = _ember["default"].A;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;
    var REGEX = _oceasoftWebObjectsConstants["default"].REGEX;
    var UserControllerScope = _oceasoftWebObjectsConstants["default"].UserControllerScope;
    var _Constants$User = _oceasoftWebObjectsConstants["default"].User;
    var Level = _Constants$User.Level;
    var TimeFormat = _Constants$User.TimeFormat;
    var DateFormat = _Constants$User.DateFormat;
    var TemperatureUnit = _oceasoftWebObjectsConstants["default"].TemperatureUnit;
    var DecimalSeparator = _oceasoftWebObjectsConstants["default"].DecimalSeparator;
    var isEmpty = _oceasoftWebObjectsUtils["default"].String.isEmpty;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], _oceasoftWebMixinsEditableController["default"], _oceasoftWebMixinsSessionManager["default"], {

        logger: service('oc-logger'),
        api: service('oc-api'),
        i18n: service('i18n'),
        notify: service('notify'),

        usersController: controller('profiles.users'),

        scope: null,

        dialogTitle: computed('i18n.locale', 'isError', 'isLoading', function () {
            var _getProperties = this.getProperties('i18n', 'isError', 'isLoading');

            var i18n = _getProperties.i18n;
            var isError = _getProperties.isError;
            var isLoading = _getProperties.isLoading;

            if (isLoading) {
                return i18n.t('loading');
            } else if (isError) {
                return this.get('error.title');
            } else {
                return i18n.t('confirmation');
            }
        }),

        showDeleteConfirmation: false,
        isDeleteConfirmed: false,

        levels: computed('model', function () {
            return new A(Object.keys(Level).map(function (k) {
                return Level[k];
            }));
        }).readOnly(),

        timeFormats: computed(function () {
            return new A(Object.keys(TimeFormat).map(function (k) {
                return TimeFormat[k];
            }));
        }).readOnly(),

        dateFormats: computed(function () {
            return new A(Object.keys(DateFormat).map(function (k) {
                return DateFormat[k];
            }));
        }).readOnly(),

        temperatureUnits: computed(function () {
            return new A(Object.keys(TemperatureUnit).map(function (k) {
                return TemperatureUnit[k];
            }));
        }).readOnly(),

        decimalSeparators: computed(function () {
            return new A(Object.keys(DecimalSeparator).map(function (k) {
                return DecimalSeparator[k];
            }));
        }).readOnly(),

        actions: {
            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');

                this.set('showDeleteConfirmation', false);
                this.set('isDeleteConfirmed', false);
            },

            resetModel: function resetModel() {
                this.set('password', null);
                this.set('confirmPassword', null);

                this._super.apply(this, arguments);
            },

            save: function save() {
                var _this = this;

                this.get('logger').info(TAG, 'save');

                var i18n = this.get('i18n');
                var user = this.get('model');
                var scope = this.get('scope');
                var password = this.get('model.plainPassword');
                var confirmPassword = this.get('model.plainPasswordConfirm');

                var hasError = false;

                // Validate Email
                if (isEmpty(user.get('email'))) {
                    this.send('setFieldError', 'email', 'error.empty_field');
                    hasError = true;
                } else if (!REGEX.EMAIL.test(user.get('email'))) {
                    this.send('setFieldError', 'email', 'login.invalid_email');
                    hasError = true;
                }

                if (isEmpty(user.get('firstName'))) {
                    this.send('setFieldError', 'firstName', 'error.empty_field');
                    hasError = true;
                }

                if (isEmpty(user.get('lastName'))) {
                    this.send('setFieldError', 'lastName', 'error.empty_field');
                    hasError = true;
                }

                // Validate Passwords
                var hasPasswordError = false;
                if (isEmpty(password)) {
                    this.send('setFieldError', 'password1', 'error.empty_field');
                    hasError = true;
                    hasPasswordError = true;
                } else if (password.length < _oceasoftWebObjectsConstants["default"].PASSWORD_LENGTH) {
                    this.send('setFieldError', 'password1', 'login.password_length');
                    hasError = true;
                    hasPasswordError = true;
                } else if (!REGEX.PASSWORD.test(password)) {
                    this.send('setFieldError', 'password1', 'login.invalid_password');
                    hasError = true;
                    hasPasswordError = true;
                }

                if (isEmpty(confirmPassword)) {
                    this.send('setFieldError', 'password2', 'error.empty_field');
                    hasError = true;
                } else if (confirmPassword.length < _oceasoftWebObjectsConstants["default"].PASSWORD_LENGTH) {
                    this.send('setFieldError', 'password2', 'login.password_length');
                    hasError = true;
                } else if (!REGEX.PASSWORD.test(confirmPassword)) {
                    this.send('setFieldError', 'password2', 'login.invalid_password');
                    hasError = true;
                } else if (!hasPasswordError && password !== confirmPassword) {
                    this.send('setFieldError', 'password2', 'error.non_matching_passwords');
                    hasError = true;
                }

                if (hasError) {
                    return;
                }

                this.send('hideError');
                this.send('showLoading');

                switch (scope) {
                    case UserControllerScope.CREATE:
                        this.get('logger').info(TAG, 'save - create');

                        user.set('password', _oceasoftWebObjectsUtils["default"].User.encrypt(password));

                        this.get('api').addUser(user).then(function () {
                            user.set('plainPassword', "");
                            user.set('plainPasswordConfirm', "");

                            _this.send('hideLoading');
                            _this.send('saveModel');

                            _this.get('notify').success(i18n.t('profiles.page.user_added_msg', { fullName: user.get('fullName') }).toString());
                            _this.transitionToRoute('profiles.users.user', user.get('id'));
                        })["catch"](function (reason) {
                            _this.send('hideLoading');

                            switch (reason) {
                                default:
                                case Response.FAILED:
                                    _this.send('showGeneralNetworkError');
                                    break;

                                case Response.ALREADY_EXIST:
                                    _this.send('showWarningError', 'error.unable_to_complete_operation', 'profiles.page.error.add.already_exists_description');
                                    _this.send('setFieldError', 'email', 'register.error_already_exists_field');
                                    break;

                                case Response.NOT_IN_DB_USER:
                                case Response.USER_IS_NOT_ADMIN:
                                    break;
                            }

                            _this.send('handleUserAPIErrors', reason);
                        });
                        break;

                    case UserControllerScope.EDIT:
                        this.get('logger').info(TAG, 'save - edit');

                        user.set('password', _oceasoftWebObjectsUtils["default"].User.passwordHash(password));
                        this.get('api').changeUser(user).then(function () {
                            user.set('plainPassword', "");
                            user.set('plainPasswordConfirm', "");

                            _this.send('hideLoading');
                            _this.send('saveModel');

                            _this.get('notify').success(i18n.t('profiles.page.user_modified_msg', { fullName: user.get('fullName') }).toString());
                            _this.get('usersController').send('addOrUpdateUser', user);
                        })["catch"](function (reason) {
                            _this.send('hideLoading');

                            switch (reason) {
                                default:
                                case Response.FAILED:
                                    _this.send('showGeneralNetworkError');
                                    break;

                                case Response.NOT_IN_DB_USER:
                                case Response.USER_IS_NOT_ADMIN:
                                    break;
                            }

                            _this.send('handleUserAPIErrors', reason);
                        });
                        break;

                    case UserControllerScope.ME:
                        this.get('logger').info(TAG, 'save - me');

                        var newPwdHash = _oceasoftWebObjectsUtils["default"].User.passwordHash(password);
                        this.get('api').updateUser(user, newPwdHash).then(function () {
                            user.set('password', newPwdHash);
                            user.set('plainPassword', "");
                            user.set('plainPasswordConfirm', "");

                            _this.send('hideLoading');
                            _this.send('saveModel');

                            _this.get('notify').success(i18n.t('profiles.page.profile_modified').toString());
                            _this.send('notifySessionDataChanged');
                        })["catch"](function (reason) {
                            _this.send('hideLoading');

                            switch (reason) {
                                default:
                                case Response.FAILED:
                                    _this.send('showGeneralNetworkError');
                                    break;

                                case Response.NOT_IN_DB_USER:
                                case Response.USER_IS_NOT_ADMIN:
                                    break;
                            }

                            _this.send('handleUserAPIErrors', reason);
                        });
                        break;
                }
            },

            remove: function remove() {
                var _this2 = this;

                this.get('logger').info(TAG, 'remove');

                if (this.get('scope') !== UserControllerScope.EDIT) {
                    return;
                }

                if (!this.get('isDeleteConfirmed')) {
                    this.set('showDeleteConfirmation', true);
                    return;
                }

                var _getProperties2 = this.getProperties('model', 'i18n');

                var model = _getProperties2.model;
                var i18n = _getProperties2.i18n;

                model.set('enabled', false);

                this.send('hideError');
                this.send('showLoading', false);

                this.get('api').changeUser(model).then(function () {
                    _this2.get('notify').success(i18n.t('profiles.page.user_removed', { fullName: model.get('fullName') }).toString());
                    _this2.get('usersController').send('removeUser', model);
                    _this2.transitionToRoute('profiles.users.index');
                })["catch"](function (reason) {
                    _this2.send('hideLoading', false);

                    switch (reason) {
                        default:
                        case Response.FAILED:
                            _this2.send('showGeneralNetworkError');
                            break;
                    }

                    _this2.send('handleUserAPIErrors', reason);
                });
            },

            confirmRemove: function confirmRemove() {
                this.get('logger').info(TAG, 'confirmRemove');

                this.set('isDeleteConfirmed', true);
                this.set('showDeleteConfirmation', true);

                this.send('remove');
            },

            rejectRemove: function rejectRemove() {
                this.get('logger').info(TAG, 'rejectRemove');

                this.set('isDeleteConfirmed', false);
                this.set('showDeleteConfirmation', false);
                this.send('hideError');
                this.send('hideLoading');
            },

            setLocale: function setLocale(locale) {
                this.get('logger').info(TAG, 'setLocale');

                if (!isEmpty(locale)) {
                    this.set('model.language', locale);

                    if (this.get('scope') === UserControllerScope.ME) {
                        this.set('i18n.locale', locale);
                    }
                }
            },

            getTimeFormatTranslation: function getTimeFormatTranslation(timeFormat) {
                switch (timeFormat) {
                    default:
                    case TimeFormat.NORMAL:
                        {
                            return 'timeFormat.type.1';
                        }

                    case TimeFormat.MILITARY:
                        {
                            return 'timeFormat.type.0';
                        }
                }
            },

            getDecimalSeparatorTranslation: function getDecimalSeparatorTranslation(decimalSeparator) {
                switch (decimalSeparator) {
                    case DecimalSeparator.POINT:
                        {
                            return 'decimalSeparator.type.0';
                        }

                    case DecimalSeparator.COMMA:
                        {
                            return 'decimalSeparator.type.1';
                        }
                }
            }
        }

    });
});