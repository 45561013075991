define('oceasoft-web/helpers/timestamp', ['exports', 'ember'], function (exports, _ember) {
  exports.timestamp = timestamp;
  var isEmpty = _ember['default'].isEmpty;

  function timestamp(params) {
    var date = new Date(params[0]);

    if (isEmpty(date)) {
      return null;
    }

    return date.getTime();
  }

  exports['default'] = _ember['default'].Helper.helper(timestamp);
});