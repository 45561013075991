define("oceasoft-web/components/oc-stage", ["exports", "ember", "oceasoft-web/mixins/window", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebMixinsWindow, _oceasoftWebObjectsUtils) {
  var $ = _ember["default"].$;
  var isEmpty = _ember["default"].isEmpty;
  var observer = _ember["default"].observer;
  var once = _ember["default"].run.once;
  var observeMutation = _oceasoftWebObjectsUtils["default"].Component.observeMutation;
  exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsWindow["default"], {
    tagName: 'oc-stage',

    handleMutationChange: observeMutation(function () {
      once(this, 'repositionLogo');
    }),

    handleSmall: observer('window.isSmallOnly', function () {
      once(this, 'repositionLogo');
    }),

    repositionLogo: function repositionLogo() {
      var isSmallOnly = this.get('window.isSmallOnly');
      var topBarEl = $('oc-topbar');
      var topBarLeftEl = $('oc-topbar > .left');
      var sideBarEl = this.$('oc-sidebar');
      var lastSideBarEl = !isEmpty(sideBarEl) && sideBarEl.length > 0 ? $(sideBarEl[sideBarEl.length - 1]) : null;

      var newLogoLeftOffset = !isEmpty(lastSideBarEl) && lastSideBarEl.size() === 1 && !isSmallOnly ? Math.round(lastSideBarEl.offset().left + lastSideBarEl.outerWidth() - this.$().offset().left) : 0;
      topBarLeftEl.css({ 'margin-left': newLogoLeftOffset + 'px' });

      if (!isEmpty(sideBarEl) && sideBarEl.length >= 2) {
        topBarEl.addClass('resizeLogo');
      } else {
        topBarEl.removeClass('resizeLogo');
      }
    }

  });
});