define('oceasoft-web/initializers/oc-api', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    ['route', 'controller'].forEach(function (component) {
      app.inject(component, 'api', 'service:oc-api');
    });
  }

  exports['default'] = {
    name: 'oc-api',
    after: 'oc-logger',
    initialize: initialize
  };
});