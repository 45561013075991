define("oceasoft-web/components/oc-center", ["exports", "ember", "oceasoft-web/mixins/window", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebMixinsWindow, _oceasoftWebObjectsUtils) {
  var later = _ember["default"].run.later;
  var computed = _ember["default"].computed;
  var on = _ember["default"].on;
  var $ = _ember["default"].$;
  var isEmpty = _ember["default"].isEmpty;
  var observeMutation = _oceasoftWebObjectsUtils["default"].Component.observeMutation;
  exports["default"] = _ember["default"].Component.extend(_oceasoftWebMixinsWindow["default"], {
    tagName: 'oc-center',
    classNameBindings: ['shouldCenterVertical:vertical', 'shouldCenterHorizontal:horizontal'],

    vertical: false,
    horizontal: false,

    verticalMin: false,
    horizontalMin: false,

    shouldCenterVertical: computed('vertical', 'verticalMin', function () {
      return this.get('vertical') && !this.get('verticalMin');
    }),

    shouldCenterHorizontal: computed('horizontal', 'horizontalMin', function () {
      return this.get('horizontal') && !this.get('horizontalMin');
    }),

    handleMutation: observeMutation(function () {
      later(this, this.calculateAxis, 50);
    }),

    handleInitAndResize: on('didInsertElement', 'resize', function () {
      later(this, this.calculateAxis, 50);
    }),

    calculateAxis: function calculateAxis() {
      if (isEmpty($(this.element))) {
        return;
      }

      this.set('verticalMin', $(window).height() <= $(this.element).outerHeight());
      this.set('horizontalMin', $(window).width() <= $(this.element).outerWidth());
    }

  });
});