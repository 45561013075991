define('oceasoft-web/helpers/constant', ['exports', 'ember', 'oceasoft-web/objects/constants'], function (exports, _ember, _oceasoftWebObjectsConstants) {
  exports.constant = constant;

  function constant(params) {
    var constantFieldName = params[0];

    var parts = constantFieldName.split('.');
    var property = _oceasoftWebObjectsConstants['default'];

    for (var i = 0; i < parts.length; i++) {
      property = property[parts[i]];
    }

    return property;
  }

  exports['default'] = _ember['default'].Helper.helper(constant);
});