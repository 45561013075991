define("oceasoft-web/controllers/pairings/modules/module", ["exports", "ember", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebObjectsConstants) {

  var TAG = 'pairings.modules.module controller';
  var _Ember$inject = _ember["default"].inject;
  var service = _Ember$inject.service;
  var controller = _Ember$inject.controller;
  var computed = _ember["default"].computed;
  var Response = _oceasoftWebObjectsConstants["default"].API.Response;
  exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], {

    logger: service('oc-logger'),
    api: service('oc-api'),
    notify: service('notify'),

    modulesController: controller('pairings.modules'),

    dialogTitle: computed('i18n.locale', 'isError', 'isLoading', function () {
      var _getProperties = this.getProperties('i18n', 'isError', 'isLoading');

      var i18n = _getProperties.i18n;
      var isError = _getProperties.isError;
      var isLoading = _getProperties.isLoading;

      if (isLoading) {
        return i18n.t('loading');
      } else if (isError) {
        return this.get('error.title');
      } else {
        return i18n.t('confirmation');
      }
    }),

    showDeleteConfirmation: false,
    isDeleteConfirmed: false,

    actions: {

      resetController: function resetController() {
        this._super.apply(this, arguments);
        this.get('logger').info(TAG, 'resetController');

        this.set('showDeleteConfirmation', false);
        this.set('isDeleteConfirmed', false);
      },

      remove: function remove() {
        var _this = this;

        this.get('logger').info(TAG, 'remove');

        if (!this.get('isDeleteConfirmed')) {
          this.set('showDeleteConfirmation', true);
          return;
        }

        var _getProperties2 = this.getProperties('model', 'i18n');

        var model = _getProperties2.model;
        var i18n = _getProperties2.i18n;

        this.send('hideError');
        this.send('showLoading', false);

        this.get('api').removeEmerald(model).then(function () {
          _this.send('notifySessionDataChanged');
          _this.get('notify').success(i18n.t('pairings.page.module_removed_msg').toString());
          _this.get('modulesController').send('removeModule', model);
          _this.transitionToRoute('pairings.modules');
        })["catch"](function (reason) {
          _this.send('hideLoading', false);

          switch (reason) {
            default:
            case Response.FAILED:
              _this.send('showGeneralNetworkError');
              break;

            case Response.INVALID_HASHCODE:
              _this.send('showWarningError', 'pairings.page.error_remove', 'pairings.page.error_remove_description');
              break;

            case Response.NOT_IN_DB_USER:
            case Response.USER_IS_NOT_ADMIN:
              break;
          }

          _this.send('handleUserAPIErrors', reason);
        });
      },

      confirmRemove: function confirmRemove() {
        this.get('logger').info(TAG, 'confirmRemove');

        this.set('isDeleteConfirmed', true);
        this.set('showDeleteConfirmation', true);

        this.send('remove');
      },

      rejectRemove: function rejectRemove() {
        this.get('logger').info(TAG, 'rejectRemove');

        this.set('isDeleteConfirmed', false);
        this.set('showDeleteConfirmation', false);
        this.send('hideError');
        this.send('hideLoading');
      }

    }

  });
});