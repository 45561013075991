define('oceasoft-web/mixins/application-content-scroll-route', ['exports', 'ember'], function (exports, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  var $ = _ember['default'].$;
  exports['default'] = _ember['default'].Mixin.create({
    actions: {

      willTransition: function willTransition() {
        this.send('resetContentScroll');
        this._super.apply(this, arguments);
      },

      resetContentScroll: function resetContentScroll() {
        var el = $('oc-content > .scroll > .ps-content');
        if (!isEmpty(el)) {
          el.scrollTop(0);
          el.scrollLeft(0);
        }
      }

    }
  });
});