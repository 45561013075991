define('oceasoft-web/routes/authentication', ['exports', 'ember', 'oceasoft-web/mixins/root-route'], function (exports, _ember, _oceasoftWebMixinsRootRoute) {
  exports['default'] = _ember['default'].Route.extend(_oceasoftWebMixinsRootRoute['default'], {
    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel() {

      if (this.get('session.isAuthenticated')) {
        this.transitionTo('index');
      }
      this._super.apply(this, arguments);
    }

  });
});