define("oceasoft-web/mixins/editable-controller", ["exports", "ember", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsUtils) {
  var observer = _ember["default"].observer;
  var isEmpty = _ember["default"].isEmpty;
  var copy = _ember["default"].copy;
  var observeTree = _oceasoftWebObjectsUtils["default"].Property.observeTree;
  var _Utils$Object = _oceasoftWebObjectsUtils["default"].Object;
  var copyProperties = _Utils$Object.copyProperties;
  var equals = _Utils$Object.equals;
  exports["default"] = _ember["default"].Mixin.create({

    isModelChanged: false,
    _oldModel: null,

    handleModel: observer('model', function () {
      if (isEmpty(this.get('_oldModel'))) {
        this.send('saveModel');
      }
    }),

    handleModelChange: observeTree('model', function () {
      if (isEmpty(this.get('_oldModel'))) {
        this.send('saveModel');
      } else {
        this.set('isModelChanged', !equals(this.get('model'), this.get('_oldModel')));
      }
    }),

    actions: {

      resetController: function resetController() {
        this._super.apply(this, arguments);

        this.send('resetModel', true);
        this.set('model', null);
      },

      saveModel: function saveModel() {
        this.set('_oldModel', copy(this.get('model')));
        this.set('isModelChanged', false);
      },

      resetModel: function resetModel() {
        var clear = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        // Instead of resetModel a new instance of the same object and removing mapping with
        // other views we copy each of the object's values in the original instance.
        copyProperties(this.get('_oldModel'), this.get('model'));

        if (clear) {
          this.set('_oldModel', null);
        }
        this.set('isModelChanged', false);
      }

    }

  });
});