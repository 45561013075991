define('oceasoft-web/components/oc-collapse-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-collapse-button',

    disabled: false,
    expanded: false,

    // TODO: Implement the label. For the moment it's not used so there was no need of implementing it.
    label: null,

    click: function click() {
      if (this.get('disabled')) {
        return;
      }

      this.sendAction('action');
    }

  });
});