define("oceasoft-web/routes/profiles/users/user", ["exports", "ember", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsConstants) {
  var _slice = Array.prototype.slice;
  var UserControllerScope = _oceasoftWebObjectsConstants["default"].UserControllerScope;
  var service = _ember["default"].inject.service;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = _ember["default"].Route.extend({

    api: service('oc-api'),
    session: service('session'),

    beforeModel: function beforeModel(transition) {
      var userID = transition.params['profiles.users.user'].id;

      if (!isEmpty(userID)) {
        if (this.get('session.data.authenticated.session.user.id') === userID) {
          this.transitionTo('profiles.me');
        }
      }
    },

    model: function model(params) {
      return this.modelFor('profiles.users').findBy('id', params.id);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('scope', UserControllerScope.EDIT);
    },

    resetController: function resetController(controller) {
      var _super;

      (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));

      controller.send('resetController');
    }

  });
});