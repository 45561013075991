define('oceasoft-web/components/oc-user-menu', ['exports', 'ember', 'oceasoft-web/mixins/window', 'oceasoft-web/mixins/outside-click', 'oceasoft-web/mixins/session-manager'], function (exports, _ember, _oceasoftWebMixinsWindow, _oceasoftWebMixinsOutsideClick, _oceasoftWebMixinsSessionManager) {
  var service = _ember['default'].inject.service;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend(_oceasoftWebMixinsWindow['default'], _oceasoftWebMixinsOutsideClick['default'], _oceasoftWebMixinsSessionManager['default'], {

    session: service('session'),

    tagName: 'oc-user-menu',
    classNameBindings: ['isOpened:opened'],

    isOpened: false,

    handleOutsideClick: on('outside-click', function () {
      if (this.get('isOpened')) {
        this.send('toggle');
      }
    }),

    handleWindowResize: on('resize', 'didUpdate', function () {
      var element = _ember['default'].$(this.element);
      var listContainerElement = element.find('.menu .list-container');

      var listScrollHeight = this.get('window.height') - element.offset().top - 120;
      var listHeight = 0;
      listContainerElement.find('.menu-item').each(function (i, e) {
        listHeight += _ember['default'].$(e).outerHeight();
      });

      listContainerElement.height(listScrollHeight > listHeight ? listHeight : listScrollHeight);
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpened');
      },

      logout: function logout() {
        this.sendAction('onLogout');
      }
    }
  });
});