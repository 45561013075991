define("oceasoft-web/controllers/about/guide", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebObjectsUtils) {
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Controller.extend({

    i18n: service('i18n'),

    actions: {

      download: function download() {
        var locale = this.get('i18n.locale').toUpperCase();

        var url = _oceasoftWebObjectsConstants["default"].URL_ABOUT_USER_GUIDE.OTHER;
        if (_oceasoftWebObjectsUtils["default"].Object.keys(_oceasoftWebObjectsConstants["default"].URL_ABOUT_USER_GUIDE).indexOf(locale) > 0) {
          url = _oceasoftWebObjectsConstants["default"].URL_ABOUT_USER_GUIDE[locale];
        }

        window.open(url, '_blank').focus();
      }

    }

  });
});