define('oceasoft-web/objects/constants', ['exports', 'ember'], function (exports, _ember) {
    var A = _ember['default'].A;
    exports['default'] = _ember['default'].Object.extend({
        Host: {
            AMERICAS: 0,
            EMEA: 1,
            APAC: 2
        },

        Error: {
            Type: {
                ALERT: 'alert',
                WARNING: 'warning',
                FATAL: 'fatal',
                NETWORK: 'network'
            },

            NOT_FOUND: 'not-found',
            GENERAL_ERROR: 'error'
        },

        LOADING_INDICATOR_TIMEOUT: 100,

        API: {
            GENERAL_DELAY: 200,

            TIME_FORMAT: 'HH:mm:ss',
            DATE_FORMAT: 'DD/MM/YYYY',
            DATETIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',

            NULL_DATE: new Date(1899, 11, 30),

            Response: {
                ALREADY_EXIST: -3,
                ALREADY_EXIST_CUSTOMER: -4,
                CLOUD_NOT_ALLOWED: 17,
                DB_CONNECTION_FAILED: -10,
                FAILED: -11,
                INVALID_HASHCODE: -13,
                INVALID_DEVICE: -21,
                INVALID_VOUCHER: -16,
                INVALID_VOUCHER_DATE: -19,
                INVALID_VOUCHER_SPACE: -20,
                KO: -1,
                MISSION_ARCHIVED: 2,
                MISSION_IN_PROGRESS: 1,
                MISSION_NOT_EXIST: -1,
                MISSION_START_EVT: 1,
                MISSION_STOP_EVT: -1,
                MISSION_STOPPED: 0,
                NAN: "NAN",
                NEW_MISSION: 2,
                NEW_USER: 2,
                NO_PLACE: -6,
                NO_ALARM: "N",
                NONE: "None",
                NOT_IN_DB_CUSTOMER: -12,
                NOT_IN_DB_MISSION: -6,
                NOT_IN_DB_SENSOR: -8,
                NOT_IN_DB_USER: -9,
                NOT_IN_DB_VOUCHER: -7,
                OK: 1,
                SENSOR_EXPIRED: -15,
                SENSOR_AVAILABLE: 2,
                UPDATE_MISSION: 1,
                UPDATE_USER: 1,
                USER_IS_NOT_ADMIN: -5,
                VOUCHER_ALREADY_USED: -2,
                WRONG_COMPANY_ID: -14,
                WRONG_CUSTOMER_ID: -4,
                WRONG_PWD: -1,

                EXIST_USER_NOT_PRESENT: 0,
                EXIST_USER_WRONG_PASSWORD: 1,
                EXIST_USER_OK: 2
            }
        },

        REGEX: {
            EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            PASSWORD: /^[0-9a-zA-ZàâäéèêëîïòôöùûüÀÁÂÄÉÈÊËÏÎÒÔÖÙÛÜ\-/ :;()€&@"\[\]{}#%¦^*+=_\\│~<>$£¥·.,?!’]{6,}$/,
            MODULE_SERIAL: /(E[0-9A-Z]{1}0[012489ACE]{1}[0-9A-Z]{1}[0-3]{1}[0-9A-Z]{6})$/
        },

        PASSWORD_LENGTH: 6,
        POSTAL_CODE_LENGTH: 50,

        ATLAS_VOUCHER_SERIAL: 'ATLAS',

        VoucherType: {
            EMERALD_AND_ATALS: 0,
            ATLAS_ONLY: 1
        },

        URL_ABOUT_USER_GUIDE: {
            OTHER: "http://temptimecorp.com/temperature-indicators-sensors/edge-electronic-sensors/edgevue-app/",
            FR: "http://temptimecorp.com/temperature-indicators-sensors/edge-electronic-sensors/edgevue-app/"
        },

        URL_ACCESSORIES_GUIDE: "http://temptimecorp.com/temperature-indicators-sensors/edge-electronic-sensors/",

        UserControllerScope: {
            CREATE: 0,
            EDIT: 1,
            ME: 2
        },

        User: {
            Level: {
                ADMINISTRATOR: 1,
                USER: 0
            },

            TimeFormat: {
                NORMAL: 'h:mm:ss a',
                MILITARY: 'HH:mm:ss'
            },

            DateFormat: {
                MDY: 'MM/DD/YYYY',
                DMY: 'DD/MM/YYYY'
            }
        },

        // maximum number of items (modules + missions) that can be displayed by the application
        maxNumberOfListedItems: 1000,

        Mission: {
            EventType: {
                UNKNOWN_EVENT: -1,
                MISSION_PROGRAMMED: 0,
                DATALOGGING_STARTED: 1,
                MODULE_CONNECTED: 2,
                READINGS_DOWNLOADED: 3,
                MISSION_STOPPED: 4,
                ALARM_RESET: 5,

                UNKNOWN_ALARM: -10,
                HIGH_LIMIT_ALARM: 10,
                LOW_LIMIT_ALARM: 11,
                SENSOR_FAILED_ALARM: 12,
                LOW_BATTERY_ALARM: 13,
                OUT_OF_RANGE_ALARM: 14,

                PROGRAMMED_ON_BENCH: 20,
                MISSION_GEOLOCATED: 21
            },

            DataType: {
                SAVED_DATA: 0,
                LIVE_DATA: 1
            },

            State: {
                ANY: -1,
                STOPPED: 0,
                ACTIVE: 1,
                STOPPED_AND_ARCHIVED: 2
            },

            MULTIVIEW_LIMIT: 20
        },

        Module: {
            State: {
                ALARMS: 0,
                NO_ALARMS: 1,
                TECHNICAL_ALARMS: 2,
                NO_MISSIONS: 3
            },

            Type: {
                UNKNOWN: 0,
                EMERALD: 1,
                ATLAS: 2,
                EMERALD2: 3,
                ATLAS2: 4,
                ATLAS2plus: 5,
                X2: 6
            },

            MissionSearch: {
                NO_MISSION: 0,
                MISSION_WITH_ALARMS: 1,
                MISSION_WITHOUT_ALARMS: 2,
                STOPPED_ENDED_MISSIONS: 3,
                ALL_MISSIONS: 4
            },

            MissionAlarmSearch: {
                ALL_ALARMS: 0,
                ON_ALARM: 1,
                NO_ALARM_IN_PROGRESS: 2
            },

            MissionTimePeriod: {
                //ALL: 0,
                LAST_DAY: 1,
                LAST_WEEK: 2,
                LAST_MONTH: 3,
                CALENDAR: 4
            },

            MissionFilter: {
                ALL_MISSIONS: 0,
                NO_ALARMS_DURING_MISSION: 1,
                ALARMS_OCCURRED: 2
            }
        },

        TemperatureUnit: {
            CELSIUS: 0,
            FAHRENHEIT: 1
        },

        DecimalSeparator: {
            POINT: '.',
            COMMA: ','
        },

        ModuleViewType: {
            STATISTICAL: 0,
            MAP: 1
        },

        Country: new A(['AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AC', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BR', 'IO', 'VG', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'IC', 'CV', 'BQ', 'KY', 'CF', 'EA', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DG', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'XK', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'KP', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'KR', 'SS', 'ES', 'LK', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TA', 'TN', 'TR', 'TM', 'TC', 'TV', 'UM', 'VI', 'UG', 'UA', 'AE', 'GB', 'US', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'WF', 'EH', 'YE', 'ZM', 'ZW']),

        LocaleFlags: {
            EN: 'gb',
            FR: 'fr',
            ES: 'es'
        }

    }).create();
});