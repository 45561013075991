define('oceasoft-web/components/oc-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-button',
    classNameBindings: ['primary', 'disabled', 'icon:icon'],

    primary: false,
    disabled: false,

    label: null,
    icon: null,

    click: function click() {
      if (this.get('disabled')) {
        return;
      }

      this.sendAction('action');
    }
  });
});