define('oceasoft-web/components/oc-country-picker', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'oc-country-picker',

        i18n: service('i18n'),

        locale: computed.oneWay('i18n.locale'),

        locales: computed('i18n.locales', function () {
            return this.get('i18n.locales').filter(function (e, i, arr) {
                return arr.indexOf(e) === i;
            });
        }).readOnly()
    });
});