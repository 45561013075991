define('oceasoft-web/components/oc-topbar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'oc-topbar',

        actions: {
            logout: function logout() {
                this.sendAction('onLogout');
            }
        }
    });
});