define("oceasoft-web/routes/pairings/modules", ["exports", "ember", "oceasoft-web/mixins/admin-route"], function (exports, _ember, _oceasoftWebMixinsAdminRoute) {
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], {

    queryParams: {
      q: {
        replace: true
      }
    },

    api: service('oc-api'),

    model: function model() {
      return this.get('api').listEmerald();
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.send('resetController');
    }

  });
});