define("oceasoft-web/objects/api-object", ["exports", "ember", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var shortMD5 = _oceasoftWebObjectsUtils["default"].String.shortMD5;

  String.prototype.parseKey = function () {
    var str = this.charAt(0).toLowerCase() + this.slice(1);
    return str.replace('_', '');
  };

  exports["default"] = _ember["default"].Object.extend(_ember["default"].Copyable, _ember["default"].Freezable, {

    init: function init() {
      var _this = this;

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        _oceasoftWebObjectsUtils["default"].Object.keys(apiResponse).forEach(function (item) {
          var parsedKey = item.parseKey();

          if (_this.hasOwnProperty(parsedKey)) {
            _this.set(parsedKey, apiResponse[item]);
          }
        });
      }
    },

    id: computed(function () {
      return shortMD5(JSON.stringify(this));
    })

  });
});