define("oceasoft-web/services/oc-logger", ["exports", "ember", "oceasoft-web/config/environment"], function (exports, _ember, _oceasoftWebConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var isEmpty = _ember["default"].isEmpty;

  var OC_NAME = "*********************************************************************************";
  var Level = {
    ERROR: 0,
    INFO: 1,
    DEBUG: 2,
    WARN: 3
  };

  function log() {
    if (!isEmpty(window.console)) {
      console.log.apply(console, arguments);
    }
  }

  /**
   * Returns the needed string for the indicated level. This string will pe appended in the console.log output.
   *
   * @param level
   * @returns {*}
   */
  function getLevelTag(level) {
    switch (level) {
      default:
      case Level.INFO:
        return 'INFO';

      case Level.ERROR:
        return 'ERROR';

      case Level.DEBUG:
        return 'DEBUG';

      case Level.WARN:
        return 'WARN';
    }
  }

  /**
   * Returns a CSS inline-style for coloring each logging level.
   *
   * @param level
   * @returns {*}
   */
  function getLevelStyle(level) {
    switch (level) {
      default:
      case Level.INFO:
        return 'color: grey';

      case Level.ERROR:
        return 'background: red; color: #ffffff';

      case Level.DEBUG:
        return 'color: blue';

      case Level.WARN:
        return 'color: red';
    }
  }

  /**
   * Checks if the provided level is allowed to print to the console.log.
   * The answer comes from the config/enviroment.js class.
   *
   * @param level
   * @returns {boolean}
   */
  function isLevelAllowed(level) {
    switch (level) {
      default:
        return false;

      case Level.INFO:
        return _oceasoftWebConfigEnvironment["default"].APP.LOG.INFO;

      case Level.ERROR:
        return _oceasoftWebConfigEnvironment["default"].APP.LOG.ERROR;

      case Level.DEBUG:
        return _oceasoftWebConfigEnvironment["default"].APP.LOG.DEBUG;

      case Level.WARN:
        return _oceasoftWebConfigEnvironment["default"].APP.LOG.WARN;
    }
  }

  /**
   * Does the actual printing using console.log but with the following options:
   * <ul>
   *  <li>Check if log level is allowed.</li>
   *  <li>Check if message exists and if it's a string or an object. If string this will format with arguments provided in this methods or if object it will be stringified.</li>
   *  <li>Adds style to the console.log output.</li>
   * </ul>
   *
   * @param level
   * @param tag
   * @param str
   */
  function println(level, tag, str) {
    if (!isLevelAllowed(level)) {
      return;
    }

    var _arguments = Array.from(arguments);

    if (_arguments.length > 3) {
      _arguments.splice(0, 3);
    }

    var levelStyle = getLevelStyle(level),
        levelTag = getLevelTag(level);

    var lineFormat = "%c%s: %s";

    if (typeof str !== 'undefined') {
      lineFormat += ": %s";

      var message = str;
      if (typeof str === 'string') {
        message = String.prototype.printf.apply(str, _arguments);
      } else {
        message = JSON.stringify(str, null, 4);
      }

      log(lineFormat, levelStyle, levelTag, tag, message);
    } else {
      log(lineFormat, levelStyle, levelTag, tag);
    }
  }

  String.prototype.printf = function (obj) {
    var useArguments = false;
    var _arguments = arguments;
    var i = -1;
    if (typeof this === 'undefined') {
      return;
    }
    if (typeof _arguments[0] === "string") {
      useArguments = true;
    }
    if (obj instanceof Array || useArguments) {
      return this.replace(/\%s/g, function () {
        i++;
        if (useArguments) {
          if (typeof _arguments[i] === 'string') {
            return _arguments[i];
          } else {
            throw new Error("Arguments element is an invalid type");
          }
        }
        return obj[i];
      });
    } else {
      return this.replace(/{([^{}]*)}/g, function (a, b) {
        var r = obj[b];
        return typeof r === 'string' || typeof r === 'number' ? r : a;
      });
    }
  };

  exports["default"] = _ember["default"].Service.extend({

    init: function init() {
      this._super.apply(this, arguments);

      if (!_oceasoftWebConfigEnvironment["default"].APP.DEBUG) {
        return;
      }

      log(OC_NAME);

      var version = _oceasoftWebConfigEnvironment["default"].APP.version;

      if (version.indexOf("+") > -1) {
        var versionData = version.split("+");
        log("Version      : " + versionData[0]);
        log("Git Commit   : " + versionData[1]);
      } else {
        log("Version      : " + version);
      }

      log("Environment  : " + _oceasoftWebConfigEnvironment["default"].environment);
      log("");
    },

    /**
     * Prints out an error message with the tag or message.
     *
     * @param tag
     * @param message
     */
    error: function error() {
      var _arguments = Array.from(arguments);
      _arguments.unshift(Level.ERROR);

      //println.apply(this, _arguments);
      println.apply(undefined, _toConsumableArray(_arguments));
    },

    /**
     * Print out information purpose messages with a specific tag.
     *
     * @param tag
     * @param message
     */
    info: function info() {
      var _arguments = Array.from(arguments);
      _arguments.unshift(Level.INFO);

      println.apply(undefined, _toConsumableArray(_arguments));
    },

    /**
     * Prints out debug messages with a tag.
     *
     * @param tag
     * @param message
     */
    debug: function debug() {
      var _arguments = Array.from(arguments);
      _arguments.unshift(Level.DEBUG);

      println.apply(undefined, _toConsumableArray(_arguments));
    },

    /**
     * Prints out warning messages with a tag.
     *
     * @param tag
     * @param message
     */
    warn: function warn() {
      var _arguments = Array.from(arguments);
      _arguments.unshift(Level.WARN);

      println.apply(undefined, _toConsumableArray(_arguments));
    }

  });
});