define('oceasoft-web/locales/es/translations', ['exports'], function (exports) {
    exports['default'] = {
        'appname': 'EDGEVue',
        'navigation': {
            'menu': {
                'my_profile': 'Mi perfil',
                'view': 'Ver',
                'profiles': 'Perfiles',
                'pairing': 'Emparejamiento',
                'voucher': 'Vale',
                'about': 'Acerca de',
                'logout': 'Cerrar sesión'
            }
        },
        'select_language_placeholder': 'Seleccionar idioma...',
        'try_again': 'Inténtelo de nuevo',
        'got_it': 'Entendido',
        'ok': 'Aceptar',
        'add_here': 'Añadir aquí',
        'more_info': 'Más información',
        'download': 'Descargar',
        'save_changes': 'Guardar cambios',
        'discard_changes': 'Descartar cambios',
        'search_placeholder': 'Buscar',
        'yes': 'Sí',
        'no': 'No',
        'cancel': 'Cancelar',
        'confirmation': 'Confirmación',
        'none': 'Ninguna',
        'celsius': '°C',
        'fahrenheit': '°F',
        'point': '1.0 <br/> Punto',
        'comma': '1,0 <br/> Coma',
        'loading': 'Cargando',
        'loading_description': 'Espere mientras se cargan los datos.',
        'not_available': 'Aún no está disponible',
        'not_available_description': 'Lo sentimos, pero la sección actual aún no está disponible para esta versión.',
        'error_dialog_title': 'Error',
        'dashboard': 'Panel de control',
        'select_country_placeholder': '-Seleccionar país-',
        'calendar_title': 'Calendario',
        'calendar_description': 'Selecciona fechas y horas para filtrar los módulos y las misiones.',
        'timeZone': 'Zona horaria',
        'gmt': 'GMT',

        'timePeriod': {
            '0': 'Todos',
            '1': 'Últimas 24 horas',
            '2': 'Última semana',
            '3': 'Último mes',
            '4': 'Calendario'
        },

        'timeFormat': {
            'title': "Formato de hora",
            'type': {
                '1': 'Reloj de 12 horas (mañana y tarde)',
                '0': 'Reloj de 24 horas'
            }
        },

        'dateFormat': {
            'title': "Formato de fecha",
            'type': {
                'MM/DD/YYYY': 'MM/DD/AAAA',
                'DD/MM/YYYY': 'DD/MM/AAAA'
            }
        },

        'timeUnit': {
            'seconds': '{{val}} segunda',
            'minutes': '{{val}} min(s)'
        },

        'temperatureUnit': {
            'title': "Unidad de temperatura",
            'type': {
                '0': '°C Celsius',
                '1': '°F Fahrenheit'
            }
        },

        'decimalSeparator': {
            'title': "Separador decimal",
            'type': {
                '0': '1.0 Punto',
                '1': '1,0 Coma'
            }
        },

        'countryInput': {
            'title': "Idioma de visualización",
            'language': {
                'EN': "Inglés",
                'FR': "Francés",
                'ES': 'Español'
            }
        },

        'logout_confirm': '¿Está seguro de que desea desconectarse?',

        'notifications': {
            'title': 'Notificaciones',
            'alarms': 'Alarmas',
            'mission_read': 'Misión leída',
            'mission_started': 'Misión iniciada',
            'mission_stopped': 'Misión parada'
        },

        'cvs_template': {
            'type': 'Typo',
            'date': 'Fecha',
            'time': 'Hora',
            'datetime': 'Fecha-Hora',
            'value': 'Valor',
            'info1': 'Info 1',
            'info2': 'Info 2'
        },

        'user': {
            'level': {
                '1': 'Administrador',
                '0': 'Usuario'
            }
        },

        'mission': {
            'event_type': {
                '-1': 'Evento desconocido',
                '0': 'Misión programada',
                '1': 'Registro de datos iniciado',
                '2': 'Módulo conectado',
                '3': 'Lecturas descargadas',
                '4': 'Misión parada',
                '5': 'Restablecimiento de alarma',

                '-10': 'Alarma desconocida',
                '10': 'Límite superior',
                '11': 'Límite inferior',
                '12': 'Fallo del sensor',
                '13': 'Batería baja',
                '14': 'Fuera de cobertura',

                '20': 'Programado en el banco',
                '21': 'Misión geolocalizado'
            }
        },

        'module': {
            'type': {
                '0': 'Desconocido',
                '1': 'Módulo',
                '2': 'EDGE S400'
            },

            'mission_search_filter_placeholder': '-Estado de la misión-',

            'mission_search_filter': {
                '0': 'Sin misión',
                '1': 'Misiones con alarma(s)',
                '2': 'Misiones sin alarma(s)',
                '3': 'Misiones detenidas/finalizadas',
                '4': 'Todas las misiones'
            },

            'mission_alarm_search_filter': {
                '0': 'Estado de la alarma',
                '1': 'En caso de alarma',
                '2': 'No hay alarmas en progreso'
            },

            'mission_filter': {
                '0': 'Todas las misiones',
                '1': 'No hay alarmas',
                '2': 'Una o más alarmas'
            }
        },

        'search': {
            'no_matches_title': 'No hay coincidencias',
            'no_matches_description': 'Su búsqueda no ha arrojado resultados. <br/><br/>Por favor, inténtelo con otra consulta.'
        },

        'reset_fields': {
            'one': 'Restablecer campo',
            'other': 'Restablecer campos'
        },

        'host': {
            'label': 'Servidor',

            '0': 'Américas',
            '1': 'EMEA (Europa, Oriente Medio, África)',
            '2': 'APAC (Asia-Pacífico)'
        },

        'voucher': {
            'page': {
                'title': 'Vale',
                'add_message_description': '¿Tiene un bono y quiere usarlo?',
                'my_voucher': 'Mi bono',
                'new_voucher': 'Nuevo bono',
                'new_voucher_key': 'Clave del nuevo bono',
                'latest_voucher_key': 'Clave del último bono',
                'available_modules': 'Módulos disponibles',
                'total_nr_modules': 'Número total de módulos',
                'exp_date': 'Fecha de caducidad',
                'new_save_msg': 'El bono se ha agregado correctamente.',
                'add_voucher': 'Agregar bono',
                'no_voucher_msg': 'No hay bonos',
                'no_voucher_msg_details': 'Parece que no ha agregado ningún bono.',

                'incorrect_voucher_key': 'La clave de este bono no es correcta',
                'key_already_used': 'La clave de este bono ya se ha utilizado',
                'voucher_expired': 'La fecha de caducidad ya ha pasado.',

                'voucher_remove_modules': 'Imposible de añadir este cupòn. Por favor elimine {{x}} módulo(s).'
            },

            'label': 'Vale',
            'type': {
                '0': 'EDGE M300 y EDGE S400',
                '1': 'Solo EDGE S400'
            }
        },

        'about': {
            'page': {
                'title': 'Acerca de',
                'product_info': 'Información sobre el producto',
                'accessories': 'Accesorios',
                'user_guide': 'Guía del usuario',
                'software_version': 'EDGEVue {{version}}',
                'product_info_page': 'Utilice EDGEVue para gestionar las funciones de la nube para sus registradores de datos TEMPTIME.<br/> Configurar los usuarios y la información de la empresa, agregar dispositivos y ver datos.',
                'accessories_page': 'Personalice sus módulos EDGE M300 con diferentes anillos de color para su fácil identificación. Consulte el sitio web de TEMPTIME para más información.',
                'guide_page': 'Lea la última guía de usuario en línea para descubrir y aprender a usar todas las características de la solución EDGEVue.'
            }
        },

        'pairings': {
            'page': {
                'title': 'Emparejamiento',
                'module_list': 'Lista de módulos',
                'new_module': 'Nuevo módulo',
                'module_sn': 'Module {{serial}}',
                'add_module_msg': '¿Desea agregar un módulo?',
                'no_modules_msg_title': 'No hay módulos',
                'no_modules_msg_description': 'Por el momento no hay módulos asignados a su empresa.',
                'what_to_add_new_module': '¿Desea agregar un módulo?',
                'no_modules_selected_msg_title': 'No hay módulos seleccionados',
                'no_modules_selected_msg_description': 'Para editar o modificar un módulo, primero necesita seleccionar uno de la lista.',
                'serial_nr_mandatory': 'Número de serie <span class="red">*</span>',
                'serial_nr': 'Número de serie',
                'name': 'Nombre',
                'remove_module': 'Eliminar módulo',
                'remove_confirmation': 'Va a quitar este módulo.<br/>Esta acción es permanente y no se puede deshacer.<br /><br/>¿Está seguro de que desea continuar?',
                'add_one_module': 'Agregar un módulo',
                'add_multiple_modules': 'Agregar varios módulos',
                'select_file': 'Seleccionar archivo',
                'select_another_file': 'Seleccionar otro archivo',
                'how_to_find_serial': '¿Cómo encontrar el número de serie?',
                'find_serial_description': 'Módulos EDGE M300: Introduzca el número de serie proporcionado en la pegatina de su módulo, después haga clic en AGREGAR UN MÓDULO. <br> <br> Módulos EDGE S400: No es necesario que introduzca un número de serie aquí. Los módulos EDGE S400 se asocian automáticamente a su cuenta cuando programe su primera misión habilitada para la nube con EDGEVue.',
                'batch_add': 'Agregar lotes',
                'batch_add_details_1': 'Puede cargar un archivo de texto con los números de serie de todos sus módulos.',
                'batch_add_details_2': 'El texto debe formatearse con un solo número de serie en cada línea.',
                'error_unkown_serial': 'El número de serie no es correcto.',

                'module_added_msg': {
                    'one': 'Módulo añadido correctamente.',
                    'other': '{{count}} Módulos añadidos correctamente.'
                },

                'error_module_atlas': 'EDGE S400 debe agregarse con la aplicación móvil de EDGEVue',
                'error_invalid_module': 'No se puede agregar este módulo',

                'error_voucher_limit_reached': 'Se ha alcanzado el límite del bono.',

                'error_invalid_hash_code': 'Número de serie no válido',
                'error_voucher_invalid_serial': 'Número de serie incorrecto.',

                'error_atlas_not_cloud_enabled': 'Este dispositivo EDGE S400 no está habilitado para la nube.',
                'error_atlas_cloud_enabled': 'Este dispositivo EDGE S400 está habilitado para la nube, pero debe usar la aplicación móvil EDGEVue para emparejarlo.',

                'error_already_used': 'El número de serie ya se ha utilizado',
                'error_module_already_used': 'El número de serie ya existe.<br/>El número de serie que está intentando agregar ya está presente en nuestra plataforma.<br/>Trate de introducir otro número de serie.',

                'error_file_invalid': 'Formato de archivo no válido',
                'error_file_invalid_description': 'El contenido del archivo proporcionado no es válido. Para agregar varios módulos a la vez, proporcione un archivo de texto con un número de serie en cada línea.',

                'error_no_serials_extracted': 'No se han encontrado números de serie',
                'error_no_serials_extracted_description': 'No se han encontrado números de serie válidos en el archivo cargado.',

                'file_serials': 'Números de serie válidos para agregar:',
                'file_added': 'Archivo cargado:',

                'dropzone_title': 'SOLTAR ARCHIVOS AQUÍ',
                'dropzone_message': 'El archivo se cargará automáticamente en cuanto lo suelte aquí',

                'upload_file': 'Empiece seleccionando un archivo para poder agregar módulos múltiples.',

                'error_uploading_success_all': 'Módulo múltiple creado',
                'error_uploading_success_all_description': 'Todos los módulos se han agregado correctamente.',

                'error_uploading_success_some': {
                    'one': 'Módulo agregado',
                    'other': 'Módulos agregados'
                },
                'error_uploading_success_some_description': '{{nrAdded}} de {{nrTotal}} módulo(s) añadidos con éxito.<br/><br/>Por favor, compruebe la lista de módulos para ver los que han fallado.',

                'error_uploading_multiple': 'Error al crear módulos múltiples',
                'error_uploading_multiple_description': 'Error al intentar agregar todos los módulos al servidor.',

                'loading_remove_module': 'Eliminar el módulo.',

                'error_remove': 'Error al eliminar',
                'error_remove_description': 'No se ha encontrado este módulo en el servidor para poder eliminarlo.',
                'module_removed_msg': 'Módulo eliminado',

                'error_voucher_limit': 'No se puede emparejar nuevos módulos',
                'error_voucher_limit_details': 'No puede emparejar los nuevos módulos porque su bono ha caducado o se ha alcanzado el límite.<br/>Consulte la página «Mi bono».',
                'error_no_voucher_details': 'No puede emparejar nuevos módulos porque no tiene ningún bono.<br/>Agregue un bono para poder usar esta característica.'
            }
        },

        'profiles': {
            'page': {
                'title': 'Perfiles',
                'my_profile': 'Mi perfil',
                'user_list': 'Lista de usuarios',
                'company_info': 'Información de la empresa',
                'add_user_message': '¿Desea añadir una nueva cuenta de usuario?',
                'new_user': 'Nuevo usuario',
                'no_users': 'No hay usuarios',
                'no_users_description': 'Por el momento no hay otros usuarios asignados a su empresa.',
                'no_user_selected': 'No se ha seleccionado ningún usuario',
                'no_user_selected_description': 'Para editar o modificar un usuario, primero necesita seleccionar uno de la lista.',
                'remove_user': 'Eliminar usuario',
                'add_user': 'Agregar usuario',
                'first_name_placeholder': 'Nombre <div class="block"> <span class="red">*</span></div>',
                'last_name_placeholder': 'Apellidos <div class="block"> <span class="red">*</span></div>',
                'email_placeholder': 'Dirección <div class="block">de correo electrónico <span class="red">*</span></div>',
                'level_placeholder': 'Nivel',
                'password_placeholder': 'Contraseña <span class="red">*</span>',
                'password_confirm_placeholder': 'Confirmar <div class="block">contraseña <span class="red">*</span></div>',
                'add_new_user': 'Agregar nuevo usuario',
                'remove_user_dialog_text': 'Va a quitar este usuario.<br/>Esta acción es permanente y no se puede deshacer.<br /><br/>¿Está seguro de que desea continuar?',

                'user_added_msg': '{{fullName}} ha sido añadido.',
                'user_modified_msg': '{{fullName}} ha sido modificado.',
                'user_removed': '{{fullName}} ha sido eliminado.',
                'profile_modified': 'Su perfil ha sido modificado.',

                'error': {
                    'edit': {
                        'wrong_customer_id': 'Empresa no válida',
                        'wrong_customer_id_description': 'El usuario que está intentando editar no pertenece a su empresa.',
                        'not_in_db_user': 'Usuario no válido',
                        'not_in_db_user_description': 'El usuario que está intentando editar no se ha encontrado en el servidor.'
                    },

                    'add': {
                        'already_exists': 'Usuario ya presente.',
                        'already_exists_description': 'El usuario ya existe. <br/> El usuario que está intentando agregar ya está presente en nuestra plataforma. <br/> Intente utilizar otra dirección de correo electrónico o póngase en contacto con su administrador.'
                    }
                },

                'company_info_page': {
                    'address_1': 'Dirección 1',
                    'address_2': 'Dirección 2',
                    'city': 'Ciudad',
                    'postal_code': 'Código postal',
                    'country': 'País',
                    'tel': 'Número de teléfono',
                    'company_saved_msg': 'La información de la empresa se ha guardado correctamente.'
                }
            }
        },

        'view': {
            'page': {
                'title': 'Ver',
                'no_modules_msg_title': 'No hay módulos',
                'no_modules_msg_description': 'Por el momento no hay módulos asignados a su empresa y no puede usar la funcionalidad de vista.',

                'select_start_date': 'Fecha de inicio',
                'select_end_date': 'Fecha de finalización',

                'no_module_selected_title': 'No hay módulos seleccionados',
                'no_module_selected_description': 'Para ver más información sobre una misión debe seleccionar una de la lista. <br> Si selecciona múltiples misiones cambiará a modo multivista, donde podrá comparar misiones en modo de datos guardados.',

                'date_range_title': ' Mostrar la misión que comenzó entre',
                'date_range_separator': 'y',

                'selected_missions': {
                    'one': "Una misión seleccionada",
                    'other': "{{count}} misiones seleccionadas"
                },

                'time_placeholder': '-Calendario de misiones-',

                'saved_data': 'Datos guardados',
                'live_data': 'Datos en tiempo real',

                'pdf': 'PDF',
                'cvs': 'CSV',

                'generating_ios_error_title': 'Error',
                'generating_ios_error': 'Este dispositivo no admite esta operación',

                'generating_safari_error_title': 'Error',
                'generating_safari_error': 'Este navegador no admite esta operación',

                'max_missions_selected_error': 'Límite alcanzado',
                'max_missions_selected_error_description': 'Ya ha seleccionado el número máximo de misiones.<br/>Desactive una misión para seleccionar otra.',

                'generating_pdf_loading': 'Generar un informe de misión',
                'pdf_generated_title': 'Informe de la misión listo',
                'pdf_generated_description': 'El informe de la misión se ha generado correctamente y se ha descargado en su dispositivo.',

                'pdf_failed_title': 'Error en el informe de la misión',
                'pdf_failed_description': 'Error al generar el informe de la misión. Inténtelo de nuevo más tarde. Póngase en contacto con nosotros si se produce el mismo problema.',

                'no_missions': 'No hay misiones',
                'no_missions_details': 'No hay misiones disponibles para este módulo.',
                'no_missions_for_this_period': 'No hay misiones disponibles para este período.',

                'no_missions_selected': 'No hay ninguna misión seleccionada',
                'no_missions_selected_description': 'Para ver información acerca de una misión, seleccionar cualquier punto en el gráfico a continuación.',
                'no_missions_selected_description_touch': 'Para ver información acerca de una misión, haga doble clic en cualquier punto del gráfico a continuación.',
                'no_missions_selected_description_android': 'Para ver información acerca de una misión, haga doble clic en cualquier punto del gráfico a continuación.',
                'no_missions_selected_description_ios': 'Para ver información acerca de una misión, seleccionar cualquier punto en el gráfico a continuación.',

                'no_readings_events': 'No hay datos de la misión',
                'no_readings_events_description': 'Esta misión todavía no tiene lecturas, eventos ni alarmas.',

                'no_readings': 'No hay lecturas',
                'no_readings_description': 'Esta misión todavía no tiene lecturas.<br/><br/>Puede intentar habilitar eventos/alarmas para ver si hay datos disponibles.',

                'no_events': 'Sin Alarmas o Alarmas',
                'no_events_description': 'Esta misión todavía no tiene eventos ni alarmas.<br/><br/>Puede intentar habilitar lecturas para ver si hay datos disponibles.',

                'no_reading_event_type_selected': 'Ningún filtro seleccionado',
                'no_reading_event_type_selected_description': 'Seleccione uno o ambos filtros anteriores para ver las lecturas, eventos o alarmas.',

                'on_alarm': 'En caso de alarma',
                'last_mission_started': 'Última misión iniciada',

                'start_date': 'Fecha de inicio',
                'end_date': 'Fecha de finalización',

                'unselected_date': 'Seleccione fecha',

                'readings_check': 'Lecturas',
                'events_alarms_check': 'Eventos/Alarmas',

                'mission_started_at_description': 'Iniciada el {{date}}',
                'too_many_result': 'Su solicitud devolvió demasiados elementos, seleccione un período más corto',
                'graph_title': 'Gráfico',

                'value_or_none': {
                    'zero': 'Ninguna',
                    'other': '{{count}}'
                },

                'informations': 'Información',
                'informations_data': {
                    'sn': 'Número de serie',
                    'reading_interval': 'Intervalo de lectura',
                    'reading_count': 'Recuento de lectura de la nube',
                    'maximum_temp': 'Temperatura máxima',
                    'minimum_temp': 'Temperatura mínima',
                    'high_limit': 'Límite superior',
                    'low_limit': 'Límite inferior',
                    'high_delay': 'Retraso alto',
                    'low_delay': 'Retraso bajo',
                    'datalogging_start': 'Inicio del registro de datos',
                    'alarms': 'Alarma(s)',
                    'avg_temp': 'Temperatura media',
                    'reading_err': 'Error de lectura'
                },

                'map_not_available': 'El mapa aún no está disponible',
                'map_not_available_description': 'Se necesita al menos 1 evento con posición GPS para generar un mapa.',
                'map_not_available_description_readings': 'Se necesita al menos 1 lectura con posición GPS para generar un mapa.',
                'map_not_available_no_mission_description': 'Seleccione una misión para generar un mapa.',

                'graph_not_available': 'El gráfico no está disponible.',
                'graph_not_available_details': {
                    'zero': 'No hay lecturas.',
                    'one': 'Son necesarias al menos dos lecturas para generar un gráfico.'
                }
            }
        },

        'required_field': '<span class="red">*</span> Campo obligatorio',

        'error': {
            'unable_to_complete_operation': 'No se puede completar la operación',
            'empty_field': 'El campo está vacío',
            'max_length': 'Se ha excedido la longitud máxima',
            'empty_select': 'Valor no seleccionado',
            'general': 'Error general',
            'general_description': 'Parece que hay un problema con nuestra plataforma.<br/>Inténtelo de nuevo más tarde.',

            'non_matching_passwords': 'Las contraseñas no coinciden',
            'full_name': 'Proporcione su nombre y apellidos',

            '404_title': 'Página no encontrada',
            '404_description': 'Parece que la página que busca no se encuentra.<br/>Regrese a la página de previsualizaciones para continuar.',

            'unauthorized': 'No autorizado',
            'unauthorized_description': 'No tiene los privilegios necesarios para acceder a esa página.<br/>Si considera que se trata de un error, póngase en contacto con nosotros.',

            'user_not_admin': 'Solo los administradores tienen permiso para realizar esta operación.',
            'user_not_enabled': 'El correo electrónico y la contraseña que ha proporcionado no son válidos.',
            'user_disabled': 'El usuario no está habilitado. Póngase en contacto con su administrador.',

            'user_not_admin_detailed': 'Solo los administradores tienen permiso para realizar esta operación.'
        },

        'login': {
            'title': 'Acceso',
            'description': 'Utilice las credenciales de su cuenta para acceder a la aplicación.',
            'send_password': 'Enviar una nueva contraseña',
            'connect': 'Conectar',
            'register_msg': '¿No tiene una cuenta?',
            'register_link_btn': 'Crear aquí',
            'email_placeholder': 'Dirección de correo electrónico',
            'password_placeholder': 'Contraseña',

            'invalid_email': 'Correo electrónico no válido',
            'invalid_password': 'Caracteres no válidos',
            'password_length': 'Mínimo 6 caracteres',

            'loading_description': 'Espere mientras le conectamos.',

            'error': 'Error de autenticación',
            'error_description': 'El correo electrónico y la contraseña que ha proporcionado no son válidos.',

            'reset_password': {
                'title': 'Restablecer contraseña',
                'description': 'Usted esta a punto de regenerar la contraseña para <b>{{email}}</b>.<br/>Es usted seguro de continuar?',

                'success': 'Se ha enviado la contraseña correctamente',
                'success_description': 'Hemos generado correctamente una contraseña nueva y se le ha enviado por correo.',

                'invalid_email': 'Correo electrónico no válido',
                'invalid_email_description': 'La dirección de correo electrónico proporcionada no está registrada en nuestra plataforma.'
            }
        },

        'register': {
            'title': 'Crear cuenta de empresa',
            'description': 'Introduzca información sobre usted para registrarse.',
            'personal_info': 'Información personal',
            'email_placeholder': 'Dirección de correo electrónico',
            'password_placeholder': 'Contraseña',
            'password_confirm_placeholder': 'Confirmar contraseña',
            'first_name_placeholder': 'Nombre',
            'last_name_placeholder': 'Apellidos',
            'company_name_placeholder': 'Nombre de la empresa',
            'addr1_placeholder': 'Dirección 1',
            'addr2_placeholder': 'Dirección 2',
            'city_placeholder': 'Ciudad',
            'postal_code_placeholder': 'Código postal',
            'phone_number_placeholder': 'Número de teléfono',
            'country': 'País',
            'have_voucher': '¡Tengo un vale!',
            'voucher': 'Vale',
            'voucher_key': 'Clave del bono',
            'already_have_account': 'Ya tengo una cuenta',
            'create': 'Crear',

            'loading_description': 'Espere mientras se está creando la cuenta en nuestra plataforma.<br/>Después de esto iniciará sesión automáticamente.',

            'error_already_exists': 'El usuario ya existe',
            'error_already_exists_description': 'El usuario ya existe.<br/>La información proporcionada para la nueva cuenta ya ha sido utilizada.<br/>Revise su información y utilice otra dirección de correo electrónico para crear una cuenta.',

            'error_already_exists_field': 'Correo electrónico ya utilizado',

            'error_already_exists_company': 'La empresa ya existe',
            'error_already_exists_company_description': 'Ya hay una empresa registrada con el nombre que proporcionó.<br/>Revise su información y utilice el nombre de otra empresa para crear una cuenta.',
            'error_already_exists_company_field': 'Nombre de la empresa ya utilizado',

            'error_voucher': 'Error del bono',
            'error_voucher_invalid': 'El bono que ha proporcionado no es válido.<br/>Proporcione un número de serie de bono válido para continuar.',
            'error_voucher_used': 'El bon que ha proporcionado no es válido.<br/>Proporcione un número de serie de bono válido para continuar.',
            'error_voucher_invalid_field': 'Bono no válido',
            'error_voucher_used_field': 'Bono ya utilizado',

            'successful': '¡Listo!',
            'successful_description': 'La nueva cuenta ha sido creada y nos hemos tomado la libertad de conectarle.<br/>Pulse continuar para acceder a la plataforma.',
            'continue_button': 'Continuar',
            'enter_platform_loading': 'Espere mientras preparamos todo'
        },

        'copyright': 'Copyright © {{year}} TEMPTIME.<br\/> Todos los derechos reservados.',

        'country': {
            'AF': 'Afganistán',
            'AX': 'Islas Åland',
            'AL': 'Albania',
            'DZ': 'Argelia',
            'AS': 'Samoa Americana',
            'AD': 'Andorra',
            'AO': 'Angola',
            'AI': 'Anguila',
            'AQ': 'Antártida',
            'AG': 'Antigua y Barbuda',
            'AR': 'Argentina',
            'AM': 'Armenia',
            'AW': 'Aruba',
            'AC': 'Isla Ascensión',
            'AU': 'Australia',
            'AT': 'Austria',
            'AZ': 'Azerbaiyán',
            'BS': 'Bahamas',
            'BH': 'Baréin',
            'BD': 'Bangladesh',
            'BB': 'Barbados',
            'BY': 'Bielorrusia',
            'BE': 'Bélgica',
            'BZ': 'Belice',
            'BJ': 'Benín',
            'BM': 'Bermudas',
            'BT': 'Bután',
            'BO': 'Bolivia',
            'BA': 'Bosnia y Herzegovina',
            'BW': 'Botsuana',
            'BR': 'Brasil',
            'IO': 'Territorio Británico del Océano Índico',
            'VG': 'Islas Vírgenes Británicas',
            'BN': 'Brunei',
            'BG': 'Bulgaria',
            'BF': 'Burkina Faso',
            'BI': 'Burundi',
            'KH': 'Camboya',
            'CM': 'Camerún',
            'CA': 'Canadá',
            'IC': 'Islas Canarias',
            'CV': 'Cabo Verde',
            'BQ': 'Caribe Neerlandés',
            'KY': 'Islas Caimán',
            'CF': 'República Centroafricana',
            'EA': 'Ceuta y Melilla',
            'TD': 'Chad',
            'CL': 'Chile',
            'CN': 'China',
            'CX': 'Isla de Navidad',
            'CC': 'Islas Cocos (Keeling)',
            'CO': 'Colombia',
            'KM': 'Comoras',
            'CG': 'Congo - Brazzaville',
            'CD': 'Congo - Kinshasa',
            'CK': 'Islas Cook',
            'CR': 'Costa Rica',
            'CI': 'Costa de Marfil',
            'HR': 'Croacia',
            'CU': 'Cuba',
            'CW': 'Curaçao',
            'CY': 'Chipre',
            'CZ': 'República Checa',
            'DK': 'Dinamarca',
            'DG': 'Diego Garcia',
            'DJ': 'Yibuti',
            'DM': 'Dominica',
            'DO': 'República Dominicana',
            'EC': 'Ecuador',
            'EG': 'Egipto',
            'SV': 'El Salvador',
            'GQ': 'Guinea Ecuatorial',
            'ER': 'Eritrea',
            'EE': 'Estonia',
            'ET': 'Etiopía',
            'FK': 'Islas Malvinas',
            'FO': 'Islas Faroe',
            'FJ': 'Fiyi',
            'FI': 'Finlandia',
            'FR': 'Francia',
            'GF': 'Guyana francesa',
            'PF': 'La Polinesia Francesa',
            'TF': 'Territorios Franceses del Sur',
            'GA': 'Gabón',
            'GM': 'Gambia',
            'GE': 'Georgia',
            'DE': 'Alemania',
            'GH': 'Ghana',
            'GI': 'Gibraltar',
            'GR': 'Grecia',
            'GL': 'Groenlandia',
            'GD': 'Granada',
            'GP': 'Guadalupe',
            'GU': 'Guam',
            'GT': 'Guatemala',
            'GG': 'Guernsey',
            'GN': 'Guinea',
            'GW': 'Guinea-Bissau',
            'GY': 'Guyana',
            'HT': 'Haití',
            'HN': 'Honduras',
            'HK': 'Hong Kong SAR China',
            'HU': 'Hungría',
            'IS': 'Islandia',
            'IN': 'India',
            'ID': 'Indonesia',
            'IR': 'Irán',
            'IQ': 'Irak',
            'IE': 'Irlanda',
            'IM': 'Isla de Man',
            'IL': 'Israel',
            'IT': 'Italia',
            'JM': 'Jamaica',
            'JP': 'Japón',
            'JE': 'Jersey',
            'JO': 'Jordania',
            'KZ': 'Kazajistán',
            'KE': 'Kenia',
            'KI': 'Kiribati',
            'XK': 'Kosovo',
            'KW': 'Kuwait',
            'KG': 'Kirguistán',
            'LA': 'Laos',
            'LV': 'Letonia',
            'LB': 'Líbano',
            'LS': 'Lesoto',
            'LR': 'Liberia',
            'LY': 'Libia',
            'LI': 'Liechtenstein',
            'LT': 'Lituania',
            'LU': 'Luxemburgo',
            'MO': 'Macau SAR China',
            'MK': 'Macedonia',
            'MG': 'Madagascar',
            'MW': 'Malawi',
            'MY': 'Malasia',
            'MV': 'Maldivas',
            'ML': 'Mali',
            'MT': 'Malta',
            'MH': 'Islas Marshall',
            'MQ': 'Martinica',
            'MR': 'Mauritania',
            'MU': 'Mauricio',
            'YT': 'Mayotte',
            'MX': 'México',
            'FM': 'Micronesia',
            'MD': 'Moldova',
            'MC': 'Mónaco',
            'MN': 'Mongolia',
            'ME': 'Montenegro',
            'MS': 'Montserrat',
            'MA': 'Marruecos',
            'MZ': 'Mozambique',
            'MM': 'Myanmar (Birmania)',
            'NA': 'Namibia',
            'NR': 'Nauru',
            'NP': 'Nepal',
            'NL': 'Países Bajos',
            'NC': 'Nueva Caledonia',
            'NZ': 'Nueva Zelanda',
            'NI': 'Nicaragua',
            'NE': 'Níger',
            'NG': 'Nigeria',
            'NU': 'Niue',
            'NF': 'Isla Norfolk',
            'KP': 'Corea del Norte',
            'MP': 'Islas Marianas del Norte',
            'NO': 'Noruega',
            'OM': 'Omán',
            'PK': 'Pakistán',
            'PW': 'Palau',
            'PS': 'Territorios Palestinos',
            'PA': 'Panamá',
            'PG': 'Papúa Nueva Guinea',
            'PY': 'Paraguay',
            'PE': 'Perú',
            'PH': 'Filipinas',
            'PN': 'Islas Pitcairn',
            'PL': 'Polonia',
            'PT': 'Portugal',
            'PR': 'Puerto Rico',
            'QA': 'Qatar',
            'RE': 'Reunión',
            'RO': 'Rumanía',
            'RU': 'Rusia',
            'RW': 'Ruanda',
            'WS': 'Samoa',
            'SM': 'San Marino',
            'ST': 'Santo Tomé y Príncipe',
            'SA': 'Arabia Saudí',
            'SN': 'Senegal',
            'RS': 'Serbia',
            'SC': 'Seychelles',
            'SL': 'Sierra Leona',
            'SG': 'Singapur',
            'SX': 'Sint Maarten',
            'SK': 'Eslovaquia',
            'SI': 'Eslovenia',
            'SB': 'Islas Salomón',
            'SO': 'Somalia',
            'ZA': 'Sudáfrica',
            'GS': 'Georgia del Sur e Islas Sandwich del Sur',
            'KR': 'Corea del Sur',
            'SS': 'Sudán del Sur',
            'ES': 'España',
            'LK': 'Sri Lanka',
            'BL': 'San Bartolomé',
            'SH': 'Santa Elena',
            'KN': 'San Kitts y Nevis',
            'LC': 'Santa Lucía',
            'MF': 'San Martín',
            'PM': 'San Pedro y Miquelón',
            'VC': 'San Vicente y las Granadinas',
            'SD': 'Sudán',
            'SR': 'Surinam',
            'SJ': 'Svalbard y Jan Mayen',
            'SZ': 'Suazilandia',
            'SE': 'Suecia',
            'CH': 'Suiza',
            'SY': 'Siria',
            'TW': 'Taiwán',
            'TJ': 'Tayikistán',
            'TZ': 'Tanzania',
            'TH': 'Tailandia',
            'TL': 'Timor-Leste',
            'TG': 'Togo',
            'TK': 'Tokelau',
            'TO': 'Tonga',
            'TT': 'Trinidad y Tobago',
            'TA': 'Tristan da Cunha',
            'TN': 'Túnez',
            'TR': 'Turquía',
            'TM': 'Turkmenistán',
            'TC': 'Islas Turks y Caicos',
            'TV': 'Tuvalu',
            'UM': 'Islas menores alejadas de los EE. UU.',
            'VI': 'Islas Vírgenes de los EE. UU.',
            'UG': 'Uganda',
            'UA': 'Ucrania',
            'AE': 'Emiratos Árabes Unidos',
            'GB': 'Reino Unido',
            'US': 'Estados Unidos',
            'UY': 'Uruguay',
            'UZ': 'Uzbekistán',
            'VU': 'Vanuatu',
            'VA': 'Ciudad del Vaticano',
            'VE': 'Venezuela',
            'VN': 'Vietnam',
            'WF': 'Wallis y Futuna',
            'EH': 'Sahara Occidental',
            'YE': 'Yemen',
            'ZM': 'Zambia',
            'ZW': 'Zimbabue'
        }

    };
});