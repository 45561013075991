define('oceasoft-web/mixins/window', ['exports', 'ember'], function (exports, _ember) {
    var on = _ember['default'].on;
    var $ = _ember['default'].$;
    var Evented = _ember['default'].Evented;
    exports['default'] = _ember['default'].Mixin.create(Evented, {

        window: {
            height: null,
            width: null,

            isPortrait: false,

            isSmallOnly: false,
            // Small Up Never changes.
            isSmallUp: true,
            isMediumOnly: false,
            isMediumUp: false,
            isLargeOnly: false,
            isLargeUp: false,
            isXLargeOnly: false,
            isXLargeUp: false,
            isXXLargeUp: false,

            isRetina: false
        },

        checkWindow: on('init', 'resize', function () {
            var windowEl = _ember['default'].$(window);
            var isPortrait = windowEl.height() > windowEl.width();

            this.set('window.height', windowEl.height());
            this.set('window.width', windowEl.width());
            this.set('window.outerHeight', windowEl.outerHeight);
            this.set('window.outerHeight', windowEl.outerWidth);

            this.set('window.isPortrait', isPortrait);

            this.set('window.isSmallOnly', Modernizr.mq('only screen and (max-width: 1250px)'));
            this.set('window.isMediumOnly', Modernizr.mq('only screen and (min-width: 1250.50px) and (max-width: 1251px)'));
            this.set('window.isMediumUp', Modernizr.mq('only screen and (min-width: 1250.50px)'));
            this.set('window.isLargeOnly', Modernizr.mq('only screen and (min-width: 1251.50px) and (max-width: 1200px)'));
            this.set('window.isLargeUp', Modernizr.mq('only screen and (min-width: 1251.50px)'));
            this.set('window.isXLargeOnly', Modernizr.mq('only screen and (min-width: 1200.50px) and (max-width: 1440px)'));
            this.set('window.isXLargeUp', Modernizr.mq('only screen and (min-width: 1200.50px)'));
            this.set('window.isXXLargeOnly', Modernizr.mq('only screen and (min-width: 1440.50px)'));

            this.set('window.isRetina', Modernizr.mq('only screen and (-webkit-min-device-pixel-ratio: 2)' + 'only screen and and (min--moz-device-pixel-ratio: 2)' + 'only screen and (-o-min-device-pixel-ratio: 2/1)' + 'only screen and (min-device-pixel-ratio: 2)' + 'only screen and (min-resolution: 192dpi)' + 'only screen and (min-resolution: 2dppx)'));
        }),

        init: function init() {
            var _this = this;

            this._super();
            $(window).on('resize', function () {
                return _this.trigger('resize');
            });
        },

        willDestoryElement: function willDestoryElement() {
            $(window).off('resize');
            this._super();
        }

    });
});