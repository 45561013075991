define("oceasoft-web/mixins/error-controller", ["exports", "ember", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsConstants) {
  var on = _ember["default"].on;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Mixin.create({

    handleInit: on('init', function () {
      this.set('i18n', service('i18n'));
    }),

    error: {
      type: null,
      image: null,
      icon: null,
      title: null,
      description: null,

      field: {}
    },

    isError: computed('error.title', 'error.description', function () {
      var error = this.get('error');
      return !isEmpty(error.title) || !isEmpty(error.description);
    }),

    actions: {
      resetController: function resetController() {
        this._super.apply(this, arguments);

        this.send('hideError');
        this.send('clearFieldErrors');
      },

      showError: function showError(type, icon, image, title, description) {
        var _this = this;

        var isI18NKey = arguments.length <= 5 || arguments[5] === undefined ? true : arguments[5];

        type = type || _oceasoftWebObjectsConstants["default"].Error.ALERT;

        if (isEmpty(icon)) {
          switch (type) {
            case _oceasoftWebObjectsConstants["default"].Error.Type.ALERT:
              icon = 'bell';
              break;

            case _oceasoftWebObjectsConstants["default"].Error.Type.WARNING:
              icon = 'exclamation-triangle';
              break;

            case _oceasoftWebObjectsConstants["default"].Error.Type.FATAL:
              icon = 'exclamation-circle';
              break;

            case _oceasoftWebObjectsConstants["default"].Error.Type.NETWORK:
              icon = 'globe';
              break;
          }
        }

        this.set('error.type', type);
        this.set('error.image', image);
        this.set('error.icon', icon);

        if (isI18NKey) {
          (function () {
            var i18n = _this.get('i18n');

            if (!isEmpty(title)) {
              _this.set('error.title', computed('i18n.locale', function () {
                return i18n.t(title);
              }));
            }

            if (!isEmpty(description)) {
              _this.set('error.description', computed('i18n.locale', function () {
                return i18n.t(description);
              }));
            }
          })();
        } else {
          this.set('error.title', title);
          this.set('error.description', description);
        }

        this.send('resetContentScroll');
      },

      showAlertError: function showAlertError(title, description) {
        var isI18NKey = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        this.send('showError', _oceasoftWebObjectsConstants["default"].Error.Type.ALERT, null, null, title, description, isI18NKey);
      },

      showWarningError: function showWarningError(title, description) {
        var isI18NKey = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        this.send('showError', _oceasoftWebObjectsConstants["default"].Error.Type.WARNING, null, null, title, description, isI18NKey);
      },

      showFatalError: function showFatalError(title, description) {
        var isI18NKey = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        this.send('showError', _oceasoftWebObjectsConstants["default"].Error.Type.FATAL, null, null, title, description, isI18NKey);
      },

      showNetworkError: function showNetworkError(title, description) {
        var isI18NKey = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        this.send('showError', _oceasoftWebObjectsConstants["default"].Error.Type.NETWORK, null, null, title, description, isI18NKey);
      },

      showGeneralNetworkError: function showGeneralNetworkError() {
        this.send('showError', _oceasoftWebObjectsConstants["default"].Error.Type.NETWORK, null, null, 'error.general', 'error.general_description', true);
      },

      hideError: function hideError() {
        if (this.get('isError')) {
          this.send('resetContentScroll');
        }

        this.set('error.type', null);
        this.set('error.image', null);
        this.set('error.icon', null);
        this.set('error.title', null);
        this.set('error.description', null);
      },

      setFieldError: function setFieldError(key, error) {
        var _this2 = this;

        var isI18NKey = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        var field = "error.field." + key;

        if (isI18NKey) {
          (function () {
            var i18n = _this2.get('i18n');
            _this2.set(field, computed('i18n.locale', function () {
              return i18n.t(error);
            }));
          })();
        } else {
          this.set(field, error);
        }
      },

      clearFieldError: function clearFieldError(key) {
        this.set("error.field." + key, null);
      },

      clearFieldErrors: function clearFieldErrors() {
        this.set('error.field', {});
      }
    }

  });
});