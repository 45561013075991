define("oceasoft-web/controllers/pairings/new", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/mixins/editable-controller", "oceasoft-web/mixins/session-manager", "oceasoft-web/objects/module", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebMixinsEditableController, _oceasoftWebMixinsSessionManager, _oceasoftWebObjectsModule, _oceasoftWebObjectsUtils) {

    var TAG = 'pairings.new controller';
    var isEmpty = _ember["default"].isEmpty;
    var computed = _ember["default"].computed;
    var service = _ember["default"].inject.service;
    var A = _ember["default"].A;
    var $ = _ember["default"].$;
    var RSVP = _ember["default"].RSVP;
    var later = _ember["default"].run.later;
    var Response = _oceasoftWebObjectsConstants["default"].API.Response;
    var REGEX = _oceasoftWebObjectsConstants["default"].REGEX;
    var ModuleType = _oceasoftWebObjectsConstants["default"].Module.Type;
    var ErrorAlertType = _oceasoftWebObjectsConstants["default"].Error.Type;
    var getFileExtension = _oceasoftWebObjectsUtils["default"].File.getFileExtension;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], _oceasoftWebMixinsEditableController["default"], _oceasoftWebMixinsSessionManager["default"], {

        logger: service('oc-logger'),
        api: service('oc-api'),
        notify: service('notify'),

        modules: new A([]),

        hasModules: computed.notEmpty('modules'),

        actions: {

            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');

                this.set('model', _oceasoftWebObjectsModule["default"].create({}));
                this.send('clearFile');
            },

            reset: function reset() {
                this.get('logger').info(TAG, 'reset');

                this.set('model.serial', null);
            },

            clearFile: function clearFile() {
                this.set('selectedFi    leName', null);
                this.set('modules', new A([]));
            },

            create: function create() {
                var _this = this;

                this.get('logger').info(TAG, 'create');

                var _getProperties = this.getProperties('i18n', 'model');

                var i18n = _getProperties.i18n;
                var model = _getProperties.model;

                var hasError = false;

                model.set('serial', model.get('serial').toUpperCase());

                if (isEmpty(model.get('serial'))) {
                    this.send('setFieldError', 'serial', 'error.empty_field');
                    hasError = true;
                } else if (!REGEX.MODULE_SERIAL.test(model.get('serial').toUpperCase().trim())) {
                    this.send('showWarningError', 'error.unable_to_complete_operation', 'pairings.page.error_voucher_invalid_serial');
                    this.send('setFieldError', 'serial', 'pairings.page.error_invalid_hash_code');
                    hasError = true;
                } else if (model.get('type') === ModuleType.ATLAS || model.get('type') === ModuleType.ATLAS2 || model.get('type') === ModuleType.ATLAS2plus) {
                    this.send('showWarningError', 'error.unable_to_complete_operation', model.get('hasCloudAccess') ? 'pairings.page.error_atlas_cloud_enabled' : 'pairings.page.error_atlas_not_cloud_enabled');
                    hasError = true;
                } else if (model.get('type') === ModuleType.UNKNOWN) {
                    this.send('showWarningError', 'error.unable_to_complete_operation', 'pairings.page.error_unkown_serial');
                    this.send('setFieldError', 'serial', 'pairings.page.error_invalid_hash_code');
                    hasError = true;
                }

                if (hasError) {
                    return;
                }

                model.set('name', model.get('serial').toUpperCase());

                this.send('hideError');
                this.send('showLoading');

                this.get('api').associateEmerald(model).then(function () {
                    _this.send('hideLoading');
                    _this.send('notifySessionDataChanged');
                    _this.get('notify').success(i18n.t('pairings.page.module_added_msg', { count: 1 }).toString());
                    _this.transitionToRoute('pairings.modules.module', model.get('id'));
                })["catch"](function (reason) {
                    _this.send('hideLoading');

                    switch (reason) {
                        default:
                        case Response.FAILED:
                            _this.send('showGeneralNetworkError');
                            break;

                        case Response.NO_PLACE:
                        case Response.INVALID_VOUCHER:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'pairings.page.error_voucher_limit_reached');
                            break;

                        case Response.ALREADY_EXIST:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'pairings.page.error_module_already_used');
                            _this.send('setFieldError', 'serial', 'pairings.page.error_already_used');
                            break;

                        case Response.INVALID_HASHCODE:
                            _this.send('showWarningError', 'error.unable_to_complete_operation', 'pairings.page.error_voucher_invalid_serial');
                            _this.send('setFieldError', 'serial', 'pairings.page.error_invalid_hash_code');
                            break;

                        case Response.NOT_IN_DB_USER:
                        case Response.USER_IS_NOT_ADMIN:
                            break;
                    }

                    _this.send('handleUserAPIErrors', reason);
                });
            },

            createMultiple: function createMultiple() {
                var _this2 = this;

                this.get('logger').info(TAG, 'createMultiple');

                var _getProperties2 = this.getProperties('api', 'i18n', 'modules');

                var api = _getProperties2.api;
                var i18n = _getProperties2.i18n;
                var modules = _getProperties2.modules;

                if (isEmpty(modules)) {
                    return;
                }

                this.send('hideError');
                this.send('showLoading');

                var filteredModules = modules.filter(function (item) {
                    return item.pairingErrorKey ? false : true;
                });

                Rx.Observable.fromArray(filteredModules).map(function (emerald) {
                    return api.associateEmerald(emerald);
                }).toArray().subscribe(function (promises) {
                    RSVP.allSettled(promises).then(function (results) {

                        var nrRequests = filteredModules.length;
                        var nrFulfilled = 0;
                        var userAPIError = null;

                        for (var i = nrRequests - 1; i >= 0; i--) {
                            var result = results[i];
                            var _module2 = modules[i];

                            switch (result.state) {
                                case 'fulfilled':
                                    results.splice(i, 1);
                                    modules.splice(i, 1);
                                    nrFulfilled++;
                                    break;

                                case 'rejected':
                                    switch (result.reason) {
                                        default:
                                        case Response.FAILED:
                                            _module2.set('pairingErrorKey', 'error.general');
                                            break;

                                        case Response.NO_PLACE:
                                            _module2.set('pairingErrorKey', 'pairings.page.error_voucher_limit_reached');
                                            break;

                                        case Response.ALREADY_EXIST:
                                            _module2.set('pairingErrorKey', 'pairings.page.error_already_used');
                                            break;

                                        case Response.INVALID_HASHCODE:
                                            _module2.set('pairingErrorKey', 'pairings.page.error_invalid_hash_code');
                                            break;

                                        case Response.INVALID_DEVICE:
                                            _module2.set('pairingErrorKey', 'pairings.page.error_invalid_module');
                                            break;

                                        case Response.NOT_IN_DB_USER:
                                        case Response.USER_IS_NOT_ADMIN:
                                            if (isEmpty(userAPIError)) {
                                                userAPIError = result.reason;
                                            }

                                            break;
                                    }
                                    break;
                            }
                        }

                        if (!isEmpty(userAPIError)) {
                            _this2.send('handleUserAPIErrors', userAPIError);
                            return;
                        }

                        _this2.set('modules', new A(modules.slice(0)));

                        _this2.send('hideLoading');

                        if (nrFulfilled === 0) {
                            // All have failed.
                            _this2.send('showWarningError', 'pairings.page.error_uploading_multiple', 'pairings.page.error_uploading_multiple_description');
                        } else if (nrFulfilled === nrRequests) {
                            // All have fulfilled.
                            _this2.send('notifySessionDataChanged');
                            _this2.send('showError', ErrorAlertType.ALERT, 'check-circle', null, 'pairings.page.error_uploading_success_all', 'pairings.page.error_uploading_success_all_description', true);
                        } else {
                            // Some have failed.
                            _this2.send('notifySessionDataChanged');
                            _this2.send('showError', ErrorAlertType.WARNING, 'check-circle', null, i18n.t('pairings.page.error_uploading_success_some', { count: nrFulfilled }), i18n.t('pairings.page.error_uploading_success_some_description', {
                                nrAdded: nrFulfilled,
                                nrTotal: nrRequests
                            }), false);
                        }

                        // this.get('notify').success(i18n.t('pairings.page.module_added_msg', {count: modules.length}));
                        // this.transitionToRoute('pairings.modules');
                    })["catch"](function () {
                        _this2.send('hideLoading');
                        _this2.send('showWarningError', 'pairings.page.error_uploading_multiple', 'pairings.page.error_uploading_multiple_description');
                    });
                });
            },

            selectFile: function selectFile() {
                this.get('logger').info(TAG, 'selectFile');

                $('.file-picker .msg').trigger('click');
            },

            reselectFile: function reselectFile() {
                this.get('logger').info(TAG, 'reselectFile');

                this.set('modules', null);

                later(this, function () {
                    this.send('selectFile');
                }, 50);
            },

            parseFile: function parseFile(file) {
                var _this3 = this;

                this.get('logger').info(TAG, 'parseFile');

                if (isEmpty(file) || getFileExtension(file.filename) !== 'txt' || isEmpty(file.data)) {
                    this.send('showWarningError', 'pairings.page.error_file_invalid', 'pairings.page.error_file_invalid_description');
                    return;
                }

                this.set('selectedFileName', file.filename);

                var nrEmeraldLimit = this.get('company.sensorAvailable');

                console.log(this.get('company.sensorAvailable'));

                Rx.Observable.from(file.data.split('\n')).map(function (sn) {
                    return sn.toUpperCase().trim();
                }).distinct().filter(function (sn) {
                    return sn.length === 12 && REGEX.MODULE_SERIAL.test(sn);
                }).map(function (sn) {
                    return new _oceasoftWebObjectsModule["default"]({ serial: sn, name: sn });
                }).filter(function (module) {
                    return module.get('type') !== _oceasoftWebObjectsConstants["default"].Module.Type.UNKNOWN;
                })
                //.take(nrEmeraldLimit)
                .toArray().subscribe(function (modules) {
                    if (modules.length === 0) {
                        _this3.send('showWarningError', 'pairings.page.error_no_serials_extracted', 'pairings.page.error_no_serials_extracted_description');
                        return;
                    }

                    modules.forEach(function (m, i) {
                        return m.set('pairingErrorKey', i >= nrEmeraldLimit ? 'pairings.page.error_voucher_limit_reached' : m.get('type') === _oceasoftWebObjectsConstants["default"].Module.Type.ATLAS ? 'pairings.page.error_module_atlas' : m.get('type') === _oceasoftWebObjectsConstants["default"].Module.Type.ATLAS2 ? 'pairings.page.error_module_atlas' : m.get('type') === _oceasoftWebObjectsConstants["default"].Module.Type.ATLAS2plus ? 'pairings.page.error_module_atlas' : null);
                    });

                    _this3.set('modules', modules);
                });
            },

            removeModule: function removeModule(serial) {
                this.get('logger').info(TAG, 'removeModule');

                var modules = this.get('modules').slice(0);
                var moduleIdx = modules.indexOf(serial);

                if (moduleIdx > -1) {
                    modules.splice(moduleIdx, 1);
                    this.set('modules', new A(modules));
                }
            },

            goToList: function goToList() {
                this.get('logger').info(TAG, 'removeModule');
                this.transitionToRoute('pairings.modules');
            }
        }

    });
});