define("oceasoft-web/controllers/profiles/company-information", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/mixins/editable-controller"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebMixinsEditableController) {

  var TAG = 'profiles.company-information controller';
  var service = _ember["default"].inject.service;
  var Response = _oceasoftWebObjectsConstants["default"].API.Response;
  var Country = _oceasoftWebObjectsConstants["default"].Country;
  exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], _oceasoftWebMixinsEditableController["default"], {

    logger: service('oc-logger'),
    api: service('oc-api'),
    notify: service('notify'),

    countries: Country,

    actions: {
      resetController: function resetController() {
        this._super.apply(this, arguments);
        this.get('logger').info(TAG, 'resetController');
      },

      save: function save() {
        var _this = this;

        this.get('logger').info(TAG, 'save');

        var i18n = this.get('i18n');
        var customer = this.get('model');

        // Validate Fields
        var hasError = false;

        if (hasError) {
          return;
        }

        this.send('hideError');
        this.send('showLoading');
        this.get('api').updateCustomer(customer).then(function () {
          _this.send('hideLoading');
          _this.send('saveModel');
          _this.send('notifySessionDataChanged');

          _this.get('notify').success(i18n.t('profiles.page.company_info_page.company_saved_msg').toString());
        })["catch"](function (reason) {
          _this.send('hideLoading');

          switch (reason) {
            default:
            case Response.FAILED:
              _this.send('showGeneralNetworkError');
              break;

            case Response.NOT_IN_DB_USER:
            case Response.USER_IS_NOT_ADMIN:
              break;
          }

          _this.send('handleUserAPIErrors', reason);
        });
      }
    }

  });
});