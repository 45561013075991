define("oceasoft-web/services/functions/defineStartDateAndEndDateFromDateType", ["exports", "ember", "oceasoft-web/objects/constants"], function (exports, _ember, _oceasoftWebObjectsConstants) {
    var isEmpty = _ember["default"].isEmpty;
    var MissionTimePeriod = _oceasoftWebObjectsConstants["default"].Module.MissionTimePeriod;
    var NULL_DATE = _oceasoftWebObjectsConstants["default"].API.NULL_DATE;

    function defineStartDateAndEndDateFromDateType(dateType) {
        var currentDate = new Date();
        var sdate = undefined,
            edate = undefined;
        switch (dateType) {
            case MissionTimePeriod.ALL:
                {
                    sdate = null;
                    edate = null;
                    break;
                }

            case MissionTimePeriod.LAST_DAY:
                {
                    sdate = new Date(currentDate / 1000) - 86400;
                    edate = null;
                    break;
                }

            case MissionTimePeriod.LAST_WEEK:
                {
                    sdate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7) / 1000;
                    edate = null;
                    break;
                }

            case MissionTimePeriod.LAST_MONTH:
                {
                    sdate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()) / 1000;
                    edate = null;
                    break;
                }

            case MissionTimePeriod.CALENDAR:
                {
                    if (isEmpty(sdate) && !isEmpty(edate)) {
                        sdate = NULL_DATE / 1000;
                    }
                    break;
                }
        }

        if (!isEmpty(sdate) && isEmpty(edate)) {
            edate = currentDate / 1000;
        }

        return { 'sdate': sdate, 'edate': edate };
    }

    exports["default"] = defineStartDateAndEndDateFromDateType;
});