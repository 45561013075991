define("oceasoft-web/objects/utils", ["exports", "ember", "oceasoft-web/config/environment", "oceasoft-web/objects/constants", "npm:file-saver"], function (exports, _ember, _oceasoftWebConfigEnvironment, _oceasoftWebObjectsConstants, _npmFileSaver) {
    var _isEmpty = _ember["default"].isEmpty;
    var observer = _ember["default"].observer;
    var on = _ember["default"].on;
    var typeOf = _ember["default"].typeOf;
    var Promise = _ember["default"].RSVP.Promise;
    var saveAs = _npmFileSaver["default"].saveAs;

    var Utils = {

        User: {
            /**
             * Concatenates the plain text password with the shared key and creates an md5 hash.
             *
             * @param pwd
             * @returns {*}
             */
            passwordHash: function passwordHash(pwd) {
                return md5(pwd + this.getSharedKey()).trim().toUpperCase();
            },

            /**
             * Function that scrambles the API Secret Hash Key in order to not use it directly as a string.
             *
             * @returns {string}
             */
            getSharedKey: function getSharedKey() {
                var keyStr = _oceasoftWebConfigEnvironment["default"].APP.API.CREDENTIALS.SECRET_HASH_KEY;
                var len = keyStr.length;

                keyStr = keyStr.substring(0, len - 2) + 'B' + 'b';
                for (var i = 0; i < len; i++) {
                    var character = String.fromCharCode(keyStr.charCodeAt(i) + i + 1);
                    keyStr = keyStr.substr(0, i) + character + keyStr.substr(i + character.length);
                }

                return keyStr;
            },

            encrypt: function encrypt(str) {
                if (_isEmpty(str)) {
                    return null;
                }

                var bytes = Utils.String.toUTF8Array(str);
                for (var i = 0; i < str.length; i++) {
                    bytes[i] = bytes[i] ^ i + 1;
                }

                var encryptedStr = btoa(Utils.String.fromUTF8Array(bytes));

                for (var j = 0; j < 2; j++) {
                    if (encryptedStr.charAt(encryptedStr.length - 1) === '=') {
                        encryptedStr = encryptedStr.slice(0, -1);
                    }
                }

                return encryptedStr;
            },

            decrypt: function decrypt(encryptedStr) {
                if (_isEmpty(encryptedStr)) {
                    return null;
                }

                for (var i = 0; i < 2; i++) {
                    if (encryptedStr.length & 3 !== 0) {
                        encryptedStr += '=';
                    }
                }

                var bytes = Utils.String.toUTF8Array(atob(encryptedStr));
                for (var j = 0; j < bytes.length; j++) {
                    bytes[j] = bytes[j] ^ j + 1;
                }

                return Utils.String.fromUTF8Array(bytes);
            }
        },

        Property: {
            observeTree: function observeTree(key, callback) {
                return observer(key, function () {
                    var _this = this;

                    var obj = this.get(key);

                    if (!_isEmpty(obj)) {
                        Utils.Object.keys(obj).forEach(function (key) {
                            obj.addObserver(key, _this, callback);
                        });
                    }
                });
            },

            isNull: function isNull(prop) {
                return _isEmpty(prop) || prop === _oceasoftWebObjectsConstants["default"].API.Response.NAN;
            }
        },

        Component: {
            observeMutation: function observeMutation(callback) {
                var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

                return on('didRender', 'didUpdate', function () {
                    var _self = this;
                    var obj = _self.element;

                    if (MutationObserver) {
                        new MutationObserver(function () {
                            return callback.apply(_self);
                        }).observe(obj, {
                            subtree: true,
                            childList: true
                        });
                    } else if (window.addEventListener) {
                        obj.addEventListener('DOMNodeInserted', function () {
                            return callback.apply(_self);
                        }, false);
                        obj.addEventListener('DOMNodeRemoved', function () {
                            return callback.apply(_self);
                        }, false);
                    }
                });
            }
        },

        Date: {
            fromString: function fromString(dateStr) {
                var format = arguments.length <= 1 || arguments[1] === undefined ? _oceasoftWebObjectsConstants["default"].API.DATETIME_FORMAT : arguments[1];

                if (_isEmpty(dateStr)) {
                    return null;
                }

                return moment(dateStr, format).toDate().getTime() + new Date().getTimezoneOffset() * 60 * 1000 * -1;
            },

            toString: function toString(date) {
                var format = arguments.length <= 1 || arguments[1] === undefined ? _oceasoftWebObjectsConstants["default"].API.DATETIME_FORMAT : arguments[1];

                if (_isEmpty(date)) {
                    return null;
                }

                return moment(date).format(format);
            },

            fromTDateTime: function fromTDateTime(dtd) {
                var delphiEpoch = new Date('12/30/1899 00:00:00');
                var timeZoneOffset = delphiEpoch.getTimezoneOffset() * 60000;
                var date = new Date(parseInt(dtd) * 8.64e7 - Math.abs(delphiEpoch - timeZoneOffset));
                date.setHours(24 * Math.abs(dtd - parseInt(dtd)));
                return date;
            },

            toTDateTime: function toTDateTime(date) {
                var timeZoneOffset = date.getTimezoneOffset() * 60000;
                var timestampUTC = date.getTime() - timeZoneOffset;
                var t = new Date('12/30/1899 00:00:00').getTime() - timeZoneOffset;
                var daysPassed = Math.floor((timestampUTC + Math.abs(t)) / 8.64e7);
                return daysPassed < 0 ? daysPassed - date.getHours() / 24 : daysPassed + date.getHours() / 24;
            },

            toUTCUnix: function toUTCUnix() {
                var date = arguments.length <= 0 || arguments[0] === undefined ? new Date() : arguments[0];

                return date.getTime();
            }
        },

        File: {
            forceFileDownload: function forceFileDownload(content, name, ext, mimeType) {
                var charset = arguments.length <= 4 || arguments[4] === undefined ? 'utf-8' : arguments[4];

                saveAs(new Blob([content], { type: mimeType + ";charset=" + charset }), name + "." + ext);
            },

            getFileExtension: function getFileExtension(filename) {
                return (/[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : null
                );
            }
        },

        String: {
            toUTF8Array: function toUTF8Array(str) {
                var utf8 = [];
                for (var i = 0; i < str.length; i++) {
                    var charcode = str.charCodeAt(i);
                    if (charcode < 0x80) {
                        utf8.push(charcode);
                    } else if (charcode < 0x800) {
                        utf8.push(0xc0 | charcode >> 6, 0x80 | charcode & 0x3f);
                    } else if (charcode < 0xd800 || charcode >= 0xe000) {
                        utf8.push(0xe0 | charcode >> 12, 0x80 | charcode >> 6 & 0x3f, 0x80 | charcode & 0x3f);
                    } else {
                        i++;
                        charcode = 0x10000 + ((charcode & 0x3ff) << 10 | str.charCodeAt(i) & 0x3ff);
                        utf8.push(0xf0 | charcode >> 18, 0x80 | charcode >> 12 & 0x3f, 0x80 | charcode >> 6 & 0x3f, 0x80 | charcode & 0x3f);
                    }
                }
                return utf8;
            },

            fromUTF8Array: function fromUTF8Array(data) {
                var str = '',
                    i;

                for (i = 0; i < data.length; i++) {
                    var value = data[i];

                    if (value < 0x80) {
                        str += String.fromCharCode(value);
                    } else if (value > 0xBF && value < 0xE0) {
                        str += String.fromCharCode((value & 0x1F) << 6 | data[i + 1] & 0x3F);
                        i += 1;
                    } else if (value > 0xDF && value < 0xF0) {
                        str += String.fromCharCode((value & 0x0F) << 12 | (data[i + 1] & 0x3F) << 6 | data[i + 2] & 0x3F);
                        i += 2;
                    } else {
                        // surrogate pair
                        var charCode = ((value & 0x07) << 18 | (data[i + 1] & 0x3F) << 12 | (data[i + 2] & 0x3F) << 6 | data[i + 3] & 0x3F) - 0x010000;

                        str += String.fromCharCode(charCode >> 10 | 0xD800, charCode & 0x03FF | 0xDC00);
                        i += 3;
                    }
                }

                return str;
            },

            toUTF16LEArray: function toUTF16LEArray(str) {
                var withBOM = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

                var bomIndex = withBOM ? 2 : 0;
                var byteArray = new Uint8Array(bomIndex + str.length * 2);

                if (withBOM) {
                    byteArray[0] = 0xFF;
                    byteArray[1] = 0xFE;
                }

                for (var i = 0; i < str.length; i++) {
                    var c = str.charCodeAt(i);
                    byteArray[bomIndex + i * 2] = c;
                    byteArray[bomIndex + i * 2 + 1] = c >> 8;
                }

                return byteArray;
            },

            shortMD5: function shortMD5(str) {
                return md5(str).substring(0, 13);
            },

            isEmpty: function isEmpty(str) {
                if (typeOf(str) !== 'string') {
                    return _isEmpty(str);
                }

                return _isEmpty(str) || _isEmpty(str.trim());
            },

            hashCode: function hashCode(str) {
                var hash = 0;
                for (var i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                return hash;
            }
        },

        Color: {
            fromInt: function fromInt(i) {
                var c = (i & 0x00FFFFFF).toString(16).toUpperCase();

                return "00000".substring(0, 6 - c.length) + c;
            },

            fromString: function fromString(str) {

                var color = _isEmpty(str) ? '#ece7f6' : "#" + Utils.Color.fromInt(Utils.String.hashCode(str));

                if ("" + Utils.Color.isTooLight(color)) {
                    return "" + Utils.Color.shadeColor2(color, -0.3);
                }

                return color;
            },

            isTooLight: function isTooLight(hexcolor) {
                hexcolor = hexcolor.replace('#', '');
                var r = parseInt(hexcolor.substr(0, 2), 16);
                var g = parseInt(hexcolor.substr(2, 2), 16);
                var b = parseInt(hexcolor.substr(4, 2), 16);
                var yiq = (r * 299 + g * 587 + b * 114) / 1000;
                return yiq >= 128;
            },

            shadeColor2: function shadeColor2(hex, lum) {
                // validate hex string
                hex = String(hex).replace(/[^0-9a-f]/gi, '');
                if (hex.length < 6) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                lum = lum || 0;

                // convert to decimal and change luminosity
                var rgb = "#",
                    c,
                    i;
                for (i = 0; i < 3; i++) {
                    c = parseInt(hex.substr(i * 2, 2), 16);
                    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
                    rgb += ("00" + c).substr(c.length);
                }

                return rgb;
            }
        },

        Object: {
            setDefaultProperties: function setDefaultProperties(obj, defaultObj) {
                if (_isEmpty(obj) || _isEmpty(defaultObj) || !(typeOf(obj) === 'class' || typeOf(obj) === 'instance')) {
                    return;
                }

                Object.keys(defaultObj).forEach(function (key) {
                    if (!obj.hasOwnProperty(key)) {
                        obj.set(key, defaultObj[key]);
                    }
                });
            },

            keys: function keys(obj) {
                var keys = [];

                if (!_isEmpty(obj)) {
                    switch (typeOf(obj)) {
                        default:
                        case 'object':
                            keys = Object.keys(obj);
                            break;

                        case 'class':
                        case 'instance':
                            keys = Object.keys(JSON.parse(JSON.stringify(obj)));
                            break;
                    }
                }

                return keys;
            },

            copyProperties: function copyProperties(fromObj, toObj) {
                if (_isEmpty(fromObj) || _isEmpty(toObj)) {
                    return;
                }

                Utils.Object.keys(toObj).forEach(function (key) {
                    toObj.set(key, fromObj.get(key));
                });
            },

            equals: function equals(a, b) {
                return JSON.stringify(a) === JSON.stringify(b);
            }
        },

        Geolocation: {
            getCurrentPosition: function getCurrentPosition() {
                return new Promise(function (resolve, reject) {
                    if (window.navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            resolve(position);
                        });
                    } else {
                        reject();
                    }
                });
            }
        },

        Browser: {
            isIE: function isIE() {
                if (navigator.appName === 'Microsoft Internet Explorer') {
                    var ua = navigator.userAgent,
                        re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");

                    if (re.exec(ua) !== null) {
                        return true;
                    }
                } else if (navigator.appName === "Netscape") {
                    return true;
                }

                return false;
            },

            isIOS: function isIOS() {
                var iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

                if (!!navigator.platform) {
                    while (iDevices.length) {
                        if (navigator.platform === iDevices.pop()) {
                            return true;
                        }
                    }
                }

                return false;
            },

            isSafari: function isSafari() {
                return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS');
            },

            isAndroid: function isAndroid() {
                return navigator.userAgent && navigator.userAgent.toLocaleLowerCase().indexOf('android') > -1;
            }
        },

        Other: {
            convertTemperature: function convertTemperature(value, isFahrenheit) {
                var finalValue = !isFahrenheit ? value : value * 9 / 5 + 32;
                return parseFloat(finalValue).toFixed(2);
            }
        }

    };

    exports["default"] = Utils;
});