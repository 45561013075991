define('oceasoft-web/mixins/outside-click', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    init: function init() {
      this._super();

      var that = this;
      _ember['default'].$(window).on('click.' + this.elementId, function (e) {
        if (!_ember['default'].$(e.target).closest(that.get('element')).length) {
          that.trigger('outside-click');
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(window).off('click.' + this.elementId);
      this._super();
    }

  });
});