define("oceasoft-web/controllers/view", ["exports", "ember", "oceasoft-web/objects/constants", "oceasoft-web/mixins/session-manager", "oceasoft-web/mixins/loading-controller", "oceasoft-web/mixins/error-controller", "oceasoft-web/objects/utils", "oceasoft-web/mixins/window", "npm:jszip", "npm:file-saver"], function (exports, _ember, _oceasoftWebObjectsConstants, _oceasoftWebMixinsSessionManager, _oceasoftWebMixinsLoadingController, _oceasoftWebMixinsErrorController, _oceasoftWebObjectsUtils, _oceasoftWebMixinsWindow, _npmJszip, _npmFileSaver) {

    var TAG = 'view controller';

    var MAP_REFRESH_TIME = 50;

    var on = _ember["default"].on;
    var A = _ember["default"].A;
    var service = _ember["default"].inject.service;
    var computed = _ember["default"].computed;
    var isEmpty = _ember["default"].isEmpty;
    var observer = _ember["default"].observer;
    var _Ember$run = _ember["default"].run;
    var later = _Ember$run.later;
    var debounce = _Ember$run.debounce;
    var once = _Ember$run.once;
    var $ = _ember["default"].$;
    var _Constants$Module = _oceasoftWebObjectsConstants["default"].Module;
    var State = _Constants$Module.State;
    var MissionSearch = _Constants$Module.MissionSearch;
    var MissionTimePeriod = _Constants$Module.MissionTimePeriod;
    var MissionAlarmSearch = _Constants$Module.MissionAlarmSearch;
    var ModuleViewType = _oceasoftWebObjectsConstants["default"].ModuleViewType;
    var Mission = _oceasoftWebObjectsConstants["default"].Mission;
    var TemperatureUnit = _oceasoftWebObjectsConstants["default"].TemperatureUnit;
    var DecimalSeparator = _oceasoftWebObjectsConstants["default"].DecimalSeparator;
    var NULL_DATE = _oceasoftWebObjectsConstants["default"].API.NULL_DATE;
    var convertTemperature = _oceasoftWebObjectsUtils["default"].Other.convertTemperature;
    var _Utils$Browser = _oceasoftWebObjectsUtils["default"].Browser;
    var isIOS = _Utils$Browser.isIOS;
    var isSafari = _Utils$Browser.isSafari;
    var isAndroid = _Utils$Browser.isAndroid;
    var toUTF16LEArray = _oceasoftWebObjectsUtils["default"].String.toUTF16LEArray;
    var forceFileDownload = _oceasoftWebObjectsUtils["default"].File.forceFileDownload;
    var Color = _oceasoftWebObjectsUtils["default"].Color;
    var saveAs = _npmFileSaver["default"].saveAs;
    exports["default"] = _ember["default"].Controller.extend(_oceasoftWebMixinsSessionManager["default"], _oceasoftWebMixinsWindow["default"], _oceasoftWebMixinsLoadingController["default"], _oceasoftWebMixinsErrorController["default"], {

        queryParams: ['q', 'lms', 't', 'at', 'sdate', 'edate', 'm', 'd', 'dp', 'view', 'mid', 'readings', 'alarms', 'dt', 'did'],

        logger: service('oc-logger'),
        api: service('oc-api'),
        i18n: service('i18n'),
        gMap: service('gMap'),

        options: { minZoom: '10' },

        isAllAlarmLimitsTheSame: undefined,
        lowLimitValue: undefined,
        highLimitValue: undefined,

        rangeStart: null,
        rangeEnd: null,

        readings: true,
        alarms: true,
        isLoading: false,

        fahrenheit: computed.equal('user.unit', TemperatureUnit.FAHRENHEIT),
        comma: computed.equal('user.decimalSeparator', DecimalSeparator.COMMA),

        dt: Mission.DataType.SAVED_DATA,

        isSidebarVisible: computed('view', function () {
            return parseInt(this.get('view')) !== ModuleViewType.MAP;
        }),

        isATdisabled: computed('t', function () {
            return this.get('t') === MissionSearch.STOPPED_ENDED_MISSIONS;
        }),

        handleTchange: observer('t', function () {
            if (this.get('t') === MissionSearch.STOPPED_ENDED_MISSIONS && this.get('at') !== MissionAlarmSearch.ALL_ALARMS) {
                this.set('at', MissionAlarmSearch.ALL_ALARMS);
            }
        }),

        handleDTChange: observer('didLoad', 'dt', 'mid', function () {
            var _this = this;

            if (this.get('dt') === Mission.DataType.LIVE_DATA) {
                this.set('readings', true);
                later(function () {
                    return _this.send('refreshModel');
                }, 1000 * 60);
            }
        }),

        observeShouldScroll: observer('q', 't', function () {
            once(this, 'handleShouldScroll');
        }),

        handleShouldScroll: function handleShouldScroll() {
            $("oc-sidebar oc-list > .list > .scroll > .ps-container").scrollTop(0);
        },

        // region Expand / Contract Box

        isInfoExpanded: false,
        isGraphExpanded: true,

        // endregion

        // region Filtering & Searching

        lms: true,

        q: null,

        isSearching: computed.notEmpty('q'),

        searchedModules: computed('model', 'q', 'lms', 't', 'at', 'hasDateRange', function () {
            var searchValue = (this.get('q') || "").toLowerCase();
            var modules = this.get('model');
            // Filters
            // const isFilterLMS = this.get('lms');
            var filterT = parseInt(this.get('t'));

            // Filter By Search Query By ModuleName
            var results = modules.filter(function (module) {
                return !isEmpty(module.get('serial')) && (module.get('serial').toLowerCase().includes(searchValue) || !isEmpty(module.get('name')) && module.get('name').toLowerCase().includes(searchValue) || !isEmpty(module.get('missions').filter(function (m) {
                    return m.get('name').toLowerCase().includes(searchValue);
                })));
            });

            // Filter the ones that do not have any missions if searching using time.
            // if (this.get('d') > 0) {
            //     results = results.filter(module => module.get('missions').length > 0);
            // }

            // Filter by Mission Type ( called Mission state on UI )
            // Filter by Mission Name
            switch (filterT) {
                case MissionSearch.NO_MISSION:
                    results = results.filter(function (module) {
                        return isEmpty(module.get('missions').filter(function (m) {
                            return m.get('name').toLowerCase().includes(searchValue);
                        }));
                    });
                    break;

                case MissionSearch.MISSION_WITH_ALARMS:
                    results = results.filter(function (module) {
                        return !isEmpty(module.get('missions').filter(function (m) {
                            return m.get('alarms') > 0;
                        }).filter(function (m) {
                            return m.get('name').toLowerCase().includes(searchValue);
                        }));
                    });
                    break;

                case MissionSearch.MISSION_WITHOUT_ALARMS:
                    results = results.filter(function (module) {
                        return !isEmpty(module.get('missions').filter(function (m) {
                            return m.get('alarms') === 0;
                        }).filter(function (m) {
                            return m.get('name').toLowerCase().includes(searchValue);
                        }));
                    });
                    break;

                case MissionSearch.STOPPED_ENDED_MISSIONS:
                    results = results.filter(function (module) {
                        return !isEmpty(module.get('missions').filter(function (m) {
                            return [Mission.State.STOPPED, Mission.State.STOPPED_AND_ARCHIVED].indexOf(m.get('state')) > -1;
                        }).filter(function (m) {
                            return m.get('name').toLowerCase().includes(searchValue);
                        }));
                    });
                    break;
            }

            // Filter each module missions
            results = results.map(function (module) {
                return module.copy();
            });

            results.forEach(function (module) {
                var missions = module.get('missions').filter(function (m) {
                    return !isEmpty(m.get('name')) && m.get('name').toLowerCase().includes(searchValue) || !isEmpty(m.get('name')) && m.get('mid').toLowerCase().includes(searchValue);
                });

                switch (filterT) {
                    case MissionSearch.STOPPED_ENDED_MISSIONS:
                        missions = missions.filter(function (m) {
                            return [Mission.State.STOPPED, Mission.State.STOPPED_AND_ARCHIVED].indexOf(m.get('state')) > -1;
                        });
                        break;

                    case MissionSearch.MISSION_WITH_ALARMS:
                        results = results.filter(function (module) {
                            return !isEmpty(module.get('missions').filter(function (m) {
                                return m.get('alarms') > 0;
                            }));
                        });
                        missions = missions.filter(function (m) {
                            return m.get('alarms') > 0;
                        });
                        break;

                    case MissionSearch.MISSION_WITHOUT_ALARMS:
                        results = results.filter(function (module) {
                            return !isEmpty(module.get('missions').filter(function (m) {
                                return m.get('alarms') === 0;
                            }));
                        });
                        missions = missions.filter(function (m) {
                            return m.get('alarms') === 0;
                        });
                        break;
                }

                module.set('missions', new A(missions));
            });
            return results;
        }),

        // endregion

        // region Sorting

        sortProperties: ['state:asc', 'name:asc', 'serial:asc'],
        modules: computed.sort('searchedModules', 'sortProperties'),

        // endregion

        // region Mission Filter

        missionSearchTypes: new A([]),

        t: null,
        d: MissionTimePeriod.LAST_WEEK,

        handleMissionTimePeriodChange: observer('d', function () {
            if (this.get('d') !== MissionTimePeriod.CALENDAR && (!isEmpty(this.get('sdate')) || !isEmpty(this.get('edate')))) {
                this.set('sdate', null);
                this.set('edate', null);
            }

            if (this.get('d') === MissionTimePeriod.CALENDAR && isEmpty(this.get('sdate')) && isEmpty(this.get('edate'))) {
                this.send('openCalendar');
            }
        }),

        createMissionSearchTypes: on('init', function () {
            var missionSearchTypes = this.get('missionSearchTypes');
            Object.keys(MissionSearch).forEach(function (missionSearchType) {
                return missionSearchTypes.push(MissionSearch[missionSearchType]);
            });
        }),

        timePeriodTypes: computed(function () {
            var types = new A([]);
            Object.keys(MissionTimePeriod).forEach(function (timePeriodType) {
                return types.push(MissionTimePeriod[timePeriodType]);
            });
            return types;
        }),

        // endregion

        // region Mission Alarm Filter

        missionAlarmSearchTypes: new A([]),

        at: MissionAlarmSearch.ALL_ALARMS,

        createMissionAlarmSearchTypes: on('init', function () {
            var missionAlarmSearchTypes = this.get('missionAlarmSearchTypes');
            Object.keys(MissionAlarmSearch).forEach(function (missionAlarmSearchType) {
                return missionAlarmSearchTypes.push(MissionAlarmSearch[missionAlarmSearchType]);
            });
        }),

        // endregion

        // region Date Range

        tempSDate: null,
        tempEDate: null,
        sdate: null,
        edate: null,
        dp: false,

        fromDate: computed('sdate', 'model', function () {
            var sdate = this.get('sdate');
            return !isEmpty(sdate) ? moment(sdate, 'X') : null;
        }).readOnly(),

        toDate: computed('edate', 'model', function () {
            var edate = this.get('edate');
            return !isEmpty(edate) ? moment(edate, 'X') : null;
        }).readOnly(),

        hasDateRange: computed('sdate', 'edate', function () {
            var _getProperties = this.getProperties('sdate', 'edate');

            var sdate = _getProperties.sdate;
            var edate = _getProperties.edate;

            return sdate !== null || edate !== null;
        }).readOnly(),

        dateTimePickerOptions: computed('user.dateTimeFormat', function () {
            var user = this.get('user');

            return {
                defaultSelect: true,
                format: user.get('dateTimeFormat').replace(':ss', ''),
                formatTime: user.get('timeFormat').replace(':ss', ''),
                formatDate: user.get('dateFormat')
            };
        }).readOnly(),

        // endregion

        // region Selected Missions

        m: new A([]),

        // endregion

        // region PDF & CSV Generation / Dialogs

        isGeneratingPDF: false,
        isGeneratingCSV: false,
        isShowingDialogError: false,

        triggerScroll: false,

        mapZoom: 8,

        isDialogVisible: computed('isGeneratingPDF', 'isGeneratingCSV', 'isShowingDialogError', 'dp', function () {
            var _getProperties2 = this.getProperties('isGeneratingPDF', 'isGeneratingCSV', 'isShowingDialogError', 'dp');

            var isGeneratingPDF = _getProperties2.isGeneratingPDF;
            var isGeneratingCSV = _getProperties2.isGeneratingCSV;
            var isShowingDialogError = _getProperties2.isShowingDialogError;
            var dp = _getProperties2.dp;

            return isGeneratingPDF || isGeneratingCSV || isShowingDialogError || dp;
        }),

        dialogTitle: computed('i18n.locale', 'isError', 'isLoading', 'dp', function () {
            var _getProperties3 = this.getProperties('i18n', 'isError', 'isLoading', 'dp');

            var i18n = _getProperties3.i18n;
            var isError = _getProperties3.isError;
            var isLoading = _getProperties3.isLoading;
            var dp = _getProperties3.dp;

            if (isError) {
                return this.get('error.title');
            } else if (isLoading) {
                return i18n.t('view.page.generating_pdf_loading');
            } else if (dp) {
                return i18n.t('calendar_title');
            }

            return "";
        }),

        // endregion

        // region Events & Alarms

        selectedMissionDataList: computed('selectedMission', 'readings', 'alarms', 'dt', function () {
            var _getProperties4 = this.getProperties('selectedMission', 'readings', 'alarms', 'dt');

            var selectedMission = _getProperties4.selectedMission;
            var readings = _getProperties4.readings;
            var alarms = _getProperties4.alarms;
            var dt = _getProperties4.dt;

            if (isEmpty(selectedMission)) {
                return null;
            }
            var dataArray = [];
            if (alarms && dt === Mission.DataType.SAVED_DATA) {
                dataArray = dataArray.concat(selectedMission.get('eventsList'));
            }
            if (readings) {
                dataArray = dataArray.concat(selectedMission.get('readingsList'));
            }
            return new A(dataArray);
        }),

        selectedMissionDataListSorted: computed('selectedMissionDataList', function () {
            var selectedMissionDataList = this.get('selectedMissionDataList');

            if (isEmpty(selectedMissionDataList)) {
                return new A([]);
            }

            var selectedMissionDataListSorted = selectedMissionDataList.filter(function (r) {
                return !isEmpty(r.get('date')) && !isNaN(r.get('date')) && !(r.get('isEvent') && r.get('type') === Mission.EventType.MISSION_GEOLOCATED);
            }).sort(function (a, b) {
                return a.get('date') - b.get('date');
            });
            return selectedMissionDataListSorted;
        }),

        did: null,

        selectedMissionData: computed('did', 'selectedMissionDataList', function () {
            var _getProperties5 = this.getProperties('did', 'selectedMissionDataList');

            var did = _getProperties5.did;
            var selectedMissionDataList = _getProperties5.selectedMissionDataList;

            if (!isEmpty(did) && !isEmpty(selectedMissionDataList)) {
                return this.get('selectedMissionDataList').findBy('id', this.get('did'));
            }

            return null;
        }),

        // endregion

        // region Map

        map: computed('m', function () {
            // this.get('gMap').maps.refresh('view-map');
            var mapWrapper = this.get('gMap').maps.select('view-map');

            if (!isEmpty(mapWrapper)) {
                return mapWrapper.map;
            }

            return null;
        }),

        isMapAvailable: computed('selectedMission', 'dt', 'isMultiView', function () {
            var m = this.get('selectedMission');

            if (this.get('isMultiView') || isEmpty(m)) {
                return false;
            }

            var isMapAvailable = m.get(this.get('dt') === Mission.DataType.LIVE_DATA ? 'readingsList' : 'eventsList').filterBy('hasGeocoordinates', true).length > 0;
            return isMapAvailable;
        }),

        mapMarkers: computed('selectedMission', 'selectedMissionData', 'dt', function () {
            var _this2 = this;

            var markers = new A([]);

            var _self = this;

            var mission = this.get('selectedMission');
            var smd = this.get('selectedMissionData');
            var dt = this.get('dt');
            var user = this.get('user');

            if (!isEmpty(mission)) {
                (function () {
                    var data = mission.get(dt === Mission.DataType.LIVE_DATA ? 'readingsList' : 'eventsList');
                    data.forEach(function (md) {
                        if (md.get('hasGeocoordinates')) {
                            var dateString = moment(md.get('date')).format(user.get('dateTimeFormat'));
                            var windowContent = "<p>" + dateString + "</p>";

                            if (md.get('isEvent')) {
                                var text = _this2.get('i18n').t("mission.event_type." + md.get('type'));
                                windowContent = "<p>" + text + "</p>" + windowContent;
                            }

                            var markerUrl = 'assets/images/oc-map-marker.png';

                            if (md.get('type') === Mission.EventType.MISSION_PROGRAMMED || md.get('type') === Mission.EventType.MISSION_STOPPED) {
                                markerUrl = 'assets/images/oc-map-marker-pink.png';
                            }

                            if (md.get('isReading') && (mission.get('highLimitEnabled') && md.get('measure') > mission.get('highLimit') || mission.get('lowLimitEnabled') && md.get('measure') < mission.get('lowLimit'))) {
                                markerUrl = 'assets/images/oc-map-marker-red.png';
                            }

                            markers.push({
                                id: md.get('id'),
                                lat: Number(dt === Mission.DataType.LIVE_DATA ? md.get('lat') : md.get('data2')),
                                lng: Number(dt === Mission.DataType.LIVE_DATA ? md.get('long') : md.get('data1')),
                                clickable: true,
                                cursor: 'pointer',
                                icon: {
                                    url: markerUrl,
                                    size: new google.maps.Size(67, 67),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(33.5, 33.5)
                                },

                                infoWindow: {
                                    content: windowContent,
                                    visible: !isEmpty(smd) && smd.get('id') === md.get('id')
                                },

                                click: function click(marker) {
                                    _self.send('selectMissionData', data.findBy('id', marker.id));
                                }
                            });
                        }
                    });
                })();
            }
            return markers;
        }),

        // TODO map polylines
        mapPolylines: computed('selectedMission', 'selectedMissionData', 'dt', function () {
            var path = [];
            var mission = this.get('selectedMission');
            var dt = this.get('dt');

            if (!isEmpty(mission)) {
                var data = mission.get(dt === Mission.DataType.LIVE_DATA ? 'readingsList' : 'eventsList');
                data.forEach(function (md) {
                    if (md.get('isEvent') && md.get('hasGeocoordinates')) {
                        var lat = Number(dt === Mission.DataType.LIVE_DATA ? md.get('lat') : md.get('data2'));
                        var lng = Number(dt === Mission.DataType.LIVE_DATA ? md.get('long') : md.get('data1'));
                        path.push([lat, lng]);
                    }
                });
            }

            return _ember["default"].A([{
                id: 'unique-polyline-id', // Recommended
                strokeColor: '#1abc9c',
                strokeOpacity: 0.8,
                strokeWeight: 4,
                path: path
            }]);
        }),

        handleMapMarkers: observer('mapMarkers', function () {
            later(this, this.computeMapPosition, MAP_REFRESH_TIME);
        }),

        computeMapPosition: function computeMapPosition() {
            var _this3 = this;

            this.get('logger').info(TAG, 'computeMapPosition');

            var map = this.get('map');

            if (isEmpty(map)) {
                return;
            }

            var markers = this.get('mapMarkers');
            var smd = this.get('selectedMissionData');

            if (!isEmpty(smd) && smd.get('hasGeocoordinates')) {
                var latLng = new google.maps.LatLng(smd.get(smd.get('isReading') ? 'lat' : 'data2'), smd.get(smd.get('isReading') ? 'long' : 'data1'));
                map.setCenter(latLng);

                new google.maps.MaxZoomService().getMaxZoomAtLatLng(latLng, function (response) {
                    if (isEmpty(_this3.get('map'))) {
                        return;
                    }

                    if (response.status !== 'OK') {
                        map.setZoom(map.mapTypes[map.getMapTypeId()].maxZoom);
                        map.setCenter(latLng);
                    } else {
                        map.setZoom(response.zoom);
                        map.setCenter(latLng);
                    }
                });
            } else if (!isEmpty(markers) && markers.length > 0) {
                (function () {
                    var bounds = new google.maps.LatLngBounds();
                    markers.forEach(function (m) {
                        return bounds.extend(new google.maps.LatLng(m.lat, m.lng));
                    });

                    later(_this3, function () {
                        map.fitBounds(bounds);
                    }, 1000);
                })();
            }
        },

        // endregion

        // region Graph

        isGraphAvailable: computed('totalReadings', function () {
            return this.get('totalReadings') > 1;
        }),

        isGraphExpandable: computed('window.isLargeUp', function () {
            return this.get('window.isMediumUp');
        }),

        graphData: computed('selectedMission', 'selectedMissions', 'selectedMissionData', 'fahrenheit', function () {
            var _this4 = this;

            var isFahrenheit = this.get('fahrenheit');
            var sm = this.get('selectedMission');
            var md = this.get('selectedMissionData');

            var isAllAlarmLimitsTheSame = true;
            var lowLimitValue = null;
            var highLimitValue = null;

            var data = [];

            this.get('selectedMissions').forEach(function (m) {
                //fetching all alarm limits, if all the same, we display the stripLines for high & low alarm limit
                highLimitValue = highLimitValue == null ? m.highLimit : highLimitValue;
                lowLimitValue = lowLimitValue == null ? m.lowLimit : lowLimitValue;
                if (lowLimitValue !== m.lowLimit || highLimitValue !== m.highLimit) {
                    isAllAlarmLimitsTheSame = false;
                }

                var dataPoints = [];
                var readings = m.get('readingsList').filter(function (r) {
                    return !isEmpty(r.get('date')) && !isNaN(r.get('date'));
                }).sort(function (a, b) {
                    return a.get('date') - b.get('date');
                });

                var selectedMarkerSize = 0.0005 * readings.length;
                selectedMarkerSize = selectedMarkerSize < 8 ? 8 : Math.round(selectedMarkerSize);

                readings.forEach(function (reading) {

                    if (!reading.get('isValid')) {
                        return;
                    }
                    var dp = {
                        x: reading.get('date'),
                        y: Number(convertTemperature(reading.get('measure'), isFahrenheit))
                    };

                    if (!isEmpty(md) && md.get('isReading') && reading.get('id') === md.get('id')) {
                        dp.markerSize = selectedMarkerSize;
                        dp.markerColor = '#000000';
                    }

                    dataPoints.push(dp);
                });

                data.push({
                    click: function click(e) {
                        if (_this4.get('isMultiView')) {
                            _this4.set('mid', e.dataSeries.id);
                        }
                    },
                    type: 'line',
                    lineThickness: !isEmpty(sm) && m.get('id') === sm.get('id') && _this4.get('isMultiView') ? 3 : 1,
                    showInLegend: false,
                    id: m.get('id'),
                    name: m.get('mid'),
                    markerType: "circle",
                    color: Color.fromString(m.get('mid')),
                    dataPoints: dataPoints
                });
            });
            this.set('isAllAlarmLimitsTheSame', isAllAlarmLimitsTheSame);
            this.set('lowLimitValue', lowLimitValue);
            this.set('highLimitValue', highLimitValue);
            return data;
        }),

        graphSuffix: computed('i18n.locale', 'fahrenheit', function () {
            var _getProperties6 = this.getProperties('i18n', 'fahrenheit');

            var i18n = _getProperties6.i18n;
            var fahrenheit = _getProperties6.fahrenheit;

            return i18n.t(!fahrenheit ? 'celsius' : 'fahrenheit');
        }),

        graphHighLimit: computed('selectedMission.highLimit', 'fahrenheit', function () {
            var isFahrenheit = this.get('fahrenheit');
            var limit = this.get('selectedMission.highLimit');

            if (isEmpty(limit)) {
                return null;
            }

            return convertTemperature(limit, isFahrenheit);
        }),

        graphLowLimit: computed('selectedMission.lowLimit', 'fahrenheit', function () {
            var isFahrenheit = this.get('fahrenheit');
            var limit = this.get('selectedMission.lowLimit');

            if (isEmpty(limit)) {
                return null;
            }

            return convertTemperature(limit, isFahrenheit);
        }),

        // endregion

        // region View Type

        view: null,

        handleViewChange: observer('view', function () {
            later(this, this.resizeMap, MAP_REFRESH_TIME);
            later(this, this.computeMapPosition, MAP_REFRESH_TIME);
        }),

        handleResize: on('resize', function () {
            later(this, this.resizeMap, MAP_REFRESH_TIME);
            later(this, this.computeMapPosition, MAP_REFRESH_TIME);

            if (parseInt(this.get('view')) === ModuleViewType.STATISTICAL && !this.get('isGraphExpandable')) {
                this.set('view', null);
            }
        }),

        resizeMap: function resizeMap() {
            this.get('gMap').maps.refresh('view-map');
        },

        isMapView: computed('view', function () {
            var view = this.get('view');
            return !isEmpty(view) && parseInt(this.get('view')) === ModuleViewType.MAP;
        }),

        isStatisticalView: computed('view', function () {
            var view = this.get('view');
            return !isEmpty(view) && parseInt(this.get('view')) === ModuleViewType.STATISTICAL;
        }),

        // endregion

        // region Mission

        missions: computed('model', function () {
            var missions = new A([]);
            this.get('model').forEach(function (module) {
                return missions.pushObjects(module.get('missions'));
            });
            return missions;
        }),

        mid: null,

        selectedMissions: computed('m', 'missions', function () {
            var _getProperties7 = this.getProperties('m', 'missions');

            var m = _getProperties7.m;
            var missions = _getProperties7.missions;

            return new A(missions.filter(function (mission) {
                return m.includes(mission.get('mid'));
            }));
        }),

        isMissionLimitReached: computed('selectedMissions', function () {
            return this.get('selectedMissions').length === Mission.MULTIVIEW_LIMIT;
        }),

        selectedMission: computed('mid', 'missions', 'isMultiView', function () {
            var mid = this.get('mid');

            var _getProperties8 = this.getProperties('m', 'missions', 'isMultiView');

            var m = _getProperties8.m;
            var missions = _getProperties8.missions;
            var isMultiView = _getProperties8.isMultiView;

            if (!isMultiView) {
                if (m.toArray().length === 1) {
                    mid = m[0];
                    // TODO alert("une seule mission selectionnee");
                }
            }
            return missions.findBy('mid', mid);
        }),

        selectedModule: computed('selectedMission', 'model', function () {
            var selectedMission = this.get('selectedMission');
            var selectedModule = null;
            if (!isEmpty(selectedMission)) {
                this.get('model').forEach(function (module) {
                    if (!isEmpty(module.get('missions').findBy('id', selectedMission.get('id')))) {
                        selectedModule = module;
                    }
                });
            }
            return selectedModule;
        }),

        handleSelectedMissionValidation: observer('selectedMission', 'missions', function () {
            var _getProperties9 = this.getProperties('selectedMission', 'missions');

            var selectedMission = _getProperties9.selectedMission;
            var missions = _getProperties9.missions;

            if (isEmpty(selectedMission)) {
                return;
            }
            if (isEmpty(missions) || isEmpty(missions.findBy('id', selectedMission.get('id')))) {
                this.set('mid', null);
            }
        }),

        isMultiView: computed('m', function () {
            return this.get('m').length > 1;
        }),

        totalReadings: computed('selectedMissions', function () {
            var readings = 0;
            this.get('selectedMissions').mapBy('readingsList').forEach(function (r) {
                return readings += r.length;
            });
            return readings;
        }),

        selectMissionDescription: computed('i18n.locale', function () {
            var i18n = this.get('i18n');

            var key = 'view.page.no_missions_selected_description';

            if (isAndroid()) {
                key = 'view.page.no_missions_selected_description_android';
            } else if (isIOS()) {
                key = 'view.page.no_missions_selected_description_ios';
            } else if (Modernizr.touchevents) {
                key = 'view.page.no_missions_selected_description_touch';
            }
            return i18n.t(key);
        }),

        // endregion

        forceRefreshModel: function forceRefreshModel() {
            this.get('logger').info('Forcing Model Refresh');

            this.send('refreshModel');
        },

        actions: {

            resetController: function resetController() {
                this._super.apply(this, arguments);
                this.get('logger').info(TAG, 'resetController');

                this.set('q', null);
                this.set('lms', true);
                this.set('t', null);
                this.set('at', MissionAlarmSearch.ALL_ALARMS);
                this.set('sdate', null);
                this.set('edate', null);
                this.set('tempSDate', null);
                this.set('tempEDate', null);
                this.set('m', new A([]));
                this.set('dp', false);

                this.set('isGeneratingPDF', false);
                this.set('isGeneratingCSV', false);

                this.set('mid', null);
                this.set('did', null);
                this.set('readings', true);
                this.set('alarms', true);

                this.set('dt', Mission.DataType.SAVED_DATA);
                this.set('view', null);

                this.set('isInfoExpanded', false);
                this.set('isGraphExpanded', true);

                this.handleShouldScroll();
            },

            handleMissionToggle: function handleMissionToggle(missionID) {
                this.set('rangeStart', null);
                this.set('rangeEnd', null);
                this.get('logger').info(TAG, "missionID: " + missionID);

                if (this.get('isLoading')) {
                    this.send('abortCurrentTransition');
                }

                this.set('mid', null);
                this.set('did', null);
                this.set('dt', Mission.DataType.SAVED_DATA);
                this.set('readings', true);
                this.set('alarms', true);

                var missions = this.get('m');
                var shouldReload = this.get('isLoading');

                if (missions.indexOf(missionID) > -1) {
                    missions.removeObject(missionID);
                } else {
                    if (this.get('isMissionLimitReached')) {
                        this.set('isShowingDialogError', true);
                        this.send('showError', null, 'check', null, 'view.page.max_missions_selected_error', 'view.page.max_missions_selected_error_description', true);
                        return;
                    }
                    missions.pushObject(missionID);
                    shouldReload = true;
                }

                if (shouldReload) {
                    this.send('showLoading');
                    debounce(this, this.forceRefreshModel, 500);
                }
            },

            zoomChanged: function zoomChanged() {
                this.get('logger').info(TAG, 'zoomChanged');

                if (this.get('mapZoom') < 3) {
                    this.set('mapZoom', 3);
                }
            },

            closeDialog: function closeDialog() {
                this.get('logger').info(TAG, 'closeDialog');

                this.send('hideError');
                this.send('hideLoading', false);

                this.setProperties({
                    isGeneratingPDF: false,
                    isGeneratingCSV: false,
                    isShowingDialogError: false,
                    dp: false,
                    tempSDate: null,
                    tempEDate: null
                });
            },

            openCalendar: function openCalendar() {
                this.get('logger').info(TAG, 'openCalendar');

                this.setProperties({
                    dp: true,
                    tempSDate: this.getWithDefault('sdate', null),
                    tempEDate: this.getWithDefault('edate', null)
                });
            },

            confirmCalendar: function confirmCalendar() {
                this.get('logger').info(TAG, 'confirmCalendar');

                this.send('abortCurrentTransition');

                this.set('sdate', this.getWithDefault('tempSDate', null));
                this.set('edate', this.getWithDefault('tempEDate', null));

                this.send('updateDateRange');
                this.setProperties({
                    dp: false,
                    tempSDate: null,
                    tempEDate: null
                });
            },

            periodTypeChanged: function periodTypeChanged() {
                var _this5 = this;

                this.get('logger').info(TAG, 'periodTypeChanged');
                if (this.get('d') === MissionTimePeriod.CALENDAR) {
                    this.send('openCalendar');
                } else {
                    this.send('abortCurrentTransition');
                    this.send('updateDateRange');
                    later(function () {
                        return _this5.send('refreshModel');
                    }, 200);
                }
            },

            generatePDF: function generatePDF() {
                var _this6 = this;

                var logger = this.get('logger');
                logger.info(TAG, 'generatePDF');

                var api = this.get('api');
                var mission = this.get('selectedMission');
                var isMultiView = this.get('isMultiView');
                var user = this.get('user');

                this.send('hideError');
                this.send('showLoading', false);
                this.set('isGeneratingPDF', true);

                var beginningDate = this.get('rangeStart') !== null ? moment(this.get('rangeStart')).format('YYYY-MM-DD HH:mm:ss') : '';
                var endingDate = this.get('rangeEnd') !== null ? moment(this.get('rangeEnd')).format('YYYY-MM-DD HH:mm:ss') : '';

                var apiCall = isMultiView ? api.getReportMultiGraph(this.get('selectedMissions').mapBy('mid'), beginningDate, endingDate, user.get('dateFormat'), user.get('unit')) : api.getMissionReport(mission, beginningDate, endingDate, user.get('unit'), 'pdf');
                apiCall.then(function (data) {
                    forceFileDownload(data, isMultiView ? "MultiViewPDF-" + moment().format('YYYYMMDD-HHmmss') : "" + mission.get('mid'), 'pdf', 'application/pdf');

                    _this6.send('hideLoading', false);
                    _this6.send('showError', null, 'check', null, 'view.page.pdf_generated_title', 'view.page.pdf_generated_description', true);
                })["catch"](function (reason) {
                    logger.error(TAG, reason);

                    _this6.send('hideLoading', false);
                    _this6.send('showNetworkError', 'view.page.pdf_failed_title', 'view.page.pdf_failed_description');
                });
            },

            generateXLS: function generateXLS() {
                var _this7 = this;

                var logger = this.get('logger');
                logger.info(TAG, 'generateXLS');

                var api = this.get('api');
                var mission = this.get('selectedMission');
                var isMultiView = this.get('isMultiView');
                var user = this.get('user');

                this.send('hideError');
                this.send('showLoading', false);
                this.set('isGeneratingPDF', true);

                var beginningDate = this.get('rangeStart') !== null ? moment(this.get('rangeStart')).format('YYYY-MM-DD HH:mm:ss') : '';
                var endingDate = this.get('rangeEnd') !== null ? moment(this.get('rangeEnd')).format('YYYY-MM-DD HH:mm:ss') : '';

                var apiCall = isMultiView ? api.getReportMultiGraph(this.get('selectedMissions').mapBy('mid'), beginningDate, endingDate, user.get('dateFormat'), user.get('unit'), 'xls') : api.getMissionReport(mission, beginningDate, endingDate, user.get('unit'), 'xls');
                apiCall.then(function (data) {
                    forceFileDownload(data, isMultiView ? "MultiViewXLS-" + moment().format('YYYYMMDD-HHmmss') : "" + mission.get('mid'), 'xls', 'application/vnd.ms-excel');

                    _this7.send('hideLoading', false);
                    _this7.send('showError', null, 'check', null, 'view.page.pdf_generated_title', 'view.page.pdf_generated_description', true);
                })["catch"](function (reason) {
                    logger.error(TAG, reason);

                    _this7.send('hideLoading', false);
                    _this7.send('showNetworkError', 'view.page.pdf_failed_title', 'view.page.pdf_failed_description');
                });
            },

            generateCVS: function generateCVS() {
                var _this8 = this;

                var logger = this.get('logger');
                logger.info(TAG, 'generateCVS');

                var i18n = this.get('i18n');

                var _getProperties10 = this.getProperties('alarms', 'readings', 'dt', 'isMultiView', 'user');

                var alarms = _getProperties10.alarms;
                var readings = _getProperties10.readings;
                var dt = _getProperties10.dt;
                var isMultiView = _getProperties10.isMultiView;
                var user = _getProperties10.user;

                var isFahrenheit = this.get('fahrenheit');

                // TODO doing CVS
                var beginningDate = this.get('rangeStart') !== null ? parseInt(this.get('rangeStart')) : null;
                var endingDate = this.get('rangeEnd') !== null ? parseInt(this.get('rangeEnd')) : null;

                this.send('hideError');
                this.send('showLoading', false);
                this.set('isGeneratingCSV', true);

                later(this, function () {
                    Rx.Observable.from(_this8.get('selectedMissions')).observeOn(Rx.Scheduler["default"]).filter(function (m) {
                        return m;
                    }).map(function (mission) {
                        var dataList = [];

                        if (alarms && dt === Mission.DataType.SAVED_DATA) {
                            dataList = dataList.concat(mission.get('eventsList').sort(function (a, b) {
                                return a.get('date') - b.get('date');
                            }));
                        }

                        if (readings) {
                            dataList = dataList.concat(mission.get('readingsList').sort(function (a, b) {
                                return a.get('date') - b.get('date');
                            }));
                        }

                        var tempDataList = [];
                        if (beginningDate != null && endingDate != null) {
                            dataList.forEach(function (element) {
                                if (element.date > beginningDate && element.date < endingDate) {
                                    tempDataList.push(element);
                                }
                            });
                            dataList = tempDataList;
                        }

                        // Create the CVS Data Array
                        var data = [[i18n.t('cvs_template.type'), i18n.t('cvs_template.date'), i18n.t('cvs_template.time'), i18n.t('cvs_template.datetime'), i18n.t('cvs_template.value'), i18n.t('cvs_template.info1'), i18n.t('cvs_template.info2')]];

                        if (!isEmpty(dataList)) {
                            for (var i = 0; i < dataList.length; i++) {
                                // const cellPos = i + 2;
                                var dataEl = dataList[i];
                                var isEvent = dataEl.get('isEvent') || false;
                                var date = dataEl.get('date');
                                var replaceWith = '.';

                                if (_this8.get('comma')) {
                                    replaceWith = ',';
                                }

                                data.push([isEvent ? 'E/A' : 'R', moment(date).format(user.get('dateFormat')), moment(date).format(user.get('timeFormat')), moment(date).format(user.get('dateTimeFormat')), isEvent ? i18n.t("mission.event_type." + dataEl.get('type')) : convertTemperature(dataEl.get('measure'), isFahrenheit).replace('.', replaceWith), isEvent ? dataEl.get('isAlarm') ? 'NaN' : dataEl.get('data1') || 'NaN' : String.fromCharCode(176) + (isFahrenheit ? 'F' : 'C'), isEvent ? dataEl.get('data2') || 'NaN' : '']);
                            }
                        }

                        // Generate the CVS
                        var cvsContent = '';
                        data.forEach(function (infoArray, index) {
                            var dataString = "";
                            infoArray.forEach(function (v, i) {
                                dataString += "\"" + v + "\"";
                                if (i < infoArray.length - 1) {
                                    dataString += '\t';
                                }
                            });

                            cvsContent += index < data.length ? dataString + '\n' : dataString;
                        });

                        return {
                            csv: cvsContent,
                            mission: mission
                        };
                    }).toArray().subscribe(function (data) {

                        if (data.length === 1) {
                            forceFileDownload(toUTF16LEArray(data[0].csv, true), data[0].mission.get('mid'), 'csv', 'text/csv', 'UTF-16LE');

                            _this8.send('hideLoading', false);
                            _this8.send('showError', null, 'check', null, 'view.page.pdf_generated_title', 'view.page.pdf_generated_description', true);
                        } else if (data.length > 1) {
                            (function () {
                                var zip = new _npmJszip["default"]();

                                data.forEach(function (d) {
                                    zip.file(d.mission.get('mid') + ".csv", new Blob([toUTF16LEArray(d.csv, true)], { type: "text/csv;charset=UTF-16LE" }), { type: 'blob' });
                                });

                                zip.generateAsync({ type: "blob" }).then(function (content) {
                                    forceFileDownload(content, "MultiViewCSV-" + moment().format('YYYYMMDD-HHmmss'), 'zip', 'application/octet-stream', 'UTF-16LE');

                                    _this8.send('hideLoading', false);
                                    _this8.send('showError', null, 'check', null, 'view.page.pdf_generated_title', 'view.page.pdf_generated_description', true);
                                });
                            })();
                        }
                    });
                }, 100);
            },

            selectMissionData: function selectMissionData(missionData) {
                this.get('logger').info(TAG, 'selectMissionData');
                if (!isEmpty(missionData)) {
                    this.set('did', missionData.get('id'));
                }
            },

            updateFromDate: function updateFromDate(date) {
                this.get('logger').info(TAG, 'updateFromDate ' + date);
                this.set('tempSDate', isEmpty(date) ? null : moment(date).unix());
            },

            updateToDate: function updateToDate(date) {
                this.get('logger').info(TAG, 'updateToDate ' + date);
                this.set('tempEDate', isEmpty(date) ? null : moment(date).unix());
            },

            updateMissionFromDate: function updateMissionFromDate(date) {
                this.get('logger').info(TAG, 'updateMissionFromDate ' + date);
                var timeStamp = isEmpty(date) ? null : moment(date).unix() * 1000;
                if (timeStamp != null && this.get('rangeEnd') != null && timeStamp >= this.get('rangeEnd')) {
                    timeStamp = null;
                }
                this.set('rangeStart', timeStamp);
            },

            updateMissionToDate: function updateMissionToDate(date) {
                this.get('logger').info(TAG, 'updateMissionToDate ' + date);
                var timeStamp = isEmpty(date) ? null : moment(date).unix() * 1000;
                if (timeStamp != null && this.get('rangeStart') != null && timeStamp <= this.get('rangeStart')) {
                    timeStamp = null;
                }
                this.set('rangeEnd', timeStamp);
            },

            updateDateRange: function updateDateRange() {
                this.get('logger').info(TAG, 'updateDateRange');

                if (this.get('hasDateRange')) {
                    this.set('mid', null);
                    this.set('did', null);
                    this.set('readings', true);
                    this.set('alarms', true);
                }
            },

            toggleMapView: function toggleMapView() {
                this.get('logger').info(TAG, 'toggleMapView');
                var currentValue = parseInt(this.get('view'));
                var newValue = isEmpty(currentValue) || currentValue !== ModuleViewType.MAP ? ModuleViewType.MAP : null;
                this.set('view', newValue);
            },

            toggleStatisticalView: function toggleStatisticalView() {
                this.get('logger').info(TAG, 'toggleStatisticalView');
                var currentValue = parseInt(this.get('view'));
                var newValue = isEmpty(currentValue) || currentValue !== ModuleViewType.STATISTICAL ? ModuleViewType.STATISTICAL : null;
                this.set('view', newValue);
            },

            downloadGraphImage: function downloadGraphImage() {
                this.get('logger').info(TAG, 'downloadGraphImage');
                $('.canvasjs-chart-canvas')[0].toBlob(function (blob) {
                    saveAs(blob, "Graph-" + moment().format('YYYYMMDD-HHmmss') + ".png");
                });
            },

            adaptScale: function adaptScale() {
                if (this.get("adaptScale") === true) {
                    this.set("adaptScale", false);
                } else {
                    this.set("adaptScale", true);
                }
            },

            mapLoaded: function mapLoaded() {
                this.get('logger').info(TAG, 'mapLoaded');

                later(this, this.computeMapPosition, MAP_REFRESH_TIME);
                var map = this.get('map');

                if (!isEmpty(map)) {
                    map.setOptions({ 'minZoom': 3 });
                }
            },

            getModuleColor: function getModuleColor(module) {
                switch (module.get('state')) {
                    case State.ALARMS:
                        return '#ff0000';
                    case State.NO_ALARMS:
                        return '#33ff33';
                    case State.TECHNICAL_ALARMS:
                        return '#ff9933';
                    case State.NO_MISSIONS:
                        return '#999999';
                }
            },

            onChanged: function onChanged() {
                this.set('isLoading', true);
                _ember["default"].run.later(this, function () {
                    this.set('isLoading', false);
                }, 1500);
            }
        }
    });
});