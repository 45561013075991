define("oceasoft-web/session-stores/application", ["exports", "ember", "oceasoft-web/objects/utils", "ember-simple-auth/session-stores/base", "ember-simple-auth/session-stores/cookie", "oceasoft-web/session-stores/encrypted-local-storage"], function (exports, _ember, _oceasoftWebObjectsUtils, _emberSimpleAuthSessionStoresBase, _emberSimpleAuthSessionStoresCookie, _oceasoftWebSessionStoresEncryptedLocalStorage) {

  var LOCAL_STORAGE_TEST_KEY = '_ember_simple_auth_test_key';
  var computed = _ember["default"].computed;
  var _Ember$run = _ember["default"].run;
  var later = _Ember$run.later;
  var cancel = _Ember$run.cancel;
  var testing = _ember["default"].testing;
  var equals = _oceasoftWebObjectsUtils["default"].Object.equals;

  // region Cookie Overwrite

  _emberSimpleAuthSessionStoresCookie["default"].reopen({
    _syncData: function _syncData() {
      var _this = this;

      return this.restore().then(function (data) {
        if (!equals(data, _this._lastData)) {
          _this._lastData = data;
          _this.trigger('sessionDataUpdated', data);
        }
        if (!testing) {
          cancel(_this._syncDataTimeout);
          _this._syncDataTimeout = later(_this, _this._syncData, 500);
        }
      });
    }
  });

  // endregion

  exports["default"] = _emberSimpleAuthSessionStoresBase["default"].extend({

    localStorageKey: '_oc-session',

    cookieDomain: null,
    cookieName: '_oc-session',
    cookieExpirationTime: null,

    // region Base

    init: function init() {
      this._super.apply(this, arguments);
      var isLocalStorageAvailable = this.get('_isLocalStorageAvailable');
      this.set('_store', this._createStore(isLocalStorageAvailable ? _oceasoftWebSessionStoresEncryptedLocalStorage["default"] : _emberSimpleAuthSessionStoresCookie["default"], isLocalStorageAvailable ? { key: this.get('localStorageKey') } : this.getProperties('cookieDomain', 'cookieName', 'cookieExpirationTime')));
    },

    persist: function persist() {
      var _get;

      return (_get = this.get('_store')).persist.apply(_get, arguments);
    },

    restore: function restore() {
      return this.get('_store').restore();
    },

    clear: function clear() {
      return this.get('_store').clear();
    },

    // endregion

    // region Utility Functions

    _isLocalStorageAvailable: computed(function () {
      try {
        localStorage.setItem(LOCAL_STORAGE_TEST_KEY, true);
        localStorage.removeItem(LOCAL_STORAGE_TEST_KEY);
        return true;
      } catch (e) {
        return false;
      }
    }),

    _createStore: function _createStore(storeType, options) {
      var _this2 = this;

      var store = storeType.create(options);

      store.on('sessionDataUpdated', function (data) {
        _this2.trigger('sessionDataUpdated', data);
      });
      return store;
    }

    // endregion

  });
});
/* global localStorage */