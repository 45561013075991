define('oceasoft-web/helpers/is-equal', ['exports', 'ember-date-components/helpers/is-equal'], function (exports, _emberDateComponentsHelpersIsEqual) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqual['default'];
    }
  });
  Object.defineProperty(exports, 'isEqual', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqual.isEqual;
    }
  });
});