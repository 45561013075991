define('oceasoft-web/components/oc-content', ['exports', 'ember'], function (exports, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'oc-content',
    classNameBindings: ['padding::no-padding', 'shadow'],

    router: service('-routing'),

    padding: true,
    shadow: false,
    scrollable: true,

    scrollTop: function scrollTop() {
      if (!this.get('scrollable')) {
        return;
      }
      var scrollContainer = this.$('.scroll .ps-content');

      if (!isEmpty(scrollContainer)) {
        scrollContainer.scrollTop(0);
      }
    }
  });
});