define("oceasoft-web/components/oc-chart", ["exports", "ember", "oceasoft-web/objects/constants", "moment", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsConstants, _moment, _oceasoftWebObjectsUtils) {
    var observer = _ember["default"].observer;
    var once = _ember["default"].run.once;
    var isEmpty = _ember["default"].isEmpty;
    var computed = _ember["default"].computed;
    var _Constants$User = _oceasoftWebObjectsConstants["default"].User;
    var TimeFormat = _Constants$User.TimeFormat;
    var DateFormat = _Constants$User.DateFormat;
    var DecimalSeparator = _oceasoftWebObjectsConstants["default"].DecimalSeparator;
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Component.extend({
        tagName: 'oc-chart',

        i18n: service('i18n'),

        chart: null,
        data: [],

        rangeStart: null,
        rangeEnd: null,
        isAllAlarmLimitsTheSame: undefined,
        lowLimitValue: undefined,
        highLimitValue: undefined,
        adaptScale: false,

        decimalSeparator: DecimalSeparator.POINT,
        dateFormat: DateFormat.DMY,
        timeFormat: TimeFormat.MILITARY,
        valueSuffix: null,

        dateTimeFormat: computed('dateFormat', 'timeFormat', function () {
            return [this.get('dateFormat'), this.get('timeFormat')].join(' ');
        }).readOnly(),

        highLimit: null,
        lowLimit: null,
        highLimitEnabled: true,
        lowLimitEnabled: true,

        limits: computed('data', 'highLimit', 'lowLimit', function () {
            var data = this.get('data');

            var minY = this.get('lowLimit') || 0;
            var maxY = this.get('highLimit') || 100;
            var intervalY = null;

            var minX = 0;
            var maxX = 100;
            var intervalX = null;

            if (!isEmpty(data) && data.length > 0) {
                var i;

                (function () {
                    minY = null;
                    maxY = null;
                    minX = null;
                    maxX = null;

                    // Calculate Y Axis
                    var valuesY = [];
                    var valuesX = [];

                    data.forEach(function (d) {
                        if (!isEmpty(d.dataPoints)) {
                            valuesY = valuesY.concat(d.dataPoints.map(function (v) {
                                return v.y;
                            }));
                            valuesX = valuesX.concat(d.dataPoints.map(function (v) {
                                return v.x;
                            }));
                        }
                    });

                    // Calculate and use min and max values

                    for (i = 0; i < valuesY.length; i++) {
                        if (maxY === null || valuesY[i] > maxY) {
                            maxY = valuesY[i];
                        }

                        if (minY === null || valuesY[i] < minY) {
                            minY = valuesY[i];
                        }
                    }

                    for (i = 0; i < valuesX.length; i++) {
                        if (maxX === null || valuesX[i] > maxX) {
                            maxX = valuesX[i];
                        }

                        if (minX === null || valuesX[i] < minX) {
                            minX = valuesX[i];
                        }
                    }

                    // Calculate Interval
                    // We do this to get better interval lines for our readings. The default library method doesn't do perfect calculations for this case.
                    var intervals = [];
                    for (i = 0; i < valuesY.length - 1; i++) {
                        intervals.push(Number(Math.abs(valuesY[i] - valuesY[i + 1]).toFixed(2)));
                    }

                    intervalY = Math.floor((maxY - minY) / 6);

                    if ((maxY - minY) / 6 < 0.1) {
                        intervalY = 0.1;
                    }

                    minY = minY - (maxY - minY) / 10 - intervalY;
                    maxY = maxY + (maxY - minY) / 10 + intervalY;

                    minX = minX - (maxX - minX) / 80 - intervalX;
                    maxX = maxX + (maxX - minX) / 80 + intervalX;
                })();
            }

            return {
                x: {
                    min: minX,
                    max: maxX,
                    interval: intervalX
                },

                y: {
                    min: minY,
                    max: maxY,
                    interval: intervalY
                }
            };
        }),

        changed: false,

        didInsertElement: function didInsertElement() {
            once(this, 'renderChart');
        },

        handleChange: observer('changed', function () {
            once(this, 'renderChart');
        }),

        handleDataChange: observer('data', 'valueSuffix', 'highLimit', 'lowLimit', 'dateFormat', 'timeFormat', 'changed', 'adaptScale', 'rangeStart', 'rangeEnd', function () {
            once(this, 'configureChartData');
        }),

        configureChartData: function configureChartData() {
            var _getProperties = this.getProperties('chart', 'data', 'valueSuffix', 'limits', 'highLimit', 'lowLimit', 'highLimitEnabled', 'lowLimitEnabled', 'decimalSeparator', 'dateFormat', 'timeFormat', 'rangeStart', 'rangeEnd');

            var chart = _getProperties.chart;
            var data = _getProperties.data;
            var valueSuffix = _getProperties.valueSuffix;
            var limits = _getProperties.limits;
            var highLimit = _getProperties.highLimit;
            var lowLimit = _getProperties.lowLimit;
            var highLimitEnabled = _getProperties.highLimitEnabled;
            var lowLimitEnabled = _getProperties.lowLimitEnabled;
            var decimalSeparator = _getProperties.decimalSeparator;
            var dateFormat = _getProperties.dateFormat;
            var timeFormat = _getProperties.timeFormat;
            var rangeStart = _getProperties.rangeStart;
            var rangeEnd = _getProperties.rangeEnd;

            if (!isEmpty(chart)) {
                chart.options.axisY.suffix = " " + valueSuffix;
                chart.options.axisY.minimum = limits.y.min;
                chart.options.axisY.maximum = limits.y.max;
                chart.options.axisY.interval = limits.y.interval;
                chart.options.axisY.stripLines = [];

                chart.options.axisX.minimum = limits.x.min;
                chart.options.axisX.maximum = limits.x.max;
                // chart.options.axisX.interval = limits.x.interval;

                var unit = valueSuffix.string;
                var isFahrenheit = unit === '°F';
                var isAllAlarmLimitsTheSame = this.get('isAllAlarmLimitsTheSame');

                var highLimitToDisplay = highLimit || _oceasoftWebObjectsUtils["default"].Other.convertTemperature(this.get('highLimitValue'), isFahrenheit);
                var lowLimitToDisplay = lowLimit || _oceasoftWebObjectsUtils["default"].Other.convertTemperature(this.get('lowLimitValue'), isFahrenheit);

                if (isAllAlarmLimitsTheSame) {
                    if (!isEmpty(highLimitToDisplay) && highLimitEnabled !== false) {
                        chart.options.axisY.stripLines.push({
                            value: highLimitToDisplay,
                            color: "red",
                            thickness: 2,
                            label: "High Limit",
                            labelFontColor: "red",
                            opacity: 0.5
                        });
                    }

                    if (!isEmpty(lowLimitToDisplay) && lowLimitEnabled !== false) {
                        chart.options.axisY.stripLines.push({
                            value: lowLimitToDisplay,
                            color: "blue",
                            thickness: 2,
                            label: "Low Limit",
                            labelFontColor: "blue",
                            opacity: 0.5
                        });
                    }
                }

                if (highLimitEnabled && !isEmpty(highLimit)) {
                    chart.options.axisY.stripLines.push({
                        value: highLimit,
                        color: "red",
                        thickness: 2,
                        label: "High Limit",
                        labelFontColor: "red",
                        opacity: 0.5
                    });
                }

                if (lowLimitEnabled && !isEmpty(lowLimit)) {
                    chart.options.axisY.stripLines.push({
                        value: lowLimit,
                        color: "blue",
                        thickness: 2,
                        label: "Low Limit",
                        labelFontColor: "blue",
                        opacity: 0.5
                    });
                }

                if (this.get("adaptScale") === true && highLimitEnabled !== false && highLimitEnabled !== false) {
                    var step = unit !== '°C' ? 2 : 1; // simply because rH & °F have ofter a bigger range
                    var maximum = parseInt(highLimitToDisplay) + step;
                    var minimum = parseInt(lowLimitToDisplay) - step;
                    if (maximum > chart.options.axisY.maximum && highLimitEnabled !== false) {
                        chart.options.axisY.maximum = maximum;
                    }

                    if (minimum < chart.options.axisY.minimum && highLimitEnabled !== false) {
                        chart.options.axisY.minimum = minimum;
                    }

                    var amplitude = chart.options.axisY.maximum - chart.options.axisY.minimum;
                    var interval = parseInt(amplitude / 5);
                    interval = interval < 1 ? 1 : interval;
                    chart.options.axisY.interval = interval;
                }

                if (rangeEnd <= rangeStart) {
                    this.set('rangeEnd', null);
                }

                if (rangeStart != null) {
                    if (!Number.isInteger(rangeStart)) {
                        this.set('rangeStart', null);
                    }
                    chart.options.axisX.viewportMinimum = rangeStart;
                } else {
                    chart.options.axisX.viewportMinimum = chart.options.axisX.minimum;
                }

                if (rangeEnd != null) {
                    if (!Number.isInteger(rangeEnd)) {
                        this.set('rangeEnd', null);
                    }
                    chart.options.axisX.viewportMaximum = rangeEnd;
                } else {
                    chart.options.axisX.viewportMaximum = chart.options.axisX.maximum;
                }

                chart.options.toolTip = {
                    contentFormatter: function contentFormatter(e) {
                        var value = String(e.entries[0].dataPoint.y);
                        if (decimalSeparator === DecimalSeparator.COMMA) {
                            value = value.replace(DecimalSeparator.POINT, DecimalSeparator.COMMA);
                        }

                        return "<div class=\"chartDataCenter\">" + value + " " + valueSuffix + "</div><div class=\"chartDataCenter\">" + (0, _moment["default"])(e.entries[0].dataPoint.x).format(timeFormat) + "</div><div class=\"chartDataCenter\">" + (0, _moment["default"])(e.entries[0].dataPoint.x).format(dateFormat) + "</div>";
                    }
                };

                chart.options.data = data;
                chart.render();
            }
        },

        renderChart: function renderChart() {

            var lng = this.get('i18n.locale').toUpperCase();

            var _getProperties2 = this.getProperties('dateFormat', 'timeFormat', 'decimalSeparator');

            var dateFormat = _getProperties2.dateFormat;
            var timeFormat = _getProperties2.timeFormat;
            var decimalSeparator = _getProperties2.decimalSeparator;

            var currentInstance = this;

            var chart = new CanvasJS.Chart('chart', {
                zoomEnabled: true,
                zoomType: "xy",
                rangeChanged: function rangeChanged(e) {
                    currentInstance.set('rangeStart', parseInt(e.axisX.viewportMinimum));
                    currentInstance.set('rangeEnd', parseInt(e.axisX.viewportMaximum));
                    this.render();
                },

                panEnabled: true,

                title: {
                    text: ""
                },

                legend: {
                    horizontalAlign: "right",
                    verticalAlign: "center"
                },

                animationEnabled: false,
                culture: lng,

                axisX: {
                    labelFormatter: function labelFormatter(e) {
                        return (0, _moment["default"])(e.value).format(timeFormat) + ' ' + (0, _moment["default"])(e.value).format(dateFormat);
                    },
                    labelAutoFit: true,
                    labelAngle: 0,
                    labelFontFamily: "'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif",
                    labelFontSize: 12,
                    intervalType: "hour"
                },

                axisY: {
                    includeZero: true,
                    valueFormatString: '#.##',
                    labelAutoFit: true,
                    labelFormatter: function labelFormatter(e) {
                        var formattedValue = Number(e.value).toFixed(1);

                        if (decimalSeparator === DecimalSeparator.COMMA) {
                            formattedValue = formattedValue.replace(DecimalSeparator.POINT, DecimalSeparator.COMMA);
                        }

                        return formattedValue % 1 === 0 ? formattedValue - formattedValue % 1 : formattedValue;
                    },
                    labelFontFamily: "'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif",
                    labelFontSize: 13
                }

            });

            this.set('chart', chart);
            once(this, 'configureChartData');
        }
    });
});