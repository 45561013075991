define("oceasoft-web/routes/view", ["exports", "ember", "oceasoft-web/mixins/root-route", "ember-simple-auth/mixins/authenticated-route-mixin", "oceasoft-web/objects/constants", "ember-i18n", "oceasoft-web/objects/mission"], function (exports, _ember, _oceasoftWebMixinsRootRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _oceasoftWebObjectsConstants, _emberI18n, _oceasoftWebObjectsMission) {

    var TAG = 'view route';
    var service = _ember["default"].inject.service;
    var _Ember$RSVP = _ember["default"].RSVP;
    var Promise = _Ember$RSVP.Promise;
    var all = _Ember$RSVP.all;
    var isEmpty = _ember["default"].isEmpty;
    var A = _ember["default"].A;
    var maxNumberOfListedItems = _oceasoftWebObjectsConstants["default"].maxNumberOfListedItems;
    var Mission = _oceasoftWebObjectsConstants["default"].Mission;
    var MissionTimePeriod = _oceasoftWebObjectsConstants["default"].Module.MissionTimePeriod;
    var NULL_DATE = _oceasoftWebObjectsConstants["default"].API.NULL_DATE;
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _oceasoftWebMixinsRootRoute["default"], {
        components: ['topbar', 'navigation'],

        too_many_items_message: (0, _emberI18n.translationMacro)('view.page.too_many_result'),

        queryParams: {
            q: {
                replace: true
            },

            lms: {
                replace: true
            },

            t: {
                replace: true
            },

            d: {
                replace: true,
                refreshModel: false
            },

            at: {
                replace: true
            },

            sdate: {
                replace: true,
                refreshModel: true
            },

            edate: {
                replace: true,
                refreshModel: true
            },

            m: {
                replace: true,
                refreshModel: false
            },

            view: {
                replace: true
            },

            isDatePicker: {
                replace: true
            },

            mid: {
                replace: true
            },

            readings: {
                replace: true
            },

            alarms: {
                replace: true
            },

            dt: {
                replace: true,
                refreshModel: true
            },

            did: {
                replace: true
            },

            dp: {
                replace: true
            }
        },

        api: service('oc-api'),

        titleToken: (0, _emberI18n.translationMacro)('view.page.title'),

        pendingTransition: null,

        model: function model(params) {
            var _this = this;

            var api = this.get('api');

            var dateType = params.d;

            var currentDate = new Date();

            switch (dateType) {
                case MissionTimePeriod.ALL:
                    {
                        params.sdate = null;
                        params.edate = null;
                        break;
                    }

                case MissionTimePeriod.LAST_DAY:
                    {
                        params.sdate = new Date(currentDate / 1000) - 86400;
                        params.edate = null;
                        break;
                    }

                case MissionTimePeriod.LAST_WEEK:
                    {
                        params.sdate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7) / 1000;
                        params.edate = null;
                        break;
                    }

                case MissionTimePeriod.LAST_MONTH:
                    {
                        params.sdate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()) / 1000;
                        params.edate = null;
                        break;
                    }

                case MissionTimePeriod.CALENDAR:
                    {
                        if (isEmpty(params.sdate) && !isEmpty(params.edate)) {
                            params.sdate = NULL_DATE / 1000;
                        }
                        if (isEmpty(params.sdate) && isEmpty(params.edate)) {
                            //params.d = MissionTimePeriod.LAST_WEEK;
                            params.sdate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7) / 1000;
                            params.edate = null;
                        }
                        break;
                    }
            }

            if (!isEmpty(params.sdate) && isEmpty(params.edate)) {
                params.edate = currentDate / 1000;
            }

            return new Promise(function (resolve, reject) {

                all([api.listEmerald(), api.listUser()]).then(function (data) {
                    var emeralds = data[0];
                    if (isEmpty(emeralds)) {
                        resolve(emeralds);
                    }

                    var users = data[1];

                    /**
                     * Defining DataPromise = the readings
                     * (params.m = array of mission ID selected)
                     */
                    var dataPromisses = [];
                    params.m.forEach(function (mid) {
                        if (params.dt === Mission.DataType.LIVE_DATA) {
                            dataPromisses.push(all([Promise.resolve([]), api.getReadingsWatchMode(mid)]));
                        } else {
                            dataPromisses.push(all([api.listEvents(mid), api.listReadings(mid)]));
                        }
                    });

                    var startDate = null;
                    var endDate = null;
                    if (!isEmpty(params.sdate) && !isEmpty(params.edate)) {
                        startDate = new Date(Number(params.sdate) * 1000 + new Date().getTimezoneOffset() * 60 * 1000);
                        endDate = new Date(Number(params.edate) * 1000 + new Date().getTimezoneOffset() * 60 * 1000);
                    }

                    /**
                     * getAllModulesAndMissionsByDate
                     * Get all content (modules & missions) in only 1 request
                     * @var allModulesAndMissions
                     */
                    // TODO utiliser la méthode ListMissionByDateProgCount pour vérifier si on traite la requête ou s'il
                    // TODO est préférable de refuser la requête et d'informer l'utilisateur au risque de faire planter le navigateur
                    api.getAllModulesAndMissionsByDateCount(startDate, endDate).then(function (response) {
                        var missionCount = 0;
                        var sensorCount = 0;
                        if (typeof response[0] !== 'undefined' && typeof response[0].MissionCount !== 'undefined' && typeof response[0].SensorCount !== 'undefined') {
                            missionCount = parseInt(response[0].MissionCount);
                            sensorCount = parseInt(response[0].SensorCount);
                        }

                        if (params.d === MissionTimePeriod.LAST_DAY || params.d === MissionTimePeriod.LAST_WEEK || !isNaN(missionCount) && !isNaN(sensorCount) && missionCount + sensorCount < maxNumberOfListedItems) {
                            // do the request
                            getAllModulesAndMissionsByDate(startDate, endDate);
                        } else {
                            // inform the user that we cancelled his request, replacing it by the last week period
                            var message = _this.get('too_many_items_message');
                            alert(message);
                            _this.transitionTo('/view');
                        }
                    });

                    function getAllModulesAndMissionsByDate(startDate, endDate) {
                        api.getAllModulesAndMissionsByDate(startDate, endDate).then(function (allModulesAndMissions) {

                            function getMissionsFromSerial(serial) {
                                var result = allModulesAndMissions.filter(function (value) {
                                    value.SerialNumber = typeof value.SerialNumber !== 'undefined' ? value.SerialNumber : '';
                                    return value.SerialNumber.toUpperCase() === serial.toUpperCase();
                                });
                                if (typeof result !== 'undefined' && typeof result[0] !== 'undefined' && typeof result[0].Missions !== 'undefined') {
                                    return result[0].Missions;
                                }
                                return false;
                            }

                            function getModuleAlarmStateFromSerial(serial) {
                                var result = allModulesAndMissions.filter(function (value) {
                                    value.SerialNumber = typeof value.SerialNumber !== 'undefined' ? value.SerialNumber : '';
                                    return value.SerialNumber.toUpperCase() === serial.toUpperCase();
                                });
                                if (typeof result !== 'undefined' && typeof result[0] !== 'undefined' && typeof result[0].CurrentMissionStateAlarm !== 'undefined') {
                                    return result[0].CurrentMissionStateAlarm;
                                }
                                return false;
                            }

                            function getUserFullNameFromId(userId) {
                                if (userId === 0) {
                                    return '';
                                }

                                var result = users.filter(function (value) {
                                    return value.userId === userId;
                                });

                                if (typeof result !== 'undefined' && typeof result[0] !== 'undefined' && typeof result[0].lastName !== 'undefined') {
                                    return result[0].lastName + ' ' + result[0].firstName;
                                }

                                return '';
                            }

                            var missionPromisses = [];
                            var moduleAlarmStatePromisses = [];
                            emeralds.forEach(function (emerald) {
                                var mappedMissionsFromDevice = Promise.resolve(api.mapDataOnObject(getMissionsFromSerial(emerald.get('serial')), _oceasoftWebObjectsMission["default"]));
                                var moduleAlarmState = getModuleAlarmStateFromSerial(emerald.get('serial'));
                                moduleAlarmStatePromisses.push(moduleAlarmState);
                                missionPromisses.push(mappedMissionsFromDevice);
                            });

                            /**
                             * Assign Readings to selected Missions
                             * + Define Device State
                             */
                            all([all(missionPromisses), all(dataPromisses), all(moduleAlarmStatePromisses)]).then(function (data) {
                                emeralds.forEach(function (emerald, i) {
                                    if (data !== null && data[0][i] !== null && typeof data[0][i].forEach !== 'undefined') {
                                        data[0][i].forEach(function (m) {
                                            m.state = parseInt(m.state);

                                            var missionId = m.get('mid');
                                            var indexOfMissionId = params.m.indexOf(missionId);

                                            if (indexOfMissionId > -1) {
                                                if (typeof data[1] !== 'undefined' && typeof data[1][indexOfMissionId] !== 'undefined') {

                                                    if (typeof data[1][indexOfMissionId][0] !== 'undefined') {
                                                        data[1][indexOfMissionId][0].forEach(function (value) {
                                                            value.userFullName = getUserFullNameFromId(value.user);
                                                        });
                                                        m.set('eventsList', new A(data[1][indexOfMissionId][0]));
                                                    }

                                                    if (typeof data[1][indexOfMissionId][1] !== 'undefined') {
                                                        m.set('readingsList', new A(data[1][indexOfMissionId][1]));
                                                    }
                                                }
                                            }
                                        });
                                        emerald.set('missions', data[0][i]);
                                        emerald.set('CurrentMissionStateAlarm', data[2][i]);
                                    }
                                });
                                resolve(emeralds);
                            })["catch"](function (reason) {
                                console.log('reason', reason);
                                reject(reason);
                            });
                        });
                    }
                })["catch"](function (reason) {
                    console.log('reason', reason);
                    reject(reason);
                });
            });
        },

        resetController: function resetController(controller) {
            this._super.apply(this, arguments);
            controller.send('resetController');
        },

        actions: {
            refreshModel: function refreshModel() {
                this.get('logger').info(TAG, 'refreshModel');
                this.set('pendingTransition', this.refresh());
            },

            abortCurrentTransition: function abortCurrentTransition() {
                this.get('logger').info(TAG, 'abortCurrentTransition');

                if (!isEmpty(this.get('pendingTransition'))) {
                    this.get('pendingTransition').abort();
                }
            }
        }
    });
});