define('oceasoft-web/helpers/timeunit', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Helper.extend({

        i18n: service('i18n'),

        compute: function compute(params) {
            var i18n = this.get('i18n');
            var value = Math.round(Number(params[0])) || 0;
            var hasMinutes = Math.round(Number(params[1])) || false;

            if (hasMinutes) {
                return i18n.t('timeUnit.minutes', { val: value }).toString();
            }

            if (value < 60) {
                return i18n.t('timeUnit.seconds', { val: value }).toString();
            } else {
                return i18n.t('timeUnit.minutes', { val: Math.round(value / 60) }).toString();
            }
        }
    });
});