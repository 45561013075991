define("oceasoft-web/routes/error", ["exports", "ember", "oceasoft-web/mixins/root-route", "oceasoft-web/mixins/session-manager", "oceasoft-web/objects/constants", "oceasoft-web/objects/error"], function (exports, _ember, _oceasoftWebMixinsRootRoute, _oceasoftWebMixinsSessionManager, _oceasoftWebObjectsConstants, _oceasoftWebObjectsError) {

  var TAG = 'error route';
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsRootRoute["default"], _oceasoftWebMixinsSessionManager["default"], {
    components: [],

    logger: service('oc-logger'),

    activate: function activate() {
      this._super.apply(this, arguments);

      if (this.get('isAuthenticated')) {
        this.set('components', ['topbar', 'navigation']);
      }
    },

    beforeModel: function beforeModel(transition) {
      var error = transition.params['error'].path;

      switch (error) {
        case _oceasoftWebObjectsConstants["default"].Error.GENERAL_ERROR:
          this.set('error', error);
          break;

        default:
          this.set('error', _oceasoftWebObjectsConstants["default"].Error.NOT_FOUND);
          break;
      }
    },

    model: function model() {
      return _oceasoftWebObjectsError["default"].create({
        type: _oceasoftWebObjectsConstants["default"].Error.Type.FATAL,
        image: 'not-found-img',
        icon: null,
        title: 'error.404_title',
        description: 'error.404_description'
      });
    },

    actions: {
      retryTransition: function retryTransition() {
        this.get('logger').info(TAG, 'retryTransition');
        var attemptedTransition = this.get('controller.model.attemptedTransition');

        if (attemptedTransition) {
          attemptedTransition.retry();
        }
      }
    }

  });
});