define("oceasoft-web/components/oc-dialog", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: 'oc-dialog',

    title: null,
    isVisible: true,

    content: { isContent: true },
    actions: { isActions: true }

  });
});