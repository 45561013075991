define("oceasoft-web/components/oc-module-icon", ["exports", "ember", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsUtils) {
    var computed = _ember["default"].computed;
    var fromString = _oceasoftWebObjectsUtils["default"].Color.fromString;
    exports["default"] = _ember["default"].Component.extend({
        tagName: 'oc-module-icon',
        attributeBindings: ['style', 'module.type:type'],

        style: computed('color', function () {
            return _ember["default"].String.htmlSafe('background-color: ' + this.get('color'));
        }),

        module: null,

        color: computed('module.serial', function () {
            return fromString(this.get('module.serial'));
        })
    });
});