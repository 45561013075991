define('oceasoft-web/initializers/oc-logger', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    ['route', 'component', 'controller'].forEach(function (component) {
      app.inject(component, 'logger', 'service:oc-logger');
    });
  }

  exports['default'] = {
    name: 'oc-logger',
    initialize: initialize
  };
});