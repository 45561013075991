define('oceasoft-web/objects/session', ['exports', 'ember'], function (exports, _ember) {

  var Session = _ember['default'].Object.extend(_ember['default'].Copyable, {

    user: null,
    company: null,

    copy: function copy() {
      return Session.create(this);
    }

  });

  exports['default'] = Session;
});