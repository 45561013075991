define("oceasoft-web/routes/profiles/company-information", ["exports", "ember", "ember-i18n", "oceasoft-web/mixins/admin-route", "oceasoft-web/mixins/session-manager"], function (exports, _ember, _emberI18n, _oceasoftWebMixinsAdminRoute, _oceasoftWebMixinsSessionManager) {
  var _slice = Array.prototype.slice;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_oceasoftWebMixinsAdminRoute["default"], _oceasoftWebMixinsSessionManager["default"], {

    session: service('session'),
    api: service('oc-api'),

    titleToken: (0, _emberI18n.translationMacro)('profiles.page.company_info'),

    model: function model() {
      return this.get('company');
    },

    resetController: function resetController(controller) {
      var _super;

      (_super = this._super).apply.apply(_super, [this].concat(_slice.call(arguments)));

      controller.send('resetController');
    }

  });
});