define('oceasoft-web/helpers/temperature', ['exports', 'ember', 'oceasoft-web/objects/utils'], function (exports, _ember, _oceasoftWebObjectsUtils) {
  exports.temperature = temperature;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var isEmpty = _ember['default'].isEmpty;

  function temperature(params, _ref) {
    var value = _ref.value;
    var isFahrenheit = _ref.isFahrenheit;
    var highLimit = _ref.highLimit;
    var lowLimit = _ref.lowLimit;
    var highLimitEnabled = _ref.highLimitEnabled;
    var lowLimitEnabled = _ref.lowLimitEnabled;
    var comma = _ref.comma;
    var round = _ref.round;

    isFahrenheit = Boolean(isFahrenheit) || false;
    highLimitEnabled = Boolean(highLimitEnabled) || false;
    lowLimitEnabled = Boolean(lowLimitEnabled) || false;
    round = Boolean(round) || false;

    highLimit = Number(highLimit);
    lowLimit = Number(lowLimit);

    var celsiusValue = Number(value);

    var tempSign = isFahrenheit ? " °F" : " °C";
    var finalValue = _oceasoftWebObjectsUtils['default'].Other.convertTemperature(value, isFahrenheit);

    var finalFormattedValue = parseFloat(finalValue).toFixed(round ? 1 : 2);

    if (comma) {
      finalFormattedValue = finalFormattedValue.replace('.', ',');
    }

    var color = "#000000";

    if (!isEmpty(highLimit) && highLimitEnabled && celsiusValue > highLimit) {
      color = "#ff0000";
    } else if (!isEmpty(lowLimit) && lowLimitEnabled > 0 && celsiusValue < lowLimit) {
      color = "#0000ff";
    }

    return htmlSafe('<span style="color: ' + color + '">' + finalFormattedValue + ' ' + tempSign + '</span>');
  }

  exports['default'] = _ember['default'].Helper.helper(temperature);
});