define("oceasoft-web/helpers/line-if", ["exports", "ember"], function (exports, _ember) {
  exports.lineIf = lineIf;

  function lineIf(params) {
    var obj = params[0];
    var a = params[1] || null;
    var b = params[2] || null;

    return obj ? a : b;
  }

  exports["default"] = _ember["default"].Helper.helper(lineIf);
});