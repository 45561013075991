define('oceasoft-web/components/oc-input', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var observer = _ember['default'].observer;
    var on = _ember['default'].on;
    var isEmpty = _ember['default'].isEmpty;
    var _Ember$run = _ember['default'].run;
    var bind = _Ember$run.bind;
    var next = _Ember$run.next;
    var htmlSafe = _ember['default'].String.htmlSafe;
    var $ = _ember['default'].$;
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'oc-input',
        classNameBindings: ['icon:icon', 'passwordVisible:visible', 'error:error', 'round:round', 'type', 'checked:checked', 'disabled:disabled'],
        attributeBindings: ['style', 'test'],
        round: null,

        icon: null,
        error: null,

        value: null,

        checked: false,
        disabled: false,
        label: null,
        type: "text",
        readonly: null,
        name: null,
        pattern: null,
        autosave: null,
        formmethod: null,
        step: null,
        selectionDirection: null,
        required: null,
        placeholder: null,

        color: null,
        style: computed('color', function () {
            var color = this.get('color');
            return _ember['default'].String.htmlSafe(isEmpty(color) || this.get('type') !== 'checkbox' ? "" : "color: " + color);
        }),

        isPlaceholderVisible: computed('placeholder', 'value', function () {
            var _getProperties = this.getProperties('placeholder', 'value', 'type');

            var placeholder = _getProperties.placeholder;
            var value = _getProperties.value;
            var type = _getProperties.type;

            if (type === 'password' && isEmpty(value) && $('input[type="password"]').css('background-color') === 'rgb(250, 255, 189)') {
                return false;
            }

            return !isEmpty(placeholder) && isEmpty(value);
        }),

        keyDown: function keyDown(e) {
            switch (this.get('type')) {
                case 'number':
                    if ($.inArray(e.keyCode, [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 13, 190, 189]) === -1 || e.keyCode === 190 && $(e.target).val().indexOf(".") !== -1 || e.keyCode === 190 && $(e.target).val().length === 0) {
                        e.preventDefault();
                    }
                    break;

                case 'email':
                    if (!$.inArray(e.keyCode, [32])) {
                        e.preventDefault();
                    }
                    break;
            }
        },

        isPreviewToggleVisible: computed('preview', 'value', function () {
            return this.get('preview') && !isEmpty(this.get('value'));
        }),

        groupValue: null,
        changed: null,

        // region InputMask Library Values

        maskType: null,

        mask: "",

        cardType: null,

        separator: null,
        unmaskedValue: null,
        group: null,
        groupSize: null,
        decimal: null,
        radix: null,

        extensions: null,

        fullCode: null,

        // endregion

        didInsertElement: function didInsertElement() {
            this.$('input').on('keyup', bind(this, 'handleInputKeyUp')).on('focusout', bind(this, 'transformEmptyToNull'));
        },

        willDestroyElement: function willDestroyElement() {
            this.$('input').off('keyup', bind(this, 'handleInputKeyUp')).off('focusout', bind(this, 'transformEmptyToNull'));
        },

        handleInputKeyUp: function handleInputKeyUp(e) {
            this.send('removeError');
            var key = e.keyCode || e.charCode;
            var isCtrl = e.metaKey || e.ctrlKey;

            if (key === 8 || key === 46 || isCtrl && key === 8) {
                next(this, this.transformEmptyToNull);
            }

            if (key === 13) {
                this.send("submit");
            }
        },

        transformEmptyToNull: function transformEmptyToNull() {
            // later(this, () => {
            //   const value = this.get('value');
            //
            //   if (isEmpty(value) && value !== null) {
            //     this.set('value', null);
            //   }
            // }, 1000);
        },

        toggleCheckbox: on('click', function (e) {
            if ($(e.target).is('input[type="checkbox"], input[type="radio"]')) {
                return;
            }

            if (['checkbox', 'radio'].indexOf(this.get('type')) > -1) {
                this.toggleProperty('checked');
            }
        }),

        formattedPlaceholder: computed('placeholder', 'required', function () {
            var placeholder = this.get('placeholder');

            if (this.get('required')) {
                placeholder += ' <span class="red">*</span>';
            }

            return htmlSafe(placeholder);
        }),

        handlePasswordVisibilityChange: observer('passwordVisible', function () {
            if (this.get('type') !== 'password') {
                return;
            }

            var inputElement = _ember['default'].$(this.get('element')).find('input');
            var isVisible = this.get('passwordVisible');

            inputElement.attr('type', isVisible ? 'text' : 'password');
        }),

        handleValueChange: observer('value', function () {
            var value = this.get('value');

            // For password types if the password is visible and the value is empty we set the password visibility back to false.
            if (this.get('type') === 'password' && this.get('passwordVisible')) {
                if (isEmpty(value)) {
                    this.set('passwordVisible', false);
                }
            }
        }),

        actions: {
            preview: function preview() {
                if (this.get('type') !== 'password') {
                    return;
                }

                this.toggleProperty('passwordVisible');
            },

            clearValue: function clearValue() {
                this.set('value', null);
            },

            removeError: function removeError() {
                this.set('error', null);
            },

            submit: function submit() {
                this.sendAction('onSubmit');
            }
        }
    });
});