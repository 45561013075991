define("oceasoft-web/objects/event", ["exports", "ember", "oceasoft-web/objects/api-object", "oceasoft-web/objects/constants", "oceasoft-web/objects/utils"], function (exports, _ember, _oceasoftWebObjectsApiObject, _oceasoftWebObjectsConstants, _oceasoftWebObjectsUtils) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var EventType = _oceasoftWebObjectsConstants["default"].Mission.EventType;
  var setDefaultProperties = _oceasoftWebObjectsUtils["default"].Object.setDefaultProperties;
  var isNull = _oceasoftWebObjectsUtils["default"].Property.isNull;

  var Event = _oceasoftWebObjectsApiObject["default"].extend(_ember["default"].Copyable, {

    init: function init() {
      setDefaultProperties(this, {
        isEvent: true,
        type: null,
        date: null,
        data1: null,
        data2: null,
        user: null
      });

      this._super.apply(this, arguments);

      var apiResponse = this.get('apiResponse');

      if (!isEmpty(apiResponse)) {
        this.set('type', parseInt(apiResponse.T));
        this.set('date', _oceasoftWebObjectsUtils["default"].Date.fromString(apiResponse.D));
        this.set('user', parseInt(apiResponse.U) || 0);

        if (!isEmpty(apiResponse.D1) && apiResponse.D1 !== _oceasoftWebObjectsConstants["default"].API.Response.NAN) {
          this.set('data1', apiResponse.D1.replace(',', '.'));
        }

        if (!isEmpty(apiResponse.D2) && apiResponse.D2 !== _oceasoftWebObjectsConstants["default"].API.Response.NAN) {
          this.set('data2', apiResponse.D2.replace(',', '.'));
        }

        delete this.apiResponse;
      }
    },

    isAlarm: computed('type', function () {
      switch (this.get('type')) {
        case EventType.ALARM_RESET:
        case EventType.UNKNOWN_ALARM:
        case EventType.HIGH_LIMIT_ALARM:
        case EventType.LOW_LIMIT_ALARM:
        case EventType.SENSOR_FAILED_ALARM:
        case EventType.LOW_BATTERY_ALARM:
        case EventType.OUT_OF_RANGE_ALARM:

          return true;

        default:
          return false;
      }
    }),

    couldHaveGeocoordinates: computed('type', function () {
      switch (this.get('type')) {
        case EventType.MISSION_PROGRAMMED:
        case EventType.MODULE_CONNECTED:
        case EventType.READINGS_DOWNLOADED:
        case EventType.MISSION_STOPPED:
          return true;

        default:
          return false;
      }
    }),

    hasGeocoordinates: computed('type', 'data1', 'data2', function () {
      var _getProperties = this.getProperties('data1', 'data2');

      var data1 = _getProperties.data1;
      var data2 = _getProperties.data2;

      return !isNull(data1) && !isNull(data2);
    }),

    copy: function copy() {
      return Event.create(this);
    }

  });

  exports["default"] = Event;
});