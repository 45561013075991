define('oceasoft-web/helpers/is-equal-year', ['exports', 'ember-date-components/helpers/is-equal-year'], function (exports, _emberDateComponentsHelpersIsEqualYear) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualYear['default'];
    }
  });
  Object.defineProperty(exports, 'isEqualYear', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualYear.isEqualYear;
    }
  });
});